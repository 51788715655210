//inspiration -- https://github.com/MomenSherif/react-oauth/tree/master/packages/%40react-oauth/google/src
//https://www.npmjs.com/package/@react-oauth/google

//https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api

import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { ToastNotificationContext } from '@providers/ToastNotification';
import { UserContext } from '@providers/User';

export const GoogleLoginContext = createContext({
	clientId: '',
	scriptLoaded: false,
	initialized: false,
});

// export default GoogleLoginContext;

/**
 * React context provider for the Google library (SignIn with Google button).
 * This component is modeled after foll references:
 * @see {@link https://github.com/MomenSherif/react-oauth/blob/master/packages/%40react-oauth/google/src/hooks/useLoadGsiScript.ts}
 * @see {@link https://github.com/MomenSherif/react-oauth/blob/master/packages/%40react-oauth/google/src/GoogleOAuthProvider.tsx}
 */
export function GoogleLogin_ContextProvider({ children }) {
	// const [scriptLoaded, setScriptLoaded] = useState(false); //since we only use this to know if script has loaded or not, and not for any UI logic, we can use it as a ref
	const scriptLoaded = useRef(false);
	const [initialized, setInitialized] = useState(false); //we can use this control when to render UI Button

	const isOnline = useContext(NetworkCheckContext);
	const { notify } = useContext(ToastNotificationContext);
	// const { id, email, createSess, reviveSess } = useContext(UserContext);
	const { id, email, createSess } = useContext(UserContext);

	const configData = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					googleClientId
				}
			}
		}
	`);
	const { googleClientId } = configData.site.siteMetadata;

	// console.log(configData);

	const handleSignInCredentialResponse = async (response) => {
		// console.log('Encoded JWT ID token: ' + response.credential);
		// console.log('Response', response);
		try {
			if (!Object.prototype.hasOwnProperty.call(response, 'credential')) throw new Error('Error reading credentials from Google');
			if (!response.credential) throw new Error('Error reading credentials from Google');
			//If user is already logged-in then we only need to revive session (ie get new creds since previous had expired), else we create new session
			// if (id && email) {
			// 	console.log('Since user ' + email + ' exists, we revive session');
			// 	//here check if user with same email-ID as the one already logged-in is trying to re-login (to refresh session)
			// 	//if a different user is trying to login-in, either disallow it or first log-out prevously logged-in user and create a new session for this new user
			// 	//if it's the same user trying to login, here check if old creds are still valid,
			// 	//we do all of the above in 'reviveSess()' fn
			// 	const creds = await reviveSess(response.credential, 'google'); //this shall refetch new credentials for already loged-in user
			// 	if (!creds) throw new Error('Error reviving session');
			// } else {
			// 	console.log('we create new session');
			// 	const creds = await createSess(response.credential, 'google'); //this shall log the user in and fetch credentials
			// 	if (!creds) throw new Error('Error creating session');
			// }
			const creds = await createSess(response.credential, 'google');
			if (!creds) throw new Error('Error creating session');
		} catch (err) {
			// console.error(err);
			notify(err.message || 'Sign-in with Google incomplete', 'warn');
		}
	};

	/* const handleInterimSignInCredentialResponse = async (response) => {
		// console.log('Encoded JWT ID token: ' + response.credential);
		// console.log('Response', response);
		try {
			if (!Object.prototype.hasOwnProperty.call(response, 'credential')) throw new Error('Error reading credentials from Google');
			if (!response.credential) throw new Error('Error reading credentials from Google');
			const creds = await reviveSess(response.credential, 'google'); //this shall refetch new credentials
			if (!creds) throw new Error('Error reviving session');
		} catch (err) {
			// console.error(err);
			notify(err.message || 'Sign-in with Google incomplete while reviving session', 'warn');
		}
	}; */

	// loads script (if it isn't already loaded) when we are back online
	useEffect(() => {
		if (!googleClientId) return;
		if (scriptLoaded.current) return; //if (scriptLoaded) return; //if script is already loaded, then no need to proceed

		//if we are back online
		if (isOnline) {
			//load script here
			const scriptTag = document.createElement('script');
			// scriptTag.id = 'googleGsiClientScript';
			scriptTag.src = 'https://accounts.google.com/gsi/client';
			scriptTag.async = true;
			scriptTag.defer = true;
			// scriptTag.nonce = nonce;
			scriptTag.onload = () => {
				scriptLoaded.current = true; //setScriptLoaded(true);
				//initialize		// NOTE : WE INITIALIZE BELOW BY REGISTERING onGoogleLibraryLoad() fn
				// window?.google?.accounts?.id?.initialize({
				// 	client_id: googleClientId,
				// 	callback: handleSignInCredentialResponse,
				// });
			};
			scriptTag.onerror = () => {
				scriptLoaded.current = false; //setScriptLoaded(false);
			};
			document.body.appendChild(scriptTag);

			//here register a fn which shall be called by the google library once it gets loaded
			//we attach fn (that initializes client lib) which is executed by the library script once it finishes loading
			window.onGoogleLibraryLoad = () => {
				//register a fn which shall be called once library is loaded
				//initialize
				window?.google?.accounts?.id?.initialize?.({
					client_id: googleClientId,
					//nonce: 'biaqbm70g23',	//random string used by the ID token to prevent replay attacks
					callback: handleSignInCredentialResponse, //it seems like the state within this fn gets cached once we set it
					use_fedcm_for_prompt: true, //https://developers.google.com/identity/gsi/web/reference/js-reference#use_fedcm_for_prompt
				});
				//https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api
				// window?.google?.accounts?.id?.prompt((notification) => {
				// 	if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
				// 		// try next provider if OneTap is not displayed or skipped
				// 		console.log('Google One Tap prompt not shown or skipped');
				// 	}
				// });

				setInitialized(true);
			};

			//we also return a cleanup fn
			return () => {
				// const scriptTag = document?.getElementById('googleGsiClientScript');
				scriptTag && document.body.removeChild(scriptTag);
			};
		}
	}, [isOnline]); //runs when network availability status changes

	/* useEffect(() => {
		if (!scriptLoaded.current) return; //if (!scriptLoaded) return; //initialize op would only execute after script is loaded, (since actually the script code itself handles the init op)

		//register a fn that google gsi client library calls once it loads
		//https://developers.google.com/identity/gsi/web/reference/js-reference#onGoogleLibraryLoad
		// setInitialized(false); //this helps rerender the 'GoogleLoginButton' component
		if (email) {
			// console.log('init google btn for interim login when logging-in next');
			//when user has just logged-in, reset the initialiation params

			//we reset the 'initialization params' prefilled with the email-id of the user
			//so that when it is invoked again (on sign-in button click in interim-login form) (in case we ask the user to do an interim-login due to expired ID_Token or AWS creds), google skips showing accounts chooser annd the same user can then login agin to continue the same session
			//once the user logs in again using interim-login, we can use the ID-token to get new AWS creds
			window?.google?.accounts?.id?.initialize?.({
				client_id: googleClientId,
				callback: handleSignInCredentialResponse, // handleInterimSignInCredentialResponse,
				login_hint: email,
				use_fedcm_for_prompt: true, //https://developers.google.com/identity/gsi/web/reference/js-reference#use_fedcm_for_prompt
			});
		} else {
			//https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.disableAutoSelect
			//When the user signs out of your website, you need to call the method google.accounts.id.disableAutoSelect() to record the status in cookies
			//This prevents a UX dead loop
			window?.google?.accounts?.id?.disableAutoSelect();

			// console.log('init google btn for first-time login when logging-in next');
			//when either no user has logged-in yet or a user has just logged-out
			//we reset the 'initialization params' with no prefilled value for email-id
			//so that when it is invoked again (on sign-in button click in main-login form)
			window?.google?.accounts?.id?.initialize?.({
				client_id: googleClientId,
				callback: handleSignInCredentialResponse,
				use_fedcm_for_prompt: true, //https://developers.google.com/identity/gsi/web/reference/js-reference#use_fedcm_for_prompt
			});
		}
		// setInitialized(true);

		return () => {
			window.onGoogleLibraryLoad = undefined;
		};
	}, [email]); */ //when value of 'email' has changed. Note that the value would change from and between an email-ID and null
	//we do not need to watch for 'isLoggedIn' since we watch for 'email'.
	//When user logs in, 'email' will have the email-Id and when no user is logged-in or any user logs-out, then email shall be null

	return <GoogleLoginContext.Provider value={{ googleClientId, initialized }}>{children}</GoogleLoginContext.Provider>;
}

/**
 * React component to render the 'Sign In with Google' button
 * @param {Object} prop - Prop object passed to component
 * @param {?('standard' | 'icon')} [prop.type='standard'] - Type of button
 * @param {?('outline' | 'filled_blue' | 'filled_black')} [prop.theme='filled_blue'] - Button Theme
 * @param {?('rectangular' | 'pill' | 'circle' | 'square')} [prop.shape='pill'] - Button shape
 * @param {()=>{}} [prop.onBtnClick] - Callback fn that executes on button click
 */
export default function GoogleLoginButton({ type, theme, shape, onBtnClick, ...restProps }) {
	const { initialized } = useContext(GoogleLoginContext);

	const btnContainerRef = useRef(null);

	//NOTE: If we render the button before the GSI lib is initialized, we get foll err --- Failed to render button before calling initialize().
	//Presumably cause the script loaded but the button rendering component ran before init cud be run by the loaded script.
	//Thus we watch for when initialization is done
	useEffect(() => {
		if (!initialized) return; // if script hasn't been loaded and initialized yet, no need to proceed with rendering the button
		//render button here
		window?.google?.accounts?.id?.renderButton(btnContainerRef.current, {
			type: type || 'standard', //'standard' | 'icon'
			theme: theme || 'filled_blue', //'outline' | 'filled_blue' | 'filled_black'
			// size: size || 'large', //'large' | 'medium' | 'small'
			// text: text || 'signin_with',		//'signin_with' | 'signup_with' | 'continue_with' | 'signin'
			shape: shape || 'pill', //'rectangular' | 'pill' | 'circle' | 'square'
			// logo_alignment,
			// width,
			// locale,
			click_listener: onBtnClick || undefined,
		});
	}, [initialized]); //}, [scriptLoaded]);

	return <div ref={btnContainerRef} {...restProps} />;
}
