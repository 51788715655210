import React, { createContext, useRef, useEffect } from 'react';

/*==================+
|					|
|		CONTEXT		|
|					|
+==================*/
/**
 * React context for Offline Cache
 */
export const PersistentOfflineCacheContext = createContext({
	/**
	 * Reads payment orders cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @returns {Promise<?Array>} Array or null
	 */
	getCachedPaymentOrders: async (userId) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Reads payment order meta-data cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @param {!string} key - Meta data to retrieve
	 * @returns {Promise<?any>} any or null
	 */
	getCachedPaymentOrdersMetaData: async (userId, key) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Writes payment orders cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @param {![]} paymentOrdersAry - Array of payment order objects
	 * @returns {Promise<Boolean>} TRUE if write op successful, FALSE otherwise
	 */
	setCachedPaymentOrders: async (userId, paymentOrdersAry) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Writes payment order meta-data cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @param {!string} key - Meta data name
	 * @param {!string} value - Meta data value
	 * @returns {Promise<Boolean>} TRUE if write op successful, FALSE otherwise
	 */
	setCachedPaymentOrdersMetaData: async (userId, key, value) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Delete all payment orders cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @returns {Promise<Boolean>} True if deletion succussfull, FALSE otherwise
	 */
	deleteCachedPaymentOrders: async (userId) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Delete payment order meta-data cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @param {?string} [key] - Meta data to delete. If null then entire table is cleared
	 * @returns {Promise<Boolean>} True if deletion succussfull, FALSE otherwise
	 */
	deleteCachedPaymentOrdersMetaData: async (userId, key) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Clears all tables cached in IndexedDB for the given userId
	 * @param {!string} userId - User ID
	 * @returns {Promise<Boolean>} True if deletion succussfull, FALSE otherwise
	 */
	clearAllTables: async (userId) => {}, // eslint-disable-line no-unused-vars
});

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for Offline Cache.
 * We use IndexedDB to store data for offline access.
 * This component is imported in 'src/containers/Root'
 * @see {@link https://javascript.info/indexeddb}
 * @see {@link https://web.dev/indexeddb}
 */
export default function PersistentOfflineCache_ContextProvider({ children }) {
	const dbRef = useRef(null); //we do not store it in a normal 'var' so as for it to persist across re-renders, kindoff like a static value

	const currentDbStoreVer = 1; //if there are any changes to the DB structure, we can increment this

	/**
	 * Sets up & initializes IndexedDB client and saves it as static for reuse later
	 * @async
	 * @function _setupIfNotAlreadyAndGetDbHandle
	 * @param {!string} dbName - Database name. For each user we create separate DBs using their distinct IDs
	 * @returns {Promise<?object>} IndexedDB handle or DOMException error.
	 */
	const _setupIfNotAlreadyAndGetDbHandle = (dbName) => {
		return new Promise((resolve, reject) => {
			if (!dbName || typeof dbName !== 'string' || dbName.trim() === '') reject();

			// if (dbRef.current?.constructor.name == 'IDBDatabase' && dbRef.current?.name === dbName) return resolve(dbRef.current); //if the req DB is already setup, we return it
			//check if a DB handle is already stored
			if (dbRef.current?.constructor.name == 'IDBDatabase') {
				if (dbRef.current?.name === dbName) {
					return resolve(dbRef.current); //if the stored DB is the one that we req, then return it
				} else {
					//close any other open DB connections (Mostly this could be of different user logged-in before the current user has just loggen-in)
					dbRef.current?.close?.(); //since anyhow we shall be only allowing one single user logged-in at any given time, and that user shall have one single DB
				}
			}

			//setup DB if not already
			const openRequest = indexedDB.open(dbName, currentDbStoreVer); //openRequest.construstor.name == IDBOpenDBRequest

			//EVENTS
			openRequest.onupgradeneeded = (event) => {
				//only fired when an attempt was made to open a database with a version number higher than its current version, or if no database existed before
				dbRef.current = openRequest.result; //same as 'event.target.result'	//had we not required it below to get 'dbRef.current.objectStoreNames' we need not have required to assign it here since we do it in the 'onsuccess' event

				// check existing db version
				if (event.oldVersion === 0) {
					// version 0 means that the client had no database
					// perform initialization

					//the 'payment-orders' table shall house all payment-transaction orders, with 'o' as the pk. 'o' denotes order-ID
					// if there's no 'payment-orders' table (ie obj-store), we create it
					if (!dbRef.current.objectStoreNames.contains('payment-orders')) {
						dbRef.current.createObjectStore('payment-orders', { keyPath: 'o' }); // here we specify the column-name to tell what it should use as primary-key
						//so now, when adding or updating items, we need not specify the 'key' explicitely
					}
					//the 'payment-orders_meta' table shall be used to store meta data related to orders, for eg, DynamoDB last-eval-key for paginated response, and maybe data write timestamp
					if (!dbRef.current.objectStoreNames.contains('payment-orders_meta')) {
						dbRef.current.createObjectStore('payment-orders_meta'); // note that in this case, we do not tell it to use inline keys from the data by opting not to specify the keyPath
					}
				} else if (event.oldVersion > 0 && event.oldVersion < currentDbStoreVer) {
					// client had old version
					// update
					dbRef.current.createObjectStore('payment-orders', { keyPath: 'o' });
					dbRef.current.createObjectStore('payment-orders_meta');
				}
			};

			openRequest.onerror = () => {
				reject(openRequest.error);
			};

			openRequest.onsuccess = (event) => {
				//fired after 'onupgradeneeded()' and if no errors while opening
				dbRef.current = openRequest.result; //event.target.result;
				dbRef.current.onversionchange = () => {
					//installs the onversionchange handler, that triggers if the current database connection becomes outdated (db version is updated elsewhere) and closes the connection
					dbRef.current.close();
					dbRef.current = null;
					console.warn('Cache database is outdated, please reload the page');
				};
				dbRef.current.onclose = () => {
					//fires only if the DB connection is unexpectedly closed
					dbRef.current = null; // console.log("Database connection closed");
				};
				// console.log('typeof dbRef.current:', typeof dbRef.current);					//object
				// console.log('dbRef.constructor.name:', dbRef.current.constructor.name);		//IDBDatabase
				// the db is ready, continue working with database using dbRef.current object

				// resolve(openRequest.result);
				resolve(dbRef.current);
			};
		});
	};

	/**
	 * Reads data cached in IndexedDB. If no 'pkVal' argument is provided, then it reads all items
	 * @async
	 * @function _read
	 * @param {!string} dbName - Database name. For each user we create separate DBs using their distinct IDs
	 * @param {!string} table - Object-store (ie' Table) name
	 * @param {?string} pk - Primary Key value. If this is not specified, fn returns all items
	 * @returns {Promise<?(any|[])>} Cached-data or null
	 */
	const _read = async (dbName, table, pk) => {
		if (!dbName || typeof dbName !== 'string' || dbName.trim() === '') return null;
		if (!table) return null;
		// if (!['payment-orders', 'payment-orders_meta'].includes(table)) return null;
		const db = await _setupIfNotAlreadyAndGetDbHandle(dbName);
		if (!db) return null;

		return new Promise((resolve, reject) => {
			try {
				//check if table exists in DB
				const tables = db.objectStoreNames; //https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/objectStoreNames
				if (tables.length < 1) return reject(); //since we do not have any tables configured in this DB
				const tablesAry = Array.from(tables); //since, db.objectStoreNames return DOMStringList and not Array, we convert it to array
				if (!tablesAry.includes(table)) return reject(`Read failed! The '${table}' table does not exist in cache database`);

				const transaction = db.transaction(table, 'readonly');
				transaction.onabort = () => {
					// console.log('Cache transaction aborted:', transaction.error);
					reject(transaction.error);
				};
				// transaction.oncomplete = () => {
				// 	console.log("Transaction is complete");
				// };
				let request;
				if (pk) {
					request = transaction.objectStore(table).get(pk); //.get(IDBKeyRange.only(key));
				} else {
					request = transaction.objectStore(table).getAll(); //returns an array
				}

				// request.onerror = (event) => {
				// 	console.error(request.error)
				// };
				request.onsuccess = () => {
					if (request.result !== undefined) {
						// return request.result;
						resolve(request.result);
					} else {
						// throw new Error('No data in Cache');
						// return;
						reject();
					}
				};
			} catch (err) {
				// return null;
				reject(err);
			}
		});
	};

	/**
	 * Reads payment orders cached in IndexedDB for the given userId
	 * @async
	 * @function getCachedPaymentOrders
	 * @param {!string} userId - User ID
	 * @returns {Promise<?Array>} Array or null
	 */
	const getCachedPaymentOrders = async (userId) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return null;
		try {
			const cachedOrders = await _read('kaagzi:' + userId, 'payment-orders', null);
			if (!cachedOrders || !Array.isArray(cachedOrders)) return null;
			if (cachedOrders.length < 1) return null;
			return cachedOrders;
		} catch (err) {
			return null;
		}
	};

	/**
	 * Reads payment order meta-data cached in IndexedDB for the given userId
	 * @async
	 * @function getCachedPaymentOrdersMetaData
	 * @param {!string} userId - User ID
	 * @param {!string} key - Meta data to retrieve. If key not provided, returns all items
	 * @returns {Promise<?any|[]>} Metadata, or array of metadata or null
	 */
	const getCachedPaymentOrdersMetaData = async (userId, key) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return null;
		try {
			// const metaData = await _read('kaagzi:' + userId, 'payment-orders_meta', key);
			// if (!metaData) return null;
			// return metaData;
			return await _read('kaagzi:' + userId, 'payment-orders_meta', key);
		} catch (err) {
			return null;
		}
	};

	//https://stackoverflow.com/questions/54176735/correct-way-to-add-multiple-objects-in-indexeddb

	/**
	 * Cache an item or array of items to IndexedDB
	 * @async
	 * @function _write
	 * @param {!string} dbName - Database name. For each user we create separate DBs using their distinct IDs
	 * @param {!string} table - Object-store (ie' Table) name
	 * @param {?string} pk - Primary Key value. Required if Object-store (ie' Table) does not have keyPath specified while creating it
	 * @param {!Object|[]} data - Data to store. If data is of type array, 'pk' is ignored and can be omitted
	 * @returns {Promise<boolean>} TRUE if write successful. FALSE otherwise.
	 */
	const _write = async (dbName, table, pk, data) => {
		if (!dbName || typeof dbName !== 'string' || dbName.trim() === '') return null;
		if (!table || !data) return null;
		// if (!['payment-orders', 'payment-orders_meta'].includes(table)) return null;
		const db = await _setupIfNotAlreadyAndGetDbHandle(dbName);
		if (!db) return null;

		return new Promise((resolve, reject) => {
			try {
				//check if table exists in DB
				const tables = db.objectStoreNames; //https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/objectStoreNames
				if (tables.length < 1) return reject(); //since we do not have any tables configured in this DB
				const tablesAry = Array.from(tables); //since, db.objectStoreNames return DOMStringList and not Array, we convert it to array
				if (!tablesAry.includes(table)) return reject(`Write failed! The '${table}' table does not exist in cache database`);

				const transaction = db.transaction(table, 'readwrite');
				transaction.onabort = () => {
					// console.log('Cache transaction aborted:', transaction.error);
					reject(transaction.error);
				};
				transaction.oncomplete = () => {
					// console.log("Transaction is complete");
					resolve(true);
				};

				const objStore = transaction.objectStore(table);

				let request;
				if (Array.isArray(data)) {
					// console.log('writing array of items');
					//check if 'keyPath' is configured for this objectStore (ie table) since the PK would be derived from that name for each of the itemswe are writing in bulk
					if (!objStore.keyPath) {
						transaction.abort();
						throw new Error('Error inferring primary key name while writing to cache');
					}

					for (let i = 0; i < data.length; i++) {
						if (!Object.prototype.hasOwnProperty.call(data[i], objStore.keyPath)) continue; // break;	//since the row data object does not have any property that of the primary-key column-name
						request = objStore.put(data[i]); //since while setting up the table, in '_setupIfNotAlreadyAndGetDbHandle()' we have already specified 'keyPath' as the primary key property, we need not explicitely specify it here
					}
				} else {
					// console.log('writing one item');
					if (objStore.keyPath) {
						//if 'objectStore' has 'keyPath' configured, we check if data object has the property with that name
						if (!Object.prototype.hasOwnProperty.call(data, objStore.keyPath)) {
							transaction.abort();
							throw new Error('Error inferring primary key value from data while writing to cache');
						}
						request = objStore.put(data); //we need not explicitely provide 'pk' since that will b inferred from the 'keyPath' property we configured when creating objectStore
					} else {
						//else if 'objectStore' does not have keyPath configured, we rely on 'pk' provided
						if (!pk) {
							transaction.abort();
							throw new Error('Primary key value not provided');
						}
						request = objStore.put(data, pk);
					}
				}
				//we could append a timestamp to the data if need be, before storing it! It'll just help us know how old it is when reading it
				request.onerror = (event) => {
					reject(event.target.error);
				};
				request.onsuccess = () => {
					resolve(true);
				};
			} catch (err) {
				// return false;
				reject(err);
			}
		});
	};

	/**
	 * Writes payment orders cached in IndexedDB for the given userId
	 * @async
	 * @function setCachedPaymentOrders
	 * @param {!string} userId - User ID
	 * @param {![]} paymentOrdersAry - Array of payment order objects
	 * @returns {Promise<Boolean>} TRUE if write op successful, FALSE otherwise
	 */
	const setCachedPaymentOrders = async (userId, paymentOrdersAry) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return false;
		if (!Array.isArray(paymentOrdersAry)) return false;
		if (paymentOrdersAry.length < 1) return false;
		try {
			return await _write('kaagzi:' + userId, 'payment-orders', null, paymentOrdersAry);
		} catch (err) {
			return false;
		}
	};

	/**
	 * Writes payment order meta-data cached in IndexedDB for the given userId
	 * @async
	 * @function setCachedPaymentOrdersMetaData
	 * @param {!string} userId - User ID
	 * @param {!string} key - Meta data name
	 * @param {!string} value - Meta data value
	 * @returns {Promise<Boolean>} TRUE if write op successful, FALSE otherwise
	 */
	const setCachedPaymentOrdersMetaData = async (userId, key, value) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return false;
		if (!key || typeof key !== 'string' || key.trim() === '') return false;
		try {
			return await _write('kaagzi:' + userId, 'payment-orders_meta', key, value);
		} catch (err) {
			return false;
		}
	};

	/**
	 * Deletes an item specified by key from specified IndexedDB table or clears entire table
	 * @async
	 * @function _delete
	 * @param {!string} dbName - Database name. For each user we have separate DBs using their distinct IDs
	 * @param {!string} table - Object-store (ie' Table) name
	 * @param {?string} key - Primary Key value. If omitted, the entire table is cleared
	 * @returns {Promise<Boolean>} TRUE if delete successful. FALSE otherwise.
	 */
	const _delete = async (dbName, table, key) => {
		if (!dbName || typeof dbName !== 'string' || dbName.trim() === '') return false;
		if (!table || typeof table !== 'string' || table.trim() === '') return false;
		// if (!key || typeof key !== 'string' || key.trim() === '') return false;
		// if (!['payment-orders', 'payment-orders_meta'].includes(table)) return false;
		const db = await _setupIfNotAlreadyAndGetDbHandle(dbName);
		if (!db) return false;
		return new Promise((resolve, reject) => {
			try {
				//check if table exists in DB
				const tables = db.objectStoreNames; //https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/objectStoreNames
				if (tables.length < 1) return resolve(true); // return true; //since we do not have any data to delete or tables to clear
				const tablesAry = Array.from(tables); //since, db.objectStoreNames return DOMStringList and not Array, we convert it to array
				if (!tablesAry.includes(table)) return reject(`The '${table}' table does not exist in cache database`);

				const transaction = db.transaction(table, 'readwrite');
				transaction.onabort = () => {
					reject(transaction.error);
				};
				transaction.oncomplete = () => {
					resolve(true);
				};

				let request;
				if (key && typeof key === 'string' && key.trim !== '') {
					request = transaction.objectStore(table).delete(key);
				} else {
					request = transaction.objectStore(table).clear();
				}

				request.onerror = (event) => {
					reject();
				};
				request.onsuccess = () => {
					resolve(true);
				};
				// resolve(true);
			} catch (err) {
				// return false;
				reject();
			}
		});
	};

	/**
	 * Delete all payment orders cached in IndexedDB for the given userId
	 * @async
	 * @function deleteCachedPaymentOrders
	 * @param {!string} userId - User ID
	 * @returns {Promise<Boolean>} True if deletion succussfull, FALSE otherwise
	 */
	const deleteCachedPaymentOrders = async (userId) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return false;
		try {
			return await _delete('kaagzi:' + userId, 'payment-orders', null);
		} catch (err) {
			return false;
		}
	};

	/**
	 * Delete payment order meta-data cached in IndexedDB for the given userId
	 * @async
	 * @function deleteCachedPaymentOrdersMetaData
	 * @param {!string} userId - User ID
	 * @param {?string} [key] - Meta data to delete. If null, then entire table is cleared
	 * @returns {Promise<Boolean>} True if deletion succussfull, FALSE otherwise
	 */
	const deleteCachedPaymentOrdersMetaData = async (userId, key) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return false;
		try {
			return await _delete('kaagzi:' + userId, 'payment-orders_meta', key);
		} catch (err) {
			return false;
		}
	};

	/**
	 * Clears all tables cached in IndexedDB
	 * @async
	 * @function clearAllTables
	 * @param {!string} dbName - Database name. For each user we have separate DBs using their distinct IDs
	 * @returns {Promise<boolean>} TRUE if clearing successful. FALSE otherwise.
	 */
	const _clearAllTables = async (dbName) => {
		if (!dbName || typeof dbName !== 'string' || dbName.trim() === '') return false;
		const db = await _setupIfNotAlreadyAndGetDbHandle(dbName);
		if (!db) return false;
		return new Promise((resolve, reject) => {
			try {
				const tables = db.objectStoreNames; //https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/objectStoreNames
				if (tables.length < 1) return resolve(true); //since we do not have any tables to clear

				const tablesAry = Array.from(tables); //since, db.objectStoreNames return DOMStringList and not Array, we convert it to array

				const transaction = db.transaction(tablesAry, 'readwrite'); //https://developer.chrome.com/blog/defining-scope-In-indexeddb-transactions/

				transaction.onabort = () => {
					reject(transaction.error);
				};
				transaction.oncomplete = () => {
					resolve(true);
				};

				tablesAry.forEach((table) => {
					// let clearRequest = transaction.objectStore(table).clear();	// clear the storage
					// clearRequest.onerror = (event) => { reject(`Error clearing '${table}' cache`); };
					// clearRequest.onsuccess = () => {resolve();};	//this may prematurely resolve promise
					transaction.objectStore(table).clear();
					// console.log(`Cleared ${table} cache`);
				});
				// resolve(true);
			} catch (err) {
				reject();
			}
		});
	};

	/**
	 * Clears all tables cached in IndexedDB for the given userId
	 * @async
	 * @function clearAllTables
	 * @param {!string} userId - User ID
	 * @returns {Promise<Boolean>} True if deletion succussfull, FALSE otherwise
	 */
	const clearAllTables = async (userId) => {
		if (!userId || typeof userId !== 'string' || userId.trim() === '') return false;
		try {
			return await _clearAllTables('kaagzi:' + userId);
		} catch (err) {
			return false;
		}
	};

	// const del = async () => {
	// 	try {
	// 		const db = await _setupIfNotAlreadyAndGetDbHandle();	//dbHandle
	// 		if (db.constructor.name != 'IDBDatabase') throw new Error('Error getting cache DB handle');
	// 		// db.deleteObjectStore('myInvestments');
	// 		const tables = db.objectStoreNames;	//https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/objectStoreNames
	// 		if (tables.length < 1) return true;
	// 		//btw, db.objectStoreNames return DOMStringList and not Array
	// 		for (var i = 0; i < tables.length; i++){
	// 			console.log('Clearing table:', tables[i]);
	// 			db.deleteObjectStore(tables[i]);	//delete entire table		//NOTE: This doesn't work rn coz we cannot delete stores outside onupgradeneeded() event
	// 		}
	// 		return true;

	// 	} catch (err) {
	// 		console.error(err);
	// 		return false;
	// 	}
	// };

	//no need to setup / init on mount. It shall be setup when first call to IndexedDB is made
	// useEffect(() => {
	// 	_setupIfNotAlreadyAndGetDbHandle();
	// }, []);

	return (
		<PersistentOfflineCacheContext.Provider
			value={{
				getCachedPaymentOrders,
				getCachedPaymentOrdersMetaData,
				setCachedPaymentOrders,
				setCachedPaymentOrdersMetaData,
				deleteCachedPaymentOrders,
				deleteCachedPaymentOrdersMetaData,
				clearAllTables,
			}}
		>
			{/*{read,write} is same as {read:read,write:write}*/}
			{children}
		</PersistentOfflineCacheContext.Provider>
	);
}
