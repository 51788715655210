import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
	// fromWebToken,
	fromCognitoIdentityPool,
	fromTemporaryCredentials,
} from '@aws-sdk/credential-providers';
import { decodeJWT } from '@utils';

// import { ToastNotificationContext } from '@providers/ToastNotifier';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { StorageContext } from '@providers/Storage';

import { areObjectsEqual } from '@utils';

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
/**
 * Short-lived Temporary Credentials issued by AWS STS service.
 * @typedef {Object} Creds
 * @property {string} [identityId] - ID (from identity-pool) of user-identity this creds have been issued for. Only available when brokering creds via Identity Pool and not available if directly fetching creds for IdP idToken
 * @property {!string} accessKeyId - Access Key ID
 * @property {!string} secretAccessKey - Secret Access Key
 * @property {!string} sessionToken - Session Token
 * @property {!Date} expiration - Creds expiry Date object
 */

/*======================+
|						|
|		CONTEXT			|
|						|
+======================*/
/**
 * React context for short-term temporary credentials issued by AWS STS service.
 * Consumed by AWS services like DynamoDB, Lambda etc
 */
export const AwsCredentialsContext = createContext({
	// /**
	//  * The AWS credentials object. Instead of using this directly, prefer to use 'getCreds()' since it checks if creds are valid also refreshes the creds if about to expire
	//  * @type {Creds|null}
	//  */
	// creds: null,
	/**
	 * True if we have valid (unexpired) creds stored. False otherwise
	 */
	isCredsValid: false,
	/**
	 * Identity ID the current creds ar associated with and issued for
	 * @type {string}
	 */
	identityId: null,
	/**
	 * Get creds for using when calling AWS APIs.
	 * This also tries to refresh the creds if old creds are about to expire.
	 * @async
	 * @function
	 * @returns {Promise<?Creds>} Creds object or null
	 */
	getCreds: async () => {},
	/**
	 * Fetch AWS creds, given an ID-Token from an identity-provider.
	 * @async
	 * @function
	 * @param {!object} idToken - ID-Token issued by an identity-provider like google, fb etc
	 * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	 * @returns {Promise<?Creds>} Creds object or null
	 */
	fetchNewCredsByExchangingIdToken: async (idToken, idp) => {}, // eslint-disable-line no-unused-vars
	/**
	 * Clears out the creds from state and localstorage. Useful after user logs-out
	 * @function
	 */
	clearCreds: () => {},
});

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for short-term temporary credentials issued by AWS STS service.
 * This component is imported in 'src/containers/Root'
 */
export default function AwsCredentials_ContextProvider({ children }) {
	// const [creds, setCreds] = useState(null);
	// creds_shape = {
	// 	"identityId": "ap-south-1:1949b096-cb8f-491b-8398-3c2ff43f8d2c",
	// 	"accessKeyId": "ASIAZHS3I647CGQA3V2S",
	// 	"secretAccessKey": "/kd3YmpcFjkoOO0VPiipfs6iw0niESzp1j6SS7tm",
	// 	"sessionToken": "a lengthy session token would be here",
	// 	"expiration": "2023-03-27T16:04:24.000Z"	//1679933064
	// }
	const [isCredsValid, setIsCredsValid] = useState(false); //we shall check this (along with checking if user-session is underway) to show interim-login form
	const [identityId, setIdentityId] = useState(null); //this shall be used as the userId and mostly the PK in DynamoDB

	// const { notify } = useContext(ToastNotificationContext);
	const isOnline = useContext(NetworkCheckContext);
	const { storageGet, storageSet, storageRemove } = useContext(StorageContext);

	const configData = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					awsCognitoIdentityPoolAuthRole
					awsCognitoIdentityPoolId
					awsRegion
				}
			}
		}
	`);
	const { awsCognitoIdentityPoolAuthRole, awsCognitoIdentityPoolId, awsRegion } = configData.site.siteMetadata;

	/**
	 * Clears the creds from state and localstorage
	 * @param {Boolean} [skipInformingContentScript=false] - Whether to skip informing content-script. Useful when this fn is run upon getting a message from content-script and avoid endless-loop
	 */
	const clearCreds = (skipInformingContentScript) => {
		setIsCredsValid(false);
		setIdentityId(null);
		// localStorage.getItem('_c') && localStorage.removeItem('_c');
		storageRemove('_c'); //same as above
		//here inform contentScript of the removal
		if (!skipInformingContentScript) window.postMessage({ action: 'credsRemoved', msgFlowDirection: 'webPage-to-contentScript' }, window.location.origin); //post a message intended to be listened by content-script injected by webextension
	};
	/**
	 * Get creds for using when calling AWS APIs.
	 * This also tries to refresh the creds if old creds are about to expire.
	 * @async
	 * @function
	 * @returns {Promise<?Creds>} Creds object or null
	 */
	const getCreds = async () => {
		if (!isOnline) return null;
		try {
			const credsFromStorage = await _readCredsFromStorage();
			if (!credsFromStorage) throw new Error('No active Session');
			// console.log('getCreds(): Read creds from storage:', credsFromStorage);
			const credsFromStorageValidity = _checkCredsExpiration(credsFromStorage); //check if creds are valid
			// console.log('credsFromStorageValidity: ', credsFromStorageValidity);
			if (credsFromStorageValidity === 'valid') {
				setIsCredsValid(true);
				return credsFromStorage;
			}
			if (credsFromStorageValidity === 'expired') {
				clearCreds();
				throw new Error('Creds expired');
			}
			if (credsFromStorageValidity === 'expiry-near') {
				const refreshedCreds = await _fetchNewCredsByExchangingOldCreds(credsFromStorage);
				if (!refreshedCreds) throw new Error('Error fetching refreshed credentials');
				// _setCreds_onlyIfNewValIsDifferent(refreshedCreds);	//not needed since '_fetchNewCredsByExchangingOldCreds()' already does that
				setIsCredsValid(true);
				return refreshedCreds;
			}
			// return credsFromStorage;
		} catch (err) {
			setIsCredsValid(false);
			// _setCreds_onlyIfNewValIsDifferent(null);
			// console.log('getCreds()', err);
			return null;
		}
	};

	/**
	 * Fetch AWS creds, given an ID-Token from an identity-provider.
	 * In this scenario since we are using Identity-Pool, the 'fromCognitoIdentityPool()' fn first gets the 'IdentityId' and then gets credentials for that IdentityId
	 * @async
	 * @function fetchNewCredsByExchangingIdToken
	 * @param {!object} idToken - ID-Token issued by an identity-provider like google, fb etc
	 * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	 * @returns {Promise<Creds>|Promise<null>} Creds object or null
	 * @see https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-credential-providers/#fromcognitoidentitypool
	 */
	const fetchNewCredsByExchangingIdToken = async (idToken, idp) => {
		try {
			if (!isOnline) throw new Error('Please check your network connection');
			if (!idToken) throw new Error('ID-Token required to fetch creds');
			let idpLogins = {};
			if (idp === 'google') idpLogins['accounts.google.com'] = idToken;
			// if (idp === 'facebook') idpLogins['graph.facebook.com'] = idToken;
			// if (idp === 'cognitoUserPool') idpLogins[`cognito-idp.${awsRegion}.amazonaws.com/${AWS_COGNITO_USER_POOL_ID}`] = idToken;
			if (Object.keys(idpLogins).length === 0 && idpLogins.constructor === Object) throw new Error('Unknown Identity Provider');

			//get creds from token
			//https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-credential-providers/#fromcognitoidentitypool
			let fromCognitoIdentityPool_options = {
				identityPoolId: awsCognitoIdentityPoolId,
				clientConfig: { region: awsRegion },
				logins: idpLogins,
				// Optional. The duration, in seconds, of the role session. Default to 3600.
				durationSeconds: 21600, //6 hrs	//also set the max allowed value in AWS console for the said role
			};
			// We also append the optional 'userIdentifier' key which is a unique identifier for the user used to cache Cognito IdentityIds on a per-user basis.
			const jwtPayload = decodeJWT(idToken);
			if (jwtPayload && jwtPayload?.sub) fromCognitoIdentityPool_options.userIdentifier = jwtPayload.sub;
			const getCredsFn = fromCognitoIdentityPool(fromCognitoIdentityPool_options);
			if (!getCredsFn) throw new Error('Error getting fn that fetches credentials');
			const creds = await getCredsFn();
			if (!creds) throw new Error('Error fetching creds for id-token');
			// here, broadcast new creds to other tabs (or maybe other tabs can read via on-localstorage-change event)
			// console.log('Creds expiration:',new Date(creds.expiration).getTime());
			// console.log('Creds expiration type:',typeof creds.expiration);
			// here save creds to LocalStorage where session in other tabs can use it. We can use identityId as part of the key to associate these creds with this user
			// localStorage.setItem('_c', JSON.stringify(creds));
			// setIsCredsValid(true);
			await _writeCredsDataToStateAndStorage_onlyIfDataChanged(creds);
			return creds;
		} catch (err) {
			return null;
		}
	};

	/* /**
	 * Fetch AWS creds, given an ID-Token from an identity-provider.
	 * @async
	 * @function fetchNewCredsByExchangingIdTokenDirectly
	 * @param {!object} idToken - ID-Token issued by an identity-provider like google, fb etc
	 * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	 * @returns {Promise<Creds>|Promise<null>} Creds object or null
	 * @see https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-credential-providers/#fromwebtoken
	 */
	/*const fetchNewCredsByExchangingIdTokenDirectly = async (idToken, idp) => {
		try {
			if (!isOnline) throw new Error('Please check your network connection');
			if (!idToken) throw new Error('ID-Token required to fetch creds');
			// let idpLogins = {};
			// if (idp === 'google') idpLogins['accounts.google.com'] = idToken;
			// // if (idp === 'facebook') idpLogins['graph.facebook.com'] = idToken;
			// // if (idp === 'cognitoUserPool') idpLogins[`cognito-idp.${awsRegion}.amazonaws.com/${AWS_COGNITO_USER_POOL_ID}`] = idToken;
			// if (Object.keys(idpLogins).length === 0 && idpLogins.constructor === Object) throw new Error('Unknown Identity Provider');

			//get creds from token
			const getCredsFn = fromWebToken({
				roleArn: iamAuthRole_googleIdp,
				webIdentityToken: idToken,
				// providerId: null, //'graph.facebook.com',	// Optional. The fully qualified host component of the domain name of the identity provider.
				clientConfig: { region: awsRegion },
				// durationSeconds: 7200, // Optional. The duration, in seconds, of the role session. Default to 3600.
				//Be sure to make changes in backend permissions too else we'll get foll err -- The requested DurationSeconds exceeds the MaxSessionDuration set for this role
			});
			if (!getCredsFn) throw new Error('Error getting fn that fetches credentials');
			const creds = await getCredsFn();
			if (!creds) throw new Error('Error fetching creds for id-token');
			// here, broadcast new creds to other tabs (or maybe other tabs can read via on-localstorage-change event)
			// console.log('Creds expiration:',new Date(creds.expiration).getTime());
			// console.log('Creds expiration type:',typeof creds.expiration);
			// here save creds to LocalStorage where session in other tabs can use it. We can use identityId as part of the key to associate these creds with this user
			// localStorage.setItem('_c', JSON.stringify(creds));
			// setIsCredsValid(true);
			_writeCredsDataToStateAndStorage_onlyIfDataChanged(creds);
			return creds;
		} catch (err) {
			return null;
		}
	}; */

	/**
	 * Fetch new AWS creds, given old AWS creds which have not yet expired.
	 * This fn can be used to  refresh old creds when they are about to expire but have not expired yet.
	 * This fn also updates `creds` state with the newly fetched creds
	 * @async
	 * @function _fetchNewCredsByExchangingOldCreds
	 * @param {!Creds} oldCreds - Creds that were previously issued and have not yet expired yet
	 * @returns {Promise<Creds>|Promise<null>} Creds object or null
	 * @see https://github.com/aws/aws-sdk-js-v3/blob/main/UPGRADING.md#temporary-credentials
	 * @see https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-credential-providers/#fromtemporarycredentials
	 */
	const _fetchNewCredsByExchangingOldCreds = async (oldCreds) => {
		try {
			if (!isOnline) throw new Error('Please check your network connection');
			if (!oldCreds) throw new Error('No current creds provided');
			//here check if old creds haven't expired yet
			const oldCredsValidityInfo = _checkCredsExpiration(oldCreds);
			if (oldCredsValidityInfo === 'expired') throw new Error('Old creds have expired');
			// if (oldCredsValidityInfo === 'expiry-near' || oldCredsValidityInfo === 'valid') {
			// 	//fetch new creds
			// }
			const getRefreshCredsFn = fromTemporaryCredentials({
				clientConfig: { region: awsRegion },
				masterCredentials: oldCreds,
				params: {
					RoleArn: awsCognitoIdentityPoolAuthRole, //'arn:aws:iam::1234567890:role/RoleB', // Required. ARN of role to assume.
					// Optional. The duration, in seconds, of the role session. Default to 3600.
					DurationSeconds: 21600, //6 hrs	//also set the max allowed value in AWS console for the said role
				},
			});
			if (!getRefreshCredsFn) throw new Error('Error getting fn that fetches credentials');
			const refreshedCreds = await getRefreshCredsFn();
			if (!refreshedCreds) {
				clearCreds();
				throw new Error('Error fetching refreshed credentials');
			}
			// localStorage.setItem('_c', JSON.stringify(refreshedCreds));
			// setIsCredsValid(true);
			await _writeCredsDataToStateAndStorage_onlyIfDataChanged(refreshedCreds);
			return refreshedCreds;
		} catch (err) {
			return null;
		}
	};

	//==========================+
	//							|
	//		CREDS HELPERS		|
	//							|
	//==========================+
	/**
	 * Check if creds are valid or are about to expire or have expired.
	 * @function _checkCredsExpiration
	 * @param {!Creds} creds - AWS Creds whose validity we are checking. See {@link Creds}
	 * @returns {'valid'|'expiry-near'|'expired'} Whether creds are valid or are about to expire or have expired
	 */
	const _checkCredsExpiration = (creds) => {
		try {
			// console.log('checking creds expiration', creds.expiration);
			if (!creds) return 'expired';
			if (!Object.prototype.hasOwnProperty.call(creds, 'expiration')) return 'expired';
			// console.log('_checkCredsExpiration(): typeof creds.expiration', typeof creds.expiration);
			// console.log('_checkCredsExpiration(): expiration date', new Date(creds.expiration).getTime());
			const expiryEpoch = new Date(creds.expiration).getTime();
			if (isNaN(expiryEpoch)) return 'expired'; //in case of err with input to date above, we get NaN. Thus we check for it
			// const bufferBeforeExpiryInMs = 15000; //15 seconds buffer before expiry
			// if (Date.now() >= expiryEpoch - bufferBeforeExpiryInMs) return 'expired'; //if current time is more than 15 seconds to expiry, we flag it as expired
			if (Date.now() >= expiryEpoch) return 'expired';
			if (Date.now() >= expiryEpoch - 600000) return 'expiry-near'; //if current time is more than 10 mins to expiry (but less than expiry), we flag it as 'about to expire'
			return 'valid';
		} catch (err) {
			console.error(err);
			return 'expired';
		}
	};

	/*/**
	 * Check if object is a valid AWS creds object.
	 * @function _isValidCredsShape
	 * @param {!Creds} obj - AWS Creds whose validity we are checking. See {@link Creds}
	 * @returns {bool} True if creds have the expected valid shape. False otherwise
	 */
	/* const _isValidCredsShape = (obj) => {
		if (!obj) return false;
		if (
			!Object.prototype.hasOwnProperty.call(obj, 'identityId') ||
			!Object.prototype.hasOwnProperty.call(obj, 'accessKeyId') ||
			!Object.prototype.hasOwnProperty.call(obj, 'secretAccessKey') ||
			!Object.prototype.hasOwnProperty.call(obj, 'sessionToken') ||
			!Object.prototype.hasOwnProperty.call(obj, 'expiration')
		)
			return false;
		if (
			obj.identityId?.trim?.() == '' ||
			obj.accessKeyId?.trim?.() == '' ||
			obj.secretAccessKey?.trim?.() == '' ||
			obj.sessionToken?.trim?.() == '' ||
			obj.expiration?.trim?.() == ''
		)
			return false;
		return true;
	}; */

	/**
	 * Sets creds-data state values if value has really changed.
	 * And writes creds-data to localstorage only if data has really changed.
	 * @async
	 * @function _writeCredsDataToStateAndStorage_onlyIfDataChanged
	 * @param {!Creds} creds - Creds issued by Amazon STS
	 * @returns {Promise<bool>} True if attempted to update creds-data values in state and updated localstorage. False otherwise
	 */
	const _writeCredsDataToStateAndStorage_onlyIfDataChanged = async (creds) => {
		try {
			const attemptedToUpdateCredsDataStateValues = _writeCredsDataToState_onlyIfValuesHavChanged(creds);
			if (!attemptedToUpdateCredsDataStateValues) return false;
			await _writeCredsDataToStorage_onlyIfDataHasChanged(creds);
			return true;
		} catch (err) {
			return false;
		}
	};

	//==========================================+
	//											|
	//		READ from / WRITE to STORAGE		|
	//											|
	//==========================================+
	/**
	 * Reads `creds` from ***localstorage***
	 * @async
	 * @function _readCredsFromStorage
	 * @returns {Promise<Creds>|Promise<null>} Fulfils with 'creds' if reading was successful otherwise null
	 */
	const _readCredsFromStorage = async () => {
		try {
			// console.log('reading creds from storage');
			// const credsStrFromStorage = localStorage.getItem('_c');
			const credsStrFromStorage = await storageGet('_c', 'awsStsCredentials');
			// console.log('credsStrFromStorage string:', credsStrFromStorage);
			if (!credsStrFromStorage) return null; //throw new Error();
			//here check if sess hasn't expired
			const credsFromStorage = JSON.parse(credsStrFromStorage);
			// console.log('credsFromStorage object before exp data parsing:');
			// console.log(credsFromStorage);

			//since AWS Creds object shape expects 'expiration' key to be a Date object, we convert string to Date like so
			if (Object.prototype.hasOwnProperty.call(credsFromStorage, 'expiration')) {
				// console.log('_readCredsFromStorage(): credsFromStorage.expiration: ', credsFromStorage.expiration);
				const exd = new Date(credsFromStorage.expiration); //we convert to date as is standard shape of Creds object expected by AWS APIs
				// console.log('_readCredsFromStorage(): date format:', exd);
				//if 'exd' was parsed into a valid date, we use that, else we just use the current time
				credsFromStorage.expiration = exd instanceof Date && !isNaN(exd.getTime()) ? exd : new Date();
			}

			// if (!credsFromStorage) return null;
			// console.log('credsFromStorage object after exp date parsing:');
			// console.log(credsFromStorage);
			return credsFromStorage;
		} catch (err) {
			return null;
		}
	};

	/**
	 * Saves creds data to localstorage only if data has really changed.
	 * @async
	 * @function _writeCredsDataToStorage_onlyIfDataHasChanged
	 * @param {!Creds} creds - Creds object we intend to write to localstorage
	 * @returns {Promise<boolean>} True if value in storage updated. False otherwise
	 */
	const _writeCredsDataToStorage_onlyIfDataHasChanged = async (creds) => {
		try {
			if (!creds) return false;
			if (typeof creds !== 'object') return false;
			const credsInStorage = await _readCredsFromStorage();
			// console.log('writing creds to storage');
			// console.log('creds object being written');
			// console.log(creds);

			if (credsInStorage && areObjectsEqual(creds, credsInStorage)) return false; //we do not need to update localstorage since data values are same
			// localStorage.setItem('_c', JSON.stringify(creds));
			console.log('Creds shall expire on:', new Date(creds.expiration).toLocaleString());
			const encryptedStr = await storageSet('_c', creds, 'awsStsCredentials');
			//here inform contentScript of the updates
			window.postMessage({ action: 'credsUpdated', msgFlowDirection: 'webPage-to-contentScript', creds: encryptedStr }, window.location.origin); //post a message intended to be listened by content-script injected by webextension

			return true;
		} catch (err) {
			return false;
		}
	};

	//==========================+
	//							|
	//		WRITE to STATE		|
	//							|
	//==========================+

	/**
	 * Sets creds-data state values if value has really changed.
	 * @function _writeCredsDataToState_onlyIfValuesHavChanged
	 * @param {!Creds} creds - Creds obj from which we write data to state
	 * @returns {boolean} True if attempted to update all creds-data values in state. False otherwise
	 */
	const _writeCredsDataToState_onlyIfValuesHavChanged = (creds) => {
		if (!creds) return false;
		if (!Object.prototype.hasOwnProperty.call(creds, 'identityId')) return false; //since we are using identity-pool, we shall have this field
		const credsValidity = _checkCredsExpiration(creds); //check if creds are valid
		if (credsValidity !== 'valid' && credsValidity !== 'expiry-near') return false;
		// _setCreds_onlyIfNewValIsDifferent(creds); //this will trigger below useEffect, which shall trigger storage event in other tabs
		setIsCredsValid(true); //if (!isCredsValid) setIsCredsValid(true);
		if (creds.identityId !== identityId) setIdentityId(creds.identityId);
		return true;
	};

	/**
	 * Update creds-data state values with that of data from localstorage
	 * @async
	 * @function _updateStateWithDataFromStorage
	 * @returns {Promise<bool>} Fulfils with true if attempt to update state was made otherwise false
	 */
	const _updateStateWithDataFromStorage = async () => {
		try {
			/**
			 * @type {Creds}
			 * @see {@link Creds}
			 */
			const credsFromStorage = await _readCredsFromStorage(); //read stored-creds-data from localstorage
			if (!credsFromStorage || !Object.prototype.hasOwnProperty.call(credsFromStorage, 'identityId')) throw new Error();
			const credsFromStorageValidity = _checkCredsExpiration(credsFromStorage); //check if creds are valid
			if (credsFromStorageValidity !== 'valid' && credsFromStorageValidity !== 'expiry-near') throw new Error(); // if (credsFromStorageValidity === 'expired') throw new Error();

			// if (!isCredsValid) setIsCredsValid(true);
			// if (credsFromStorage.identityId !== identityId) setIdentityId(credsFromStorage.identityId);
			_writeCredsDataToState_onlyIfValuesHavChanged(credsFromStorage);
			// console.log('Creds-data state values synced with storage');
			return true;
		} catch (err) {
			clearCreds();
			return false;
		}
	};

	/**
	 * We watch for when `creds` stored in ***localstorage*** are updated by code from another tab
	 * @function _handleCredsChangesInStorage
	 * @param {!StorageEvent} e - Storage Event
	 */
	const _handleCredsChangesInStorage = (e) => {
		// console.log('storageArea:', e.storageArea);
		if (e.key == '_c') {
			// console.log('Localstorage creds-data changed by another TAB');
			if (e.newValue == null) {
				// console.log('creds removed from localstorage by other TAB');
				clearCreds();
			} else {
				// console.log('Creds updated in localstorage from another tab: ', e.newValue);
				(async () => {
					await _updateStateWithDataFromStorage(); //we do not pass e.newValue to the fn since anyhow the fn reads latest value from localstorage
				})();
			}
		}
	};

	/**
	 * We listen for any messages from the content-script
	 * @param {!StorageEvent} event - Storage Event
	 */
	const _handleMsgsFromContentScript = (event) => {
		if (event.source != window) return; // We only accept messages from ourselves, (ie sent by the same window object the content-script is running in)
		if (!event.origin.startsWith('http://localhost:') && event.origin !== 'https://kaagzi.in') {
			console.warn('Does not accept incoming messages from ' + event.origin + ' origin');
			return;
		}
		//here also chk if message is from content-script to webpage and not the other way around
		if (!Object.prototype.hasOwnProperty.call(event.data, 'msgFlowDirection')) return;
		if (event.data.msgFlowDirection !== 'contentScript-to-webPage') return;

		if (!Object.prototype.hasOwnProperty.call(event.data, 'action')) return;
		switch (event.data.action) {
			case 'credsUpdated':
				//The content-script would have already written the updated data to the localstorage
				(async () => {
					await _updateStateWithDataFromStorage(); //read creds/session validity data from localstorage into state
				})();
				break;
			case 'credsRemoved':
				clearCreds(true);
				break;
			// default:
			// 	//do something default
			// 	console.log('AwsCredentials_ContextProvider received message from content-script with foll action: ' + event.data.action);
		}
	};

	//==========================+
	//							|
	//		EFFECT-HOOKS		|
	//							|
	//==========================+

	useEffect(() => {
		//check for stored creds data and update state
		(async () => {
			await _updateStateWithDataFromStorage(); //read creds/session validity data from localstorage into state
		})();

		//we listen for any changes to session objects stored in localstorage by code from other tabs running this app
		window.addEventListener('storage', _handleCredsChangesInStorage);

		window.addEventListener('message', _handleMsgsFromContentScript);

		return () => {
			window.removeEventListener('storage', _handleCredsChangesInStorage);
			window.removeEventListener('message', _handleMsgsFromContentScript);
		};
	}, []); //runs only on mount

	return (
		<AwsCredentialsContext.Provider value={{ isCredsValid, identityId, getCreds, fetchNewCredsByExchangingIdToken, clearCreds }}>
			{children}
		</AwsCredentialsContext.Provider>
	);
}
