import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import { fromCognitoIdentityPool, fromTemporaryCredentials } from '@aws-sdk/credential-providers';
// import { ToastNotifierContext } from '@components/ToastNotifier';
import { ToastNotificationContext } from '@providers/ToastNotification';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { StorageContext } from '@providers/Storage';
import { DynamoDbContext } from '@providers/aws/DynamoDB';
import { LambdaContext } from '@providers/aws/Lambda';
import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';

import { areObjectsEqual, decodeJWT } from '@utils';

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
/**
 * Short-lived Temporary Credentials issued by AWS STS service
 * @typedef {import('@providers/aws/AwsCredentials').Creds} Creds
 */

/**
 * User object constructed after reading data from backend DB
 * @typedef {object} ProfileData
 * @property {string} id - User ID (This would mostly be the Identity ID since we are using Identity Pool)
 * @property {string} name - Name of User
 * @property {string} email - Email of User
 * @property {number} phone - Phone number
 * @property {number} expiry - Plan expiry epoch
 */

/**
 * User object stored in localstorage.
 * @typedef {Object} StoredUserData
 * @property {!string} u - UserID of the user
 * @property {!string} n - Name of the user
 * @property {!string} e - Email of the user
 * @property {?9876543210} [p] - Phone of the user
 * @property {?string} [pc] - Url of the picture
 * @property {?true} [rg] - When user-data registered / saved in our backend
 * @property {?765234689} [x] - Plan expiry UNIX epoch
 */

// /**
//  * Plan expiry details.
//  * @typedef {Object} PlanExpiryDetails
//  * @property {?Date} expiry - Expiry date | NULL if no expiry date set
//  * @property {!boolean} isActive - TRUE if plan is active, FALSE if inactive or no expiry date set
//  */

/*==================+
|					|
|		CONTEXT		|
|					|
+==================*/
/**
 * React context for User
 */
export const UserContext = createContext({
	isLoggedIn: false,
	/** Since we only save / register user in the backend upon completing his first purchase *(since we do not offer free trial period)*, this flag will be `true` if such is the case. */
	isRegistered: false,
	/** The IdentityID assigned by Identity Pool */
	id: null,
	name: null,
	email: null,

	/** @type {number} */
	phone: null,
	pic: null,
	/**
	 * Note: Do not directly use this state var 'exp' for checking the expiry since it may be prone to user modification
	 * Instead use 'getPlanExpiry()' or 'isPlanActive()' methods to check for it
	 * Also it's best to store it in encrypted form
	 * expiry is just stored in state so as for us to know when the value changes in useEffect() hook of the consumer component.
	 * Thus, since that we are only after change, we could just store any random text and re-randomize the value when the actual expiry changes. This shall cause the useEffect() hook in consumer components to run
	 */
	exp: 0,

	/** Signs out the user by clearing the state & localstorage */
	signOut: () => {},

	/**
	 * Create user session from the id-token issued by an Identity Provider.
	 * @async
	 * @function
	 * @param {!string} idToken - ID-Token issued by an identity-provider like google, fb etc
	 * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	 * @returns {Promise<Creds>|Promise<null>} AWS Creds object or null if session cannot be created.
	 */
	createSess: async (idToken, idp) => {}, // eslint-disable-line no-unused-vars

	// /**
	//  * When the user is still logged-in but the creds have expired, we can use the interim-login modal form to request the logged-in user to re sign-in using IdPs (Google / Fb) etc.
	//  * This function refetches new credentials for the same logged-in user
	//  * @async
	//  * @function
	//  * @param {!string} idToken - ID-Token issued by an identity-provider like google, fb etc
	//  * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	//  * @returns {Promise<?Creds>} AWS Creds object or null if session cannot be created
	//  */
	// reviveSess: async (idToken, idp) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Reads a user's profile from the database.
	 * @async
	 * @function
	 * @param {!string} idParam - The ID of the user whose profile is to be read. Required.
	 * @param {boolean} [updateState=false] - A flag indicating whether to update the state after reading.
	 * @returns {Promise<?ProfileData>} An object containing metadata & the user's profile or null if no user is found in DB.
	 * @example
	 * const profileData = await readUserProfileFromDB('31351-51351fgsdg-1351',true);
	 * // profileData:
	 * {
	 *		id: '31351-51351fgsdg-1351',
	 *		name: 'Jonathan Doe',
	 *		email: 'john.doe@gmail.com',
	 *		phone: 9876543210,
	 *		expiry: 0	//epoch
	 *	}
	 */
	readUserProfileFromDB: async (idParam, updateState) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Write user profile data to the database.
	 * @async
	 * @function
	 * @param {!string} idParam - ID of the user. This is the partition key used in DynamoDB. Required.
	 * @param {?string} [nameParam] - Name of the user
	 * @param {?number} [phoneParam] - Phone number of the user.
	 * @param {!boolean} [skipUpdatingState=false] - A flag indicating whether or not to update the state after writing to DB. By default state is updated.
	 * @returns {Promise<?object>} An object containing the data written to DB or null if write failed.
	 * @example
	 * const newlyUpdatedData = await updateUserProfileInDB('john.doe@gmail.com','John Doe',9876543210);
	 */
	updateUserProfileInDB: async (idParam, nameParam, phoneParam, skipUpdatingState) => {}, // eslint-disable-line no-unused-vars

	/** Only used to cause effect in PlanExpiryGateway component to rerun on change. In the effect inside PlanExpiryGateway we recheck status using checkIfPlanActive() fn */
	isPlanActive: false,

	/**
	 * Retrieves plan expiry date stored in localstorage / state after decrypting it.
	 * @returns {Promise<{expiry:?Date,isActive:Boolean}>} Expiry date and status
	 */
	getPlanExpiry: async () => {},

	/**
	 * Checks if plan is active or has expired (or not set yet).
	 * @returns {Promise<Boolean>} TRUE if plan is active | FALSE otherwise
	 */
	checkIfPlanActive: async () => {},
});

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for User.
 * This component is imported in 'src/containers/Root'
 */
export default function User_ContextProvider({ children }) {
	const [isLoggedIn, setIsLoggedIn] = useState(false); //if there is a valid id-token in localstorage
	const [isRegistered, setIsRegistered] = useState(false); //if the user data is saved in the backend. We only save / register user in the backend upon his first purchase (since we do not offer free trial period)
	const [id, setId] = useState(null); //we use the 'identityId' from Identity Pool itself. We get 'identityId' value when fetching creds
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(/** @type {number} */ (null));
	const [pic, setPic] = useState(null);
	// const [combinedUserData, setCombinedUserData] = useState(null); //used to sync to/from data stored in localstorage (represented here as a as key-val pair object)
	//we watch above state for when to update the corresponding value in storage. Meaning when this changes, we update the storage value too

	const [isPlanActive, setIsPlanActive] = useState(false); //only used to cause effect in PlanExpiryGateway to rerun on change. In the effect inside PlanExpiryGateway we recheck status using checkIfPlanActive() fn
	//Note: Do not directly use this state var 'isPlanActive' or 'exp' for checking the expiry since it may be prone to user modification
	//Instead use 'getPlanExpiry()' or 'isPlanActive()' methods to check for it
	//Also it's best to store it in encrypted form
	//expiry is just stored in state so as for us to know when the value changes in useEffect() hook of the consumer component.
	//Thus, since that we are only after change, we could just store any random text and re-randomize the value when the actual expiry changes. This shall cause the useEffect() hook in consumer components to run
	const [exp, setExp] = useState(0);

	const { notify } = useContext(ToastNotificationContext);
	const isOnline = useContext(NetworkCheckContext);
	const { storageSet, storageGet, storageRemove, storageClear } = useContext(StorageContext);
	const { dbGetItemData, dbQueryData, dbUpdateData } = useContext(DynamoDbContext);
	// const invokeFn = useContext(LambdaContext);
	const { identityId, fetchNewCredsByExchangingIdToken, getCreds, clearCreds } = useContext(AwsCredentialsContext);

	/**
	 * Signs out the user by clearing the state & localstorage
	 * @param {Boolean} [skipInformingContentScript=false] - Whether to skip informing content-script. Useful when this fn is run upon getting a message from content-script and avoid endless-loop
	 */
	const signOut = (skipInformingContentScript) => {
		// if (localStorage.getItem('_u')) localStorage.removeItem('_u');
		storageRemove('_u'); //same as above
		//here inform contentScript of the removal
		if (!skipInformingContentScript) window.postMessage({ action: 'userSignedOut', msgFlowDirection: 'webPage-to-contentScript' }, window.location.origin); //post a message intended to be listened by content-script injected by webextension

		// isLoggedIn && setIsLoggedIn(false);
		// isRegistered && setIsRegistered(false);
		// id !== null && setId(null);
		// name !== null && setName(null);
		// email !== null && setEmail(null);
		// phone !== null && setPhone(null);
		// pic !== null && setPic(null);
		// // if (combinedUserData !== null) setCombinedUserData(null); //this will trigger useEffect hook which handles upkeep of localstorage data
		// exp !== 0 && setExp(0);
		setIsLoggedIn(false);
		setIsRegistered(false);
		setId(null);
		setName(null);
		setEmail(null);
		setPhone(null);
		setPic(null);
		// setCombinedUserData(null); //this will trigger useEffect hook which handles upkeep of localstorage data
		setIsPlanActive(false);
		setExp(0);
		clearCreds(); //also clear creds
	};

	/**
	 * Retrieves plan expiry date stored in localstorage / state after decrypting it.
	 * @returns {Promise<{expiry:?Date,isActive:Boolean}>} Expiry date and status
	 */
	const getPlanExpiry = async () => {
		try {
			/**
			 * @type {StoredUserData}
			 * @see {@link StoredUserData}
			 */
			const userDataFromStorage = await _readUserDataFromStorage(); //read stored-user-data from localstorage
			if (!userDataFromStorage) throw new Error(); //No stored user data
			if (!Object.prototype.hasOwnProperty.call(userDataFromStorage, 'x')) throw new Error();
			// if (typeof userDataFromStorage.x !== 'number' || !Number.isInteger(userDataFromStorage.x)) return null;
			if (isNaN(Number(userDataFromStorage.x))) throw new Error(); //checks if it is a number
			if (!Number.isInteger(userDataFromStorage.x)) throw new Error(); //checks if it is not an integer
			const planExpiryDate = new Date(Number(userDataFromStorage.x * 1000)); //we convert to date. Since javascript Date is milisecond based, we convert Unix epoch to miliseconds
			if (planExpiryDate instanceof Date && isNaN(planExpiryDate.getTime())) throw new Error(); //check if 'planExpiryDate' was parsed into a valid date	//since 'Invalid Date' would return 'NaN'
			// return planExpiryDate;
			return { expiry: planExpiryDate, isActive: Date.now() < planExpiryDate.getTime() };
		} catch (err) {
			// console.error(err);
			return { expiry: null, isActive: false };
		}
	};

	/**
	 * Checks if plan is active or has expired (or not set yet).
	 * @returns {Promise<Boolean>} TRUE if plan is active | FALSE otherwise
	 * @see {@link getPlanExpiry}
	 */
	const checkIfPlanActive = async () => {
		try {
			// const planExpiryDate = getPlanExpiry();
			// if (!planExpiryDate) return false;
			// if (Date.now() >= planExpiryDate.getTime()) return false;
			/**
			 * @type {PlanExpiryDetails}
			 * @see {@link PlanExpiryDetails}
			 */
			const { isActive } = await getPlanExpiry();
			setIsPlanActive(isActive);
			return isActive;
		} catch (err) {
			return false;
		}
	};

	/**
	 * Reads a user's profile from the database.
	 * @async
	 * @function readUserProfileFromDB
	 * @param {!string} usrId - The ID of the user whose profile is to be read. Required.
	 * @param {boolean} [updateStateAndStorage=false] - A flag indicating whether to update the state and storage after reading.
	 * @returns {Promise<?ProfileData>} An object containing metadata & the user's profile or null if no user is found in DB.
	 * @example
	 * const profileData = await readUserProfileFromDB('31351-51351fgsdg-1351',true);
	 * // profileData:
	 * {
	 *		id: '31351-51351fgsdg-1351',
	 *		name: 'Jonathan Doe',
	 *		email: 'john.doe@gmail.com',
	 *		phone: 9876543210,
	 *		expiry: 0	//epoch
	 *	}
	 */
	const readUserProfileFromDB = async (usrId, updateStateAndStorage) => {
		try {
			if (!usrId || typeof usrId !== 'string') {
				usrId = id; //identityId;
			}
			if (usrId == null) throw new Error('Unknown user while fetching profile');
			if (!isOnline) {
				// notify('Please check your network connection', 'err');
				throw new Error('Please check your network connection');
			}
			//fetch from backend database here
			let queryParams = {
				//https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/dynamodb/command/GetItemCommand/
				TableName: 'KaagziUsers',
				Key: { u: usrId },
				// ProjectionExpression: 'Invst', //'Invst, Mngr',	//here we retrieve 2 attributes	//https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/Expressions.ProjectionExpressions.html
			};
			const profileData = await dbGetItemData(queryParams);
			if (!profileData || typeof profileData !== 'object' || profileData.Item == undefined || !Object.prototype.hasOwnProperty.call(profileData, 'Item'))
				throw new Error('Profile data unavailable for user');
			if (!Object.keys(profileData?.Item).length) throw new Error('Profile data unavailable for user');
			if (updateStateAndStorage) {
				await _updateUserDataAlreadyStoredInStorage(
					usrId,
					profileData.Item.n || null,
					profileData.Item.p || null,
					null,
					true,
					profileData.Item.x || null,
				);
				//here update state
				profileData.Item?.n && profileData.Item?.n !== name && setName(profileData.Item.n);
				profileData.Item?.e && profileData.Item?.e !== email && setEmail(profileData.Item.e);
				profileData.Item?.p &&
					profileData.Item?.p !== phone &&
					!isNaN(Number(profileData.Item.p)) &&
					Number.isInteger(Number(profileData.Item.p)) &&
					setPhone(Number(profileData.Item.p));
				profileData.Item?.x && profileData.Item?.x !== exp && setExp(profileData.Item.x);
				if (!isRegistered) setIsRegistered(true); //since user-data is stored in our backend (from where it is being fetched right now), we mark user as being registered
			}
			// console.log('Phone: ', profileData.Item.p);
			// return profileData.Item;
			return {
				id: usrId,
				name: profileData.Item?.n,
				email: profileData.Item?.e,
				phone: profileData.Item?.p,
				expiry: profileData.Item?.x || 0,
			};
		} catch (err) {
			// console.log(err);
			// notify(err.message || 'Error fetching user profile data', 'err');
			return null;
		}
	};

	/**
	 * Write user profile data to the database.
	 * @param {?string} [nameParam] - Name of the user
	 * @param {?number} [phoneParam] - Phone number of the user.
	 * @param {!boolean} [skipUpdatingState=false] - A flag indicating whether or not to update the state after writing to DB. By default state is updated.
	 * @returns {Promise<?object>} An object containing the data written to DB or null if write failed.
	 * @example
	 * const newlyUpdatedData = await updateUserProfileInDB('john.doe@gmail.com','John Doe',9876543210);
	 */
	const updateUserProfileInDB = async (nameParam, phoneParam, skipUpdatingState) => {
		try {
			if (!id) throw new Error('Unknown user while saving profile data');
			//if neither of the foll is present, we do not proceed
			if (!nameParam && !phoneParam) throw new Error('No user data to save');
			if (nameParam?.trim() == '' && phoneParam?.trim() == '') throw new Error('No user data to save');
			//here check if data being written to DB is really being updated, ie' different compared to current state
			if (nameParam == name && phoneParam === phone) throw new Error('Data is already saved');
			if (!isOnline) throw new Error('Please check your network connection');
			//write to db here using 'put'	//we do not use 'put' since this op replaces the row-item completely. Use update instead
			// let Item = {
			// 	p: idParam,
			// 	s: 'd',
			// };
			// if (nameParam && nameParam.trim() !== '' && nameParam !== name) Item.n = nameParam;
			// if (phoneParam && phoneParam.trim() !== '' && phoneParam !== phone) Item.ph = phoneParam;
			// let putParams = {
			// 	TableName: 'Kaagzi',
			// 	Item,
			// };
			// const putData = await dbPutData(putParams, creds);
			// console.log('writeUserProfileToDB() --> putData', putData);
			// // putData_shape -- {
			// // 	$metadata: {
			// // 		httpStatusCode: 200,
			// // 		requestId: '2NS0PDOSK5EJ235RA9BPOIAITJVV4KQNSO5AEMVJF66Q9ASUAAJG',
			// // 		attempts: 1,
			// // 		totalRetryDelay: 0,
			// // 	},
			// // };
			// if (!putData) throw new Error();
			// return putData;

			//Update DB item attributes using 'update'
			// const updateExpression = 'SET #n = :n, #p = :p';
			// const expressionAttributeValues = { ':n': nameParam, ':p': phoneParam };
			// const expressionAttributeNames = { '#n': 'n', '#p': 'ph' };
			let updateExpression = '',
				expressionAttributeNames = {},
				expressionAttributeValues = {};
			if (nameParam && nameParam.trim() !== '' && nameParam !== name) {
				updateExpression += '#nameAtr = :nameVal';
				expressionAttributeNames['#nameAtr'] = 'n';
				expressionAttributeValues[':nameVal'] = nameParam;
			}
			if (phoneParam && phoneParam.trim() !== '' && phoneParam !== phone) {
				if (updateExpression.trim() !== '') updateExpression += ', ';
				updateExpression += '#phnAtr = :phnVal';
				expressionAttributeNames['#phnAtr'] = 'p';
				expressionAttributeValues[':phnVal'] = phoneParam;
			}
			if (updateExpression.trim() === '' || Object.keys(expressionAttributeNames).length < 1 || Object.keys(expressionAttributeValues).length < 1)
				throw new Error('No user data to be saved');
			const updateParams = {
				TableName: 'KaagziUsers',
				Key: { u: id },
				UpdateExpression: 'SET ' + updateExpression,
				ExpressionAttributeValues: expressionAttributeValues,
				ExpressionAttributeNames: expressionAttributeNames,
				ReturnValues: 'UPDATED_NEW',
			};
			const newlyUpdatedData = await dbUpdateData(updateParams);
			// console.log('writeUserProfileToDB() --> newlyUpdatedData', newlyUpdatedData);
			if (!newlyUpdatedData) throw new Error();

			if (!skipUpdatingState) {
				//here probably update storage too
				await _updateUserDataAlreadyStoredInStorage(id, nameParam || null, phoneParam || null, null, true, null);
				//here update state
				nameParam && nameParam !== name && setName(nameParam);
				phoneParam && phoneParam !== phone && setPhone(Number(phoneParam));
			}
			return newlyUpdatedData;
		} catch (err) {
			// console.error(err);
			notify(err.message || 'Error saving user profile data', 'err');
			return null;
		}
	};

	/**
	 * Creates user session from the id-token issued by an Identity Provider.
	 * @async
	 * @function createSess
	 * @param {!string} idToken - ID-Token issued by an identity-provider like google, fb etc
	 * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	 * @returns {Promise<?Creds>} AWS Creds object or null if session cannot be created
	 */
	const createSess = async (idToken, idp) => {
		try {
			if (!isOnline) throw new Error('Please check your network connection');
			//here check if a session is already in progress and if yes signOut() before proceeding

			//save idToken to localstorage (or maybe save custom encrypted idToken containing only info like name and email)
			const jwtPayload = decodeJWT(idToken);
			// console.log('jwtPayload:', jwtPayload);
			//NOTE that its best to validate it first before trusting the claims
			//but since we are fetching creds here via Amazon IdentityPool, AWS IdentityPool validates this JWT before issuing creds
			//and if that we have the creds issued, it's safe to assume that JWT has been validated server side by AWS IdentityPool
			//https://developers.google.com/identity/gsi/web/guides/verify-google-id-token
			if (!jwtPayload) throw new Error('Error parsing ID-token issued by ' + idp);

			//if user is already logged-in (meaning his profile data is in state), we only need to refresh the creds
			// if (id !== null) {	//somehow this doesn't seem to read updated values
			// 	console.log('Session revived for logged-in user');
			// 	return await reviveSess(idToken, idp); //return the refreshed creds
			// }

			/** @type {Creds} @see {@link Creds} */
			const creds = await fetchNewCredsByExchangingIdToken(idToken, idp);
			// console.log('createSess(): fetched new creds', creds);
			if (!creds) throw new Error('Error fetching credentials');
			if (!Object.prototype.hasOwnProperty.call(creds, 'identityId')) throw new Error('Error reading user-identity when fetching credentials');
			if (!creds.identityId || typeof creds.identityId !== 'string' || creds.identityId?.trim?.() === '')
				throw new Error('Error inferring user-identity when fetching credentials');

			const usrId = creds.identityId;
			let usrName = null,
				usrEmail = null,
				usrPhone = null,
				usrPic = null,
				usrRegStatus = false,
				usrPlanExpiry = 0;

			const storedUserData = await _readUserDataFromStorage();
			//here we first check if user is already logged-in and user-data for the user logging-in now is already stored in storage
			//this may be the case in case of interim login when user is already logged-in but only the creds hav expired
			if (storedUserData !== null && storedUserData.u === usrId) {
				usrName = storedUserData.n;
				usrEmail = storedUserData.e;
				usrPhone = storedUserData.p || null;
				usrPic = storedUserData.pc || null;
				usrRegStatus = storedUserData.rg || false;
				usrPlanExpiry = storedUserData.x || 0;
			} else {
				//else fetch data from backend
				/** @type {ProfileData} @see {@link ProfileData} */
				const profileDataStoredInBackend = await readUserProfileFromDB(usrId, false);

				if (idp === 'google') {
					//here fetch user profile details from backend, which wud also include plan expiry details
					// const profileDataStoredInBackend = await readUserProfileFromDB(jwtPayload.email, false); //with the second parameter, we ask the fn not to update state since we do it here below

					//here populate vars from 'google' idToken. Data stored in our backend has priority out data from the idToken
					usrName = profileDataStoredInBackend?.name || jwtPayload.name; //priorotize data from our backend
					usrEmail = jwtPayload.email;
					usrPhone = profileDataStoredInBackend?.phone || jwtPayload?.phone || null;
					usrPic = jwtPayload?.picture && jwtPayload?.picture?.trim() !== '' ? jwtPayload.picture : null; //since we do not store 'pic' url in backend we only read from idToken payload
					if (profileDataStoredInBackend !== null) {
						usrRegStatus = true; //meaning user data is registered in our backend and since that we only register user-data in backend when he does his first plan purchase, we shall have the planExpiry data too
						usrPlanExpiry = profileDataStoredInBackend?.expiry || 0;
					}
				}
				// if (idp === 'facebook') {
				// 	//repeat code from above block except for takaing into acc data stored in relevant jwtPayload properties
				// }
				// console.log('createSession() --> profileDataStoredInBackend?.phone', profileDataStoredInBackend?.phone);
				// console.log('createSession() --> jwtPayload?.phone', jwtPayload?.phone);
				// console.log('createSession() --> usrPhone', usrPhone);
			}
			//here update state and localstorage
			const isUserDataSetupDone = await _writeUserDataToStateAndStorage_onlyIfDataChanged(
				usrId,
				usrName,
				usrEmail,
				usrPhone,
				usrPic,
				usrRegStatus,
				usrPlanExpiry,
			);
			if (!isUserDataSetupDone) {
				signOut();
				return null;
			}
			return creds;
		} catch (err) {
			notify(err.message || 'Error creating user session', 'warn');
			return null;
		}
	};

	// NOTE: Foll fn is not needed anymore since the functionality has now been built in creatSess() fn
	// /**
	//  * When the user is still logged-in but the creds have expired, we can use the interim-login modal form to request the logged-in user to re sign-in using IdPs (Google / Fb) etc.
	//  * This function refetches new credentials for the same logged-in user
	//  * @async
	//  * @function reviveSess
	//  * @param {!string} idToken - ID-Token issued by an identity-provider like google, fb etc
	//  * @param {!'cognitoUserPool'|'facebook'|'google'} idp - Identity provider that issued the ID-Token
	//  * @returns {Promise<?Creds>} AWS Creds object or null if session cannot be created
	//  */
	/* const reviveSess = async (idToken, idp) => {
		try {
			if (!isOnline) throw new Error('Please check your network connection');
			//here check if the re-issued id-token during interim-login is for the same user whose session is already underway (to be revived)
			const jwtPayload = decodeJWT(idToken);
			if (idp === 'google' && jwtPayload.email !== email) throw new Error(`User other than "${name}" can only sign in after first logging out`);
			//here check if already issued creds are still valid (just in case this fn gets called even when creds are still valid)
			const existingCreds = await getCreds();
			if (existingCreds) return existingCreds;

			//exchange id-token for aws creds
			//** @type {Creds} @see {@link Creds}
			const creds = await fetchNewCredsByExchangingIdToken(idToken, idp);
			if (!creds) throw new Error('Error fetching credentials');
			if (!Object.prototype.hasOwnProperty.call(creds, 'identityId')) throw new Error('Error reading user-identity when fetching credentials');
			if (!creds.identityId || typeof creds.identityId !== 'string' || creds.identityId?.trim?.() === '')
				throw new Error('Error inferring user-identity when fetching credentials');
			//if a different user has logged-in using interim login
			if (creds.identityId !== id) {
				//or maybe we create session for the different user who has signed in
				notify(`Expected ${name} to continue last session! Session has been ended!`, 'warn');
				signOut();
				return null;
			}
			//here the creds would have been refreshed
			notify(`Welcome back ${name}!`, 'success');
			// console.log('Function is TO-DO');
			return creds;
		} catch (err) {
			notify(err.message || 'Error reviving session', 'warn');
			return null;
		}
	}; */

	//--------------------------------------+
	//		USER-DATA in LOCAL-STORAGE		|
	//--------------------------------------+
	/**
	 * Sets user data state values if value has really changed.
	 * And writes user data to localstorage only if data has really changed.
	 * @param {!string} usrId - ID of user
	 * @param {!string} usrName - Name of user
	 * @param {!string} usrEmail - Email of user
	 * @param {?number} [usrPhone] - Phone number of user
	 * @param {?string} [usrPic] - Picture url of user
	 * @param {!boolean} [usrRegStatus] - True for user registered in backend. False otherwise
	 * @param {?number|0} [usrPlanExpiry] - Plan expiry UNIX epoch. 0 or null for no plan purchased yet
	 * @returns {Promise<boolean>} True if attempted to update all user-data values in state and updated localstorage. False otherwise
	 */
	const _writeUserDataToStateAndStorage_onlyIfDataChanged = async (usrId, usrName, usrEmail, usrPhone, usrPic, usrRegStatus, usrPlanExpiry) => {
		try {
			//we write-to/update storage before updating state coz other children components which rerender on 'exp' state change, wud b looking to read 'usrPlanExpiry' value from storage rather than from state for security reasons
			await _writeUserDataToStorage_onlyIfDataHasChanged(usrId, usrName, usrEmail, usrPhone, usrPic, usrRegStatus, usrPlanExpiry);
			const attemptedToUpdateAllUserDataStateValues = _writeUserDataToState_onlyIfValHasChanged(
				usrId,
				usrName,
				usrEmail,
				usrPhone,
				usrPic,
				usrRegStatus,
				usrPlanExpiry,
			);
			if (!attemptedToUpdateAllUserDataStateValues) return false;
			//foll has been moved above to before we update state
			// await _writeUserDataToStorage_onlyIfDataHasChanged(usrId, usrName, usrEmail, usrPhone, usrPic, usrRegStatus, usrPlanExpiry);
			return true;
		} catch (err) {
			return false;
		}
	};

	/**
	 * Reads stored user-data from ***localstorage***
	 * @returns {Promise<?StoredUserData>} Fulfils with user data if reading was successful otherwise null
	 * @see {@link StoredUserData}
	 */
	const _readUserDataFromStorage = async () => {
		try {
			// const userDataStrFromStorage = localStorage.getItem('_u');
			const userDataStrFromStorage = await storageGet('_u', 'kaagziUser');
			if (!userDataStrFromStorage) throw new Error('No stored user data');
			/**
			 * @type {StoredUserData}
			 * @see {@link StoredUserData}
			 */
			const userDataFromStorage = JSON.parse(userDataStrFromStorage);
			if (!userDataFromStorage) throw new Error('Error parsing stored user data');

			//check for mandatory data
			if (
				!Object.prototype.hasOwnProperty.call(userDataFromStorage, 'u') || //ID of user
				!Object.prototype.hasOwnProperty.call(userDataFromStorage, 'n') || //Name of user
				!Object.prototype.hasOwnProperty.call(userDataFromStorage, 'e') //Email of user
			)
				throw new Error('Stored user data is missing reqired details');
			if (
				typeof userDataFromStorage.u !== 'string' || //check type
				typeof userDataFromStorage.n !== 'string' ||
				typeof userDataFromStorage.e !== 'string'
			)
				throw new Error('Stored user data details are in invalid format');
			if (
				userDataFromStorage.u.trim() === '' || //check for empty str
				userDataFromStorage.n.trim() === '' ||
				userDataFromStorage.e.trim() === ''
			)
				throw new Error('Stored user data details are empty');
			return userDataFromStorage;
		} catch (err) {
			// console.log('_readUserDataFromStorage()', err.message || err);
			return null;
		}
	};

	/**
	 * Saves user data to localstorage only if data has really changed.
	 * @param {!string} usrId - ID of user
	 * @param {!string} usrName - Name of user
	 * @param {!string} usrEmail - Email of user
	 * @param {?number} [usrPhone] - Phone number of user
	 * @param {?string} [usrPic] - Picture url of user
	 * @param {?Boolean} [usrRegStatus] - True for user registered in backend. False otherwise
	 * @param {?number|0} [usrPlanExpiry] - Plan expiry UNIX epoch. 0 or null for no plan purchased yet
	 * @returns {Promise<boolean>} True if value in storage updated. False otherwise
	 */
	const _writeUserDataToStorage_onlyIfDataHasChanged = async (usrId, usrName, usrEmail, usrPhone, usrPic, usrRegStatus, usrPlanExpiry) => {
		try {
			//check for mandatory data
			if (!usrId || typeof usrId !== 'string' || !usrName || typeof usrName !== 'string' || !usrEmail || typeof usrEmail !== 'string') return false;
			if (usrId.trim() === '' || usrName.trim() === '' || usrEmail.trim() === '') return false;
			/**
			 * @type {StoredUserData}
			 * @see {@link StoredUserData}
			 */
			const newUserData = {
				u: usrId,
				n: usrName,
				e: usrEmail,
			};
			// if (usrPhone && typeof usrPhone == 'number' && Number.isInteger(usrPhone)) newUserData.p = usrPhone;
			if (usrPhone && !isNaN(Number(usrPhone)) && Number.isInteger(Number(usrPhone))) newUserData.p = Number(usrPhone);
			if (usrPic && typeof usrPic === 'string' && usrPic?.trim() !== '') newUserData.pc = usrPic;
			if (usrRegStatus && typeof usrRegStatus === 'boolean') newUserData.rg = true; //1;
			if (usrPlanExpiry && typeof usrPlanExpiry == 'number' && Number.isInteger(usrPlanExpiry) && usrPlanExpiry > 0) newUserData.x = usrPlanExpiry;

			const userDataInStorage = await _readUserDataFromStorage(); //read stored-user-data from localstorage
			// if (!userDataInStorage) {
			// 	// localStorage.setItem('_u', JSON.stringify(newUserData)); //write new data to localstoarage
			// 	const encryptedStr = await storageSet('_u', newUserData, 'kaagziUser');
			// 	//here inform contentScript of the updates
			// 	window.postMessage({ action: 'userUpdated', msgFlowDirection: 'webPage-to-contentScript', user: encryptedStr }, window.location.origin); //post a message intended to be listened by content-script injected by webextension
			// } else {
			// 	if (areObjectsEqual(newUserData, userDataInStorage)) return false; //we do not need to update localstorage since data values are same

			// 	// localStorage.setItem('_u', JSON.stringify(newUserData));
			// 	const encryptedStr = await storageSet('_u', newUserData, 'kaagziUser');
			// 	//here inform contentScript of the updates
			// 	window.postMessage({ action: 'userUpdated', msgFlowDirection: 'webPage-to-contentScript', user: encryptedStr }, window.location.origin); //post a message intended to be listened by content-script injected by webextension
			// }
			if (userDataInStorage && areObjectsEqual(newUserData, userDataInStorage)) return false; //we do not need to update localstorage since data values are same

			// localStorage.setItem('_u', JSON.stringify(newUserData));
			const encryptedStr = await storageSet('_u', newUserData, 'kaagziUser');
			//here inform contentScript of the updates
			window.postMessage({ action: 'userUpdated', msgFlowDirection: 'webPage-to-contentScript', user: encryptedStr }, window.location.origin); //post a message intended to be listened by content-script injected by webextension

			return true;
		} catch (err) {
			return false;
		}
	};

	/**
	 * Updates optional user data already stored in storage.
	 * @param {!string} usrId - ID of user
	 * @param {!string} [usrName] - Name of user
	 * @param {?number} [usrPhone] - Phone number of user
	 * @param {?string} [usrPic] - Picture url of user
	 * @param {?Boolean} [usrRegStatus] - True for user registered in backend. False otherwise
	 * @param {?number|0} [usrPlanExpiry] - Plan expiry UNIX epoch. 0 or NULL for no plan purchased yet
	 * @returns {Promise<boolean>} True if value in storage updated. False otherwise
	 */
	const _updateUserDataAlreadyStoredInStorage = async (usrId, usrName, usrPhone, usrPic, usrRegStatus, usrPlanExpiry) => {
		try {
			/**
			 * @type {StoredUserData}
			 * @see {@link StoredUserData}
			 */
			const userDataInStorage = await _readUserDataFromStorage(); //read already stored-user-data from localstorage
			if (!userDataInStorage) throw new Error(); //no need to proceed if no user already stored
			if (userDataInStorage.u !== usrId) throw new Error(); //already stored user is different than the one we are updating data for

			//prefill mandatory data
			const newUserData = {
				u: usrId,
				n: usrName && typeof usrName == 'string' && usrName?.trim() !== '' ? usrName : userDataInStorage.n,
				e: userDataInStorage.e,
			};

			// if (usrPhone && typeof usrPhone == 'number' && Number.isInteger(usrPhone)) {
			if (usrPhone && !isNaN(Number(usrPhone)) && Number.isInteger(Number(usrPhone))) {
				newUserData.p = Number(usrPhone);
			} else if (userDataInStorage.p) {
				newUserData.p = userDataInStorage.p;
			}
			if (usrPic && typeof usrPic === 'string' && usrPic?.trim() !== '') {
				newUserData.pc = usrPic;
			} else if (userDataInStorage.pc) {
				newUserData.pc = userDataInStorage.pc;
			}
			if (usrRegStatus && typeof usrRegStatus === 'boolean') {
				newUserData.rg = true; //1;
			} else if (Object.prototype.hasOwnProperty.call(userDataInStorage, 'rg')) {
				newUserData.rg = userDataInStorage.rg;
			}
			if (usrPlanExpiry && typeof usrPlanExpiry == 'number' && Number.isInteger(usrPlanExpiry) && usrPlanExpiry > 0) {
				newUserData.x = usrPlanExpiry;
			} else if (Object.prototype.hasOwnProperty.call(userDataInStorage, 'x')) {
				newUserData.x = userDataInStorage.x;
			}

			if (userDataInStorage && areObjectsEqual(newUserData, userDataInStorage)) return false; //we do not need to update localstorage since data values are same

			// localStorage.setItem('_u', JSON.stringify(newUserData));
			const encryptedStr = await storageSet('_u', newUserData, 'kaagziUser');
			//here inform contentScript of the updates
			window.postMessage({ action: 'userUpdated', msgFlowDirection: 'webPage-to-contentScript', user: encryptedStr }, window.location.origin); //post a message intended to be listened by content-script injected by webextension

			return true;
		} catch (err) {
			return false;
		}
	};

	/**
	 * Sets user data state values if value has really changed.
	 * @function _writeUserDataToState_onlyIfValHasChanged
	 * @param {!string} usrId - ID of user
	 * @param {!string} usrName - Name of user
	 * @param {!string} usrEmail - Email of user
	 * @param {?number} [usrPhone] - Phone number of user
	 * @param {?string} [usrPic] - Picture url of user
	 * @param {?Boolean} [usrRegStatus] - True for user registered in backend. False otherwise
	 * @param {?number|0} [usrPlanExpiry] - Plan expiry UNIX epoch. 0 or null for no plan purchased yet
	 * @returns {boolean} True if attempted to update all user-data values in state. False otherwise
	 */
	const _writeUserDataToState_onlyIfValHasChanged = (usrId, usrName, usrEmail, usrPhone, usrPic, usrRegStatus, usrPlanExpiry) => {
		//check for mandatory data
		if (!usrId || typeof usrId !== 'string' || !usrName || typeof usrName !== 'string' || !usrEmail || typeof usrEmail !== 'string') {
			signOut();
			return false;
		}
		if (usrId.trim() === '' || usrName.trim() === '' || usrEmail.trim() === '') {
			signOut();
			return false;
		}
		setId(usrId); // usrId !== id && setId(usrId);
		setName(usrName); //usrName !== name && setName(usrName);
		setEmail(usrEmail); //usrEmail !== email && setEmail(usrEmail);
		//check for optional data
		// if (usrPhone && typeof usrPhone == 'number' && Number.isInteger(usrPhone)) {
		// console.log('_writeUserDataToState_onlyIfValHasChanged() --> usrPhone:', usrPhone);
		if (usrPhone && !isNaN(Number(usrPhone)) && Number.isInteger(Number(usrPhone))) {
			// console.log('_writeUserDataToState_onlyIfValHasChanged() --> Writing user phone to state');
			setPhone(Number(usrPhone)); //usrPhone !== phone && setPhone(usrPhone);
		} else {
			// console.log('_writeUserDataToState_onlyIfValHasChanged() --> User Phone is NULL');
			setPhone(null); //usrPhone !== null && setPhone(null);
		}
		// console.log('_writeUserDataToState_onlyIfValHasChanged() --> usrPhone:', typeof usrPhone);
		if (usrPic && typeof usrPic === 'string' && usrPic?.trim() !== '') {
			setPic(usrPic); //usrPic !== pic && setPic(usrPic);
		} else {
			setPic(null); //usrPic !== null && setPic(null);
		}
		if (usrRegStatus && typeof usrRegStatus === 'boolean') {
			setIsRegistered(true); //if (!isRegistered) setIsRegistered(true);
		} else {
			setIsRegistered(false); //isRegistered && setIsRegistered(false);
		}
		if (usrPlanExpiry && typeof usrPlanExpiry == 'number' && Number.isInteger(usrPlanExpiry) && usrPlanExpiry > 0) {
			setExp(usrPlanExpiry); //usrPlanExpiry !== exp && setExp(usrPlanExpiry);
		} else {
			setExp(0); //usrPlanExpiry !== 0 && setExp(0);
		}
		setIsLoggedIn(true); //if (!isLoggedIn) setIsLoggedIn(true);
		return true;
	};

	/**
	 * Updates user-data state values with that of data from localstorage
	 * @function _updateStateWithDataFromStorage
	 * @returns {Promise<boolean>} Fulfils with true if attempt to update state was made otherwise false
	 */
	const _updateStateWithDataFromStorage = async () => {
		try {
			/**
			 * @type {StoredUserData}
			 * @see {@link StoredUserData}
			 */
			const userDataFromStorage = await _readUserDataFromStorage(); //read stored-user-data from localstorage
			if (!userDataFromStorage) return false; //throw new Error();
			_writeUserDataToState_onlyIfValHasChanged(
				userDataFromStorage.u,
				userDataFromStorage.n,
				userDataFromStorage.e,
				userDataFromStorage?.p,
				userDataFromStorage?.pc,
				Object.prototype.hasOwnProperty.call(userDataFromStorage, 'rg') && userDataFromStorage?.rg, // == 1,
				userDataFromStorage?.x,
			);

			// _setCombinedUserData_onlyIfNewValIsDifferent(userDataFromStorage.n, userDataFromStorage.e, userDataFromStorage.ph, userDataFromStorage.p); //this will trigger below useEffect, which shall trigger storage event in other tabs
			console.log('User state restored from and synced with storage');
			return true;
		} catch (err) {
			// if (combinedUserData !== null) setCombinedUserData(null); // won't be required since we start tab session with 'combinedUserData' as 'null' on page-load
			signOut();
			return false;
		}
	};

	/**
	 * We watch for when 'user-data' stored in ***localstorage*** is updated by code from another tab
	 * @param {!StorageEvent} e - Storage Event
	 */
	const _handleUserDataChangesInStorage = (e) => {
		// console.log('storageArea:', e.storageArea);
		if (e.key == '_u') {
			// console.log('Localstorage user-data changed by another TAB');
			if (e.newValue == null) {
				console.log('User-data removed from localstorage by other TAB');
				signOut();
				// _setCombinedUserData_onlyIfNewValIsDifferent(null);
			} else {
				console.log('user-data updated in localstorage from another tab: ', e.newValue);
				// (async () => {
				// 	await _updateStateWithDataFromStorage(); //we do not pass e.newValue to the fn since anyhow the fn reads latest value from localstorage
				// })();
				_updateStateWithDataFromStorage(); //we do not pass e.newValue to the fn since anyhow the fn reads latest value from localstorage
			}
		}
	};

	/**
	 * We listen for any messages from the content-script
	 * @param {!StorageEvent} event - Storage Event
	 */
	const _handleMsgsFromContentScript = (event) => {
		if (event.source != window) return; // We only accept messages from ourselves, (ie sent by the same window object the content-script is running in)
		if (!event.origin.startsWith('http://localhost:') && event.origin !== 'https://kaagzi.in') {
			console.warn('Does not accept incoming messages from ' + event.origin + ' origin');
			return;
		}
		//here also chk if message is from content-script to webpage and not the other way around
		if (!Object.prototype.hasOwnProperty.call(event.data, 'msgFlowDirection')) return;
		if (event.data.msgFlowDirection !== 'contentScript-to-webPage') return;

		if (!Object.prototype.hasOwnProperty.call(event.data, 'action')) return;
		switch (event.data.action) {
			case 'userUpdated':
				//The content-script would have already written the updated data to the localstorage
				_updateStateWithDataFromStorage();
				break;
			case 'userSignedOut':
				signOut(true);
				break;
			// default:
			// 	//do something default
			// 	console.log('User_ContextProvider received message from content-script with foll action: ' + event.data.action);
		}
	};

	//==========================+
	//							|
	//		EFFECT-HOOKS		|
	//							|
	//==========================+

	//==========================+
	//		RUN ON MOUNT		|
	//==========================+

	useEffect(() => {
		//check for stored user & creds data and update state
		// (async () => {
		// 	await _updateStateWithDataFromStorage(); //read creds/session validity data from localstorage into state
		// })();
		_updateStateWithDataFromStorage(); //read creds/session validity data from localstorage into state

		//we listen for any changes to session objects stored in localstorage by code from other tabs running this app
		window.addEventListener('storage', _handleUserDataChangesInStorage);

		window.addEventListener('message', _handleMsgsFromContentScript);

		return () => {
			window.removeEventListener('storage', _handleUserDataChangesInStorage);
			window.removeEventListener('message', _handleMsgsFromContentScript);
		};
	}, []); //runs only on mount

	//======================================================+
	//		RUN ON MOUNT & if 'dentityId' (creds) change	|
	//======================================================+

	// useEffect(() => {
	// 	//check for stored user & creds data and update state
	// 		_updateStateWithDataFromStorage(); //read creds/session validity data from localstorage into state
	// }, [identityId]);

	/* 
	//==================================================+
	//		RUN ON 'combinedUserData' STATE CHANGE		|
	//==================================================+
	// Updates localstorage when 'combinedUserData' state value changes

	const isEffectFirstRunOnMountCompleted = useRef(false);
	useEffect(() => {
		//write to localstorage
		if (isEffectFirstRunOnMountCompleted.current) {
			if (combinedUserData == null) {
				if (localStorage.getItem('_u')) {
					//only remove if item is presnet
					console.log('Deleting user data from localstorage since combinedUserData state set to null');
					localStorage.removeItem('_u');
				}
			} else {
				//if combinedUserData is already set, update it if it is different
				if (localStorage.getItem('_u')) {
					//if (combinedUserData !== localStorage.getItem('_u')) {	//only write to localstorgae if data is different
					if (!areObjectsEqual(combinedUserData, JSON.parse(localStorage.getItem('_u')))) {
						console.log('Updating user data in localstorage since combinedUserData state changed / updated');
						localStorage.setItem('_u', JSON.stringify(combinedUserData));
					}
				} else {
					console.log('Writing new user data to localstorage since combinedUserData state changed / updated');
					localStorage.setItem('_u', JSON.stringify(combinedUserData));
				}
			}
		} else {
			isEffectFirstRunOnMountCompleted.current = true; //on first run we set this to true so that on subsequent runs, we execute code from above if-stmt
		}
	}, [combinedUserData]); //we only allow fn body to run when 'combinedUserData' changes and not on first mount

	//==========================================+
	//		RUN ON USER-DATA STATE CHANGE		|
	//==========================================+
	// Updates 'combinedUserData' when individual userData changes

	const isEffect2FirstRunOnMountCompleted = useRef(false);
	useEffect(() => {
		if (isEffect2FirstRunOnMountCompleted.current) {
			if (name == null && email == null && phone == null && pic == null && exp == null && isRegistered == false && combinedUserData != null) {
				setCombinedUserData(null);
			} else {
				_setCombinedUserData_onlyIfNewValIsDifferent(name, email, phone, pic); //this updates the combinedUserData state --> runs above useEffect --> updates localstorage
			}
		} else {
			isEffect2FirstRunOnMountCompleted.current = true; //on first run we set this to true so that on subsequent runs, we execute code from above if-stmt
		}
	}, [name, email, phone, pic, exp, isRegistered]); //run when individual user properties change
 	*/
	return (
		<UserContext.Provider
			value={{
				isLoggedIn,
				isRegistered,
				id,
				name,
				email,
				phone,
				pic,
				signOut,
				createSess,
				// reviveSess,
				readUserProfileFromDB,
				updateUserProfileInDB,
				exp,
				isPlanActive,
				getPlanExpiry,
				checkIfPlanActive,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}
