import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag'; //for analytics
import Icon from '@components/ui/Icon';

// import './button.css';
import * as styles from './index.module.css';

/**
 * Button React Component
 * @function Button
 * @param {Object} props - Props object
 * @param {string} [props.id] - Id attriute
 * @param {('button'|'submit')} [props.type='button'] - Type of button
 * @param {('primary'|'secondary'|'tertiary'|'buy'|'sell')} [props.variant='primary'] - Type of button
 * @param {Boolean} [props.isDark=false] - Whether to use dark style
 * @param {('small'|'smaller'|'big'|'bigger')} [props.size] - Button size
 * @param {()=>{}} [props.onClick] - Onclick function
 * @param {Boolean} [props.isDisabled=false] - Whether disabled
 * @param {Boolean} [props.isWip=false] - Whether to show wip spinner
 * @param {String} [props.wipText] - Text to show when wip spinner is shown
 * @param {String} [props.icon] - Icon to show
 * @param {Boolean} [props.isIconOnRight=false] - Whether to show icon on the right side of text
 * @param {JSX.Element} [props.children] - Children components
 * @param {?String} [props.className] - CSS class set on this component element
 * @returns {JSX.Element} - Button component
 */
export default function Button({
	id,
	type,
	variant,
	isDark,
	size,
	onClick,
	isDisabled,
	isWip,
	wipText,
	icon,
	isIconOnRight,
	children,
	className,
	...restProps
}) {
	return (
		<button
			tabIndex="0"
			id={id}
			// className={className?'btn '+className:'btn'}
			className={clsx(
				styles.btn, //'button',
				// variant == 'secondary'
				// 	? styles.secondary //'button--secondary'
				// 	: variant == 'tertiary'
				// 	? styles.tertiary //'button--tertiary'
				// 	: styles.primary, //'button--primary'
				// isDark && styles.dark,
				// isWip && styles.wip,
				// size == 'small' && styles.small,
				// size == 'smaller' && styles.smaller,
				// size == 'big' && styles.big,
				// size == 'bigger' && styles.bigger,
				// icon && icon !== '' && styles.ico,
				// icon && icon !== '' && isIconOnRight && styles.icoRight,
				variant == 'primary' && styles.primary,
				variant == 'secondary' && styles.secondary,
				variant == 'tertiary' && styles.tertiary,
				variant == 'buy' && styles.primaryBuy,
				variant == 'sell' && styles.primarySell,
				!['primary', 'secondary', 'tertiary', 'buy', 'sell'].includes(variant) && styles.primary, //default if no acceptable variant specified
				isDark && styles.dark,
				isWip && styles.wip,
				size == 'small' && styles.small,
				size == 'smaller' && styles.smaller,
				size == 'big' && styles.big,
				size == 'bigger' && styles.bigger,
				icon && icon !== '' && styles.ico,
				icon && icon !== '' && isIconOnRight && styles.icoRight,
				className && typeof className === 'string' && className.trim(),
				/* 'button',
				type=='secondary' ? 
					'button--secondary'
					:
					type=='tertiary' ?
						'button--tertiary'
						:
						'button--primary'
				,
				size=='small' && 'button--sm',
				size=='large' && 'button--lg' */
			)}
			type={type || 'button'}
			onClick={onClick}
			disabled={isDisabled || isWip ? 'disabled' : ''}
			{...restProps}
		>
			{/* {isWip ?
				wipText.trim() !== '' ? wipText.trim() : 'Loading...'
				:
				<span>{children || 'Button'}</span>
			} */}
			<>
				{!isWip && icon && icon !== '' && <Icon icon={icon} />}
				{children && <span>{isWip ? (wipText.trim() !== '' ? wipText.trim() : 'Loading...') : children}</span>}
			</>
		</button>
	);
}

/**
 * LinkButton React Component
 * @function LinkButton
 * @param {Object} props - Props object
 * @param {string} [props.id] - Id attriute
 * @param {String} props.to - pathname or url
 * @param {'primary'|'secondary'|'tertiary'} [props.variant='primary'] - Type of button
 * @param {Boolean} [props.isDark=false] - Whether to use dark style
 * @param {'small'|'smaller'|'big'|'bigger'} [props.size] - Button size
 * @param {String} [props.icon] - Icon to show
 * @param {Boolean} [props.isIconOnRight=false] - Whether to show icon on the right side of text
 * @param {JSX.Element} [props.children] - Children components
 * @param {?String} [props.className] - CSS class set on this component element
 * @returns {JSX.Element} - Anchor component
 */
export function LinkButton({ id, to, variant, isDark, size, icon, isIconOnRight, children, className, ...restProps }) {
	//if it is a normal URL, use 'a' anchor tag, else use gatsby 'Link'
	if (to.startsWith('http')) {
		return (
			<OutboundLink
				id={id}
				tabIndex="0"
				className={clsx(
					styles.btn, //'button',
					variant == 'secondary'
						? styles.secondary //'button--secondary'
						: variant == 'tertiary'
						? styles.tertiary //'button--tertiary'
						: styles.primary, //'button--primary'
					isDark && styles.dark,
					size == 'small' && styles.small,
					size == 'smaller' && styles.smaller,
					size == 'big' && styles.big,
					size == 'bigger' && styles.bigger,
					icon && icon !== '' && styles.ico,
					icon && icon !== '' && isIconOnRight && styles.icoRight,
					className && typeof className === 'string' && className.trim(),
				)}
				href={to}
				target="_blank"
				rel="noreferrer"
				{...restProps}
			>
				<>
					{icon && icon !== '' && <Icon icon={icon} />}
					{children && <span>{children}</span>}
				</>
			</OutboundLink>
		);
	} else {
		return (
			<Link
				tabIndex="0"
				id={id}
				className={clsx(
					styles.btn, //'button',
					variant == 'secondary'
						? styles.secondary //'button--secondary'
						: variant == 'tertiary'
						? styles.tertiary //'button--tertiary'
						: styles.primary, //'button--primary'
					isDark && styles.dark,
					size == 'small' && styles.small,
					size == 'smaller' && styles.smaller,
					size == 'big' && styles.big,
					size == 'bigger' && styles.bigger,
					icon && icon !== '' && styles.ico,
					icon && icon !== '' && isIconOnRight && styles.icoRight,
					className && typeof className === 'string' && className.trim(),
					/* 'button',
					variant=='secondary' ? 
						'button--secondary'
						:
						variant=='tertiary' ?
							'button--tertiary'
							:
							'button--primary'
					,
					size=='small' && 'button--sm',
					size=='large' && 'button--lg' */
				)}
				to={to}
				{...restProps}
			>
				<>
					{icon && icon !== '' && <Icon icon={icon} />}
					{children && <span>{children}</span>}
				</>
				{/* {children || 'Button'} */}
			</Link>
		);
	}
}

export function ButtonListContainer({ children, className, ...restProps }) {
	return (
		<div className={clsx(styles.btnCntnr, className && className)} {...restProps}>
			{children}
		</div>
	);
}
