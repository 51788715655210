import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda';

// import { ToastNotifierContext } from '@components/ToastNotifier';
import { ToastNotificationContext } from '@providers/ToastNotification';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
/**
 * Short-lived Temporary Credentials issued by AWS STS service
 * @typedef {import('@providers/aws/AwsCredentials').Creds} Creds
 */

/*==================+
|					|
|		CONTEXT		|
|					|
+==================*/
/**
 * React context for AWS Lambda
 */
export const LambdaContext = createContext(
	/**
	 * Invokes a lambda function
	 * @async
	 * @function
	 * @param {!string} fnName - Name of the function to invoke
	 * @param {object|string|undefined} [payload=undefined] - Data to send to lambda function
	 * @returns {Promise<object>|Promise<null>} An object containing the response from lambda fn
	 */
	async (fnName, payload) => {}, // eslint-disable-line no-unused-vars
);
// export const LambdaContext = createContext({
// 	/**
// 	 * Invoke a lambda function
// 	 * @async
// 	 * @function invoke
// 	 * @param {!string} fnName - Function name
// 	 * @param {!object} payload - Payload object
// 	 * @param {!object} creds - AWS Creds
// 	 * @returns {Promise<object|null>} An object containing the response from lambda fn
// 	 */
// 	invoke: async (fnName, payload, creds) => {}, // eslint-disable-line no-unused-vars
// 	/**
// 	 * Destroys any Lambda clients cached before (usefull after signing out a user)
// 	 * @function destroy
// 	 */
// 	destroy: () => {},
// });

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for AWS Lambda.
 * This component is imported in 'src/containers/Root'
 */
export default function Lambda_ContextProvider({ children }) {
	let lambdaClientRef = useRef(null);
	let lastUsedCredsStamp = useRef(null); //we store a signature (ie' any prop of the creds obj that we can compare) of creds to check if the creds have changed

	const { notify } = useContext(ToastNotificationContext);
	const isOnline = useContext(NetworkCheckContext);
	const { getCreds } = useContext(AwsCredentialsContext);

	const configData = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					awsRegion
				}
			}
		}
	`);
	const AWS_REGION = configData.site.siteMetadata.awsRegion;

	/**
	 * Closes any open Lambda client. Also clears out any saved static variables
	 * @function _close
	 */
	const _close = () => {
		if (lambdaClientRef.current) lambdaClientRef.current = null;
		if (lastUsedCredsStamp.current) lastUsedCredsStamp.current = null;
	};

	// const _initIfNotAlready = async (creds) => {
	/**
	 * Gets handle to Lambda client.
	 * If not already initialized, this initializes the Lambda client and also saves it as static for reuse later.
	 * @async
	 * @function _getLambdaClient
	 * @param {!Creds} creds - Credentials object issued by Amazon STS
	 * @returns {Promise<object>|Promise<null>} Lambda document client handle or null.
	 */
	const _getLambdaClient = async (creds) => {
		if (!creds) return null;
		if (typeof creds !== 'object') return null;
		try {
			//check if creds have changed. NOTE: SInce we are doing a string comparison of two objects, we do not take into account the sort order of the object keys
			if (
				JSON.stringify(creds) === lastUsedCredsStamp?.current && //here we check if creds have changed
				lambdaClientRef.current && // here we check if we have the LambdaClient stored in cache which we can reuse
				lambdaClientRef.current.constructor.name === 'LambdaClient'
			) {
				return lambdaClientRef.current; //return the already created client stored for reuse
			} else {
				_close(); //cleanup any last-used clients
				// initialize new lambda client
				lambdaClientRef.current = new LambdaClient({
					region: AWS_REGION,
					credentials: creds, //AwsCogIdCreds.creds
				});
				lastUsedCredsStamp.current = JSON.stringify(creds);
				// console.log('lambdaClient:',lambdaClientRef.current);
				return lambdaClientRef.current;
			}
		} catch (err) {
			console.log('_getLambdaClient():', err.message || err.code || err.name || 'Error initiating Lambda client');
			return null;
		}
	};

	/**
	 * Invokes a lambda function
	 * @async
	 * @function invoke
	 * @param {!string} fnName - Name of the function to invoke
	 * @param {object|string|undefined} [payload=undefined] - Data to send to lambda function
	 * @returns {Promise<object>|Promise<null>} An object containing the response from lambda fn.
	 */
	const invoke = async (fnName, payload) => {
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!fnName || fnName.trim() === '') throw new Error('Function name not specified');
			// if (!payload) throw new Error('Payload not specified');
			// if (typeof payload !== 'object') throw new Error('Payload specified in improper format');
			const creds = await getCreds();
			if (!creds) throw new Error('Error getting credentials');
			if (typeof creds !== 'object') throw new Error('No proper creds specified');

			const lambdaClient = await _getLambdaClient(creds); //_initIfNotAlready(creds);
			//note that this sets up 'lambdaClientRef.current' var so we may actually use 'lambdaClientRef.current' instead of 'lambdaClient' further

			if (!lambdaClient) throw new Error('Error getting Lambda handle');

			let invokeCmdOptions = {
				FunctionName: fnName, //kaagzi-lambda-cashFreePg-createOrder
				InvocationType: 'RequestResponse', // Use 'Event' for asynchronous independent invocation of fn when not having to wait for response
				Payload: JSON.stringify(payload),
			};
			if (payload && (typeof payload === 'object' || typeof payload === 'string')) invokeCmdOptions.Payload = JSON.stringify(payload);
			const resp = await lambdaClient.send(new InvokeCommand(invokeCmdOptions));
			// console.log('Lambda_ContextProvider.invoke() resp:', resp);
			// return resp;

			//since resp.Payload would uint8array, we shall need to parse it further
			//https://stackoverflow.com/questions/67077173/aws-sdk-client-lambda-invoke-lambda-payload-response-in-unit8array-conve

			// console.log('Lambda_ContextProvider.invoke() resp.Payload constructor:', resp.Payload.constructor);
			const asciiDecoder = new TextDecoder('ascii');
			const respDecoded = asciiDecoder.decode(resp.Payload); //.toString();
			// console.log('Lambda_ContextProvider.invoke() respDecoded.type:', typeof respDecoded);
			// const jsonObj = JSON.parse(JSON.parse(respDecoded)); //https://stackoverflow.com/questions/30194562/json-parse-not-working#answer-49460716
			const jsonObj = JSON.parse(respDecoded); //if we are returning a stringified JSON from lambda fn, we shall need to reparse JSON like above line
			// console.log('resp:', jsonObj);
			// console.log('propval:', jsonObj.status);
			return jsonObj;
		} catch (err) {
			console.log('invoke():', err.message || err.code || err.name || 'Error invoking lambda function');
			return null;
		}
	};
	useEffect(() => {
		//cleanup
		return () => {
			_close();
		};
	}, []);
	return <LambdaContext.Provider value={invoke}>{children}</LambdaContext.Provider>;
}
