//https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/How_to/Trigger_install_prompt
import React, { useEffect, useRef, useState } from 'react';

import Button from '@components/ui/Button';

// import * as styles from './pwaInstallPrompt.module.css';
import { pwaInstallPromptC } from './pwaInstallPrompt.module.css';

export default function PWA_installPrompt() {
	const [showUi, setShowUi] = useState(false);
	const beforeinstallpromptEventRef = useRef(null);

	// const doOnInstallClick = async () => {
	// 	if (!beforeinstallpromptEventRef.current) return;
	// 	const result = await beforeinstallpromptEventRef.current.prompt();
	// 	console.log(`Install prompt was: ${result.outcome}`);
	// 	beforeinstallpromptEventRef.current = null;
	// 	setShowUi(false);
	// };
	useEffect(() => {
		window.addEventListener('beforeinstallprompt', (event) => {
			event.preventDefault();
			beforeinstallpromptEventRef.current = event;
			setShowUi(true);
		});

		//in case app is installed by the user by directly using the in-rowser menu button and not via this UI, we watch for it and hide our UI
		window.addEventListener('appinstalled', () => {
			beforeinstallpromptEventRef.current = null;
			setShowUi(false);
		});

		//cleanup function
		return () => {
			beforeinstallpromptEventRef.current = null;
		};
	}, []);
	if (!showUi) return null;
	return (
		<div id={pwaInstallPromptC}>
			<p>Do you wish to install Kaagzi app?</p>
			<div>
				<Button
					variant="secondary"
					isDark={true}
					size="big"
					onClick={() => {
						beforeinstallpromptEventRef.current = null;
						setShowUi(false);
					}}
				>
					Dismiss
				</Button>
				<Button
					variant="primary"
					isDark={true}
					size="big"
					onClick={async () => {
						if (!beforeinstallpromptEventRef.current) return;
						const result = await beforeinstallpromptEventRef.current.prompt();
						// console.log(`Install prompt was: ${result.outcome}`);
						beforeinstallpromptEventRef.current = null;
						setShowUi(false);
					}}
				>
					Install
				</Button>
			</div>
		</div>
	);
}
