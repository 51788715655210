// extracted by mini-css-extract-plugin
export var big = "index-module--big--1916e";
export var bigger = "index-module--bigger--2cb59";
export var btn = "index-module--btn--1052e";
export var btnCntnr = "index-module--btnCntnr--7b62c";
export var dark = "index-module--dark--f23b5";
export var ico = "index-module--ico--9ee12";
export var icoRight = "index-module--icoRight--0e5b4";
export var primary = "index-module--primary--9650e";
export var primaryBuy = "index-module--primaryBuy--85534";
export var primarySell = "index-module--primarySell--ac3b1";
export var secondary = "index-module--secondary--aab7f";
export var small = "index-module--small--09fd8";
export var smaller = "index-module--smaller--2cab7";
export var spin = "index-module--spin--79652";
export var tertiary = "index-module--tertiary--707af";
export var wip = "index-module--wip--bece6";