import React from 'react';

import Icon from '@components/ui/Icon';

import * as styles from './planSelectionForm.module.css';

export default function PlanSelectionForm({ goToForm }) {
	return (
		<ul className={styles.planSelForm}>
			<li>
				<button
					className={styles.planSelBtn}
					onClick={() => {
						goToForm('1monthPlanBuyConfirmation');
					}}
				>
					<span>
						<h3>1 Month Plan</h3>
					</span>
					<span className={styles.cp}>75</span>
					<span>
						<Icon icon="chevron-right" />
					</span>
				</button>
			</li>
			<li>
				<button
					className={styles.planSelBtn}
					onClick={() => {
						goToForm('6monthsPlanBuyConfirmation');
					}}
				>
					<span>
						<h3>6 Months Plan</h3>
						<p>1 month Free included</p>
					</span>
					<span className={styles.cp}>375</span>
					<span>
						<Icon icon="chevron-right" />
					</span>
				</button>
			</li>
			<li>
				<button
					className={styles.planSelBtn}
					onClick={() => {
						goToForm('12monthsPlanBuyConfirmation');
					}}
				>
					<span>
						<h3>12 Months Plan</h3>
						<p>3 months Free included</p>
					</span>
					<span className={styles.cp}>675</span>
					<span>
						<Icon icon="chevron-right" />
					</span>
				</button>
			</li>
			<li className={styles.gfxC}>
				<div>
					<span>At approx &#8377; 2 / Day,</span>
					<p>
						Kaagzi costs less than your everyday <b>Chai</b>
					</p>
				</div>
				<div>
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
						<use
							href="#svgFeatureBlob"
							x="48"
							y="56"
							opacity=".25"
							style={{ transformOrigin: '240px 240px', transform: 'scaleX(1.15) rotate(60deg)' }}
						/>
						<use href="#svgFeatureBlob" x="48" y="56" opacity=".5" style={{ transformOrigin: '240px 240px', transform: 'rotate(15deg)' }} />
						<use href="#svgTea" x="136" y="168" />
					</svg>
				</div>
			</li>
		</ul>
	);
}
