import React from 'react';

import * as styles from './loading.module.css';

/**
 * Displays a loading overlay
 * @param {Object} props - Props passed to this component
 * @param {?String} [props.note='Loading'] - Note to display
 * @param {?Boolean} [props.isDark=false] - Whether to show dark overlay
 * @param {?Boolean} [props.coverFullPage=false] - Whether to cover full page (fixed pos)
 */
export default function Loading({ note, isDark, coverFullPage }) {
	// const [note, setNote] = React.useState(props.note || '');
	// const [note] = useState(props.note || '');
	return typeof note == 'string' && note.trim() != '' ? (
		<div className={`${styles.wip}${isDark ? ' ' + styles.dark : ''}${coverFullPage ? ' ' + styles.fullPage : ''}`} data-note={note} />
	) : (
		<div className={`${styles.wip}${isDark ? ' ' + styles.dark : ''}${coverFullPage ? ' ' + styles.fullPage : ''}`} />
	);
}
