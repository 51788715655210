/**
 * Compares two objects for equality.
 * This fn loops over each key and compares its value with the corresponding key from the object we are comparing with.
 * Note the values are stringified before comparing with the value from similar key from the other object. This is done to account for if the values itself are of 'object' type for eg' 'Date' object
 * @param {!object} obj1 - Object with parameters
 * @param {!object} obj2 - Object with parameters
 * @returns {boolean} True if objects are equal, false otherwise
 */
export const areObjectsEqual = (obj1, obj2) => {
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;
	var entries1 = Object.entries(obj1);
	var entries2 = Object.entries(obj2);
	return (
		entries1.length === entries2.length &&
		entries1.every(([key, value], i) => key === entries2[i][0] && JSON.stringify(value) === JSON.stringify(entries2[i][1]))
	);
};

/**
 * Decodes a JWT token and returns claims as an object.
 * @param {!object} token - The JWT token to be decoded
 * @returns {?object} JWT claims object or null in case of failure
 * @see {@link https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library}
 */
export const decodeJWT = (token) => {
	// export function decodeJWT(token) {
	try {
		var partsAry = token.split('.');
		if (partsAry.length !== 3) throw new Error();
		var base64Url = partsAry[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(''),
		);
		if (!jsonPayload) throw new Error();
		return JSON.parse(jsonPayload);
	} catch (err) {
		return null;
	}
};

//==================+
//					|
//		DATETIME	|
//					|
//==================+

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
/* const monthsAbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getLetterFormatedDate = (epoch, whetherMonthsAbrevated) => {
	if (!epoch) return '';
	if (!Number.isInteger(epoch)) return '';
	if (typeof whetherMonthsAbrevated != 'boolean') whetherMonthsAbrevated = false;

	const t = new Date(epoch * 1000); //*1000 is to convert epoch seconds to miliseconds for us in JS
	return whetherMonthsAbrevated
		? t.getDate() + ' ' + monthsAbr[t.getMonth()] + ' ' + t.getFullYear()
		: t.getDate() + ' ' + months[t.getMonth()] + ' ' + t.getFullYear();
}; */

/**
 * A function that takes either a Date object or a Number (Unix epoch) as input
 * and returns a formatted string of the date
 * @param {(Date|number)} input - The date to be formatted or UNIX epoch
 * @param {boolean} [addDayOrdinalSuffix=false] - Default FALSE. Whether to add ordinal suffix to date. For eg' 1st, 2nd, 3rd, 4th etc
 * @param {boolean} [abbreviateMonth=false] - Default FALSE. Whether to abbreviate the month or not. For eg' Jan, Feb, Mar
 * @param {boolean} [outputTime=false] - Default FALSE. Whether to output time along with date. For eg '15 August 2023, 6:30 AM'
 * @returns {'15 Aug 2023'|'15 August 2023'|'15 Aug 2023, 6:30 AM'} The formatted date string or ''
 */
export function getLetterFormatedDate(input, addDayOrdinalSuffix, abbreviateMonth, outputTime) {
	try {
		//defaults
		if (typeof addDayOrdinalSuffix !== 'boolean') addDayOrdinalSuffix = false;
		if (typeof abbreviateMonth !== 'boolean') abbreviateMonth = false;
		if (typeof outputTime !== 'boolean') outputTime = false;

		// const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		var date;
		// Check the type of the input
		if (typeof input === 'number' && Number.isInteger(input)) {
			date = new Date(input * 1000); // If the input is Unix epoch number, create a new date object from it
		} else if (input instanceof Date) {
			date = input; // If the input is already a date object, use it as it is
		} else {
			return ''; // If the input is neither a number nor a date object
		}
		if (date instanceof Date && isNaN(date.getTime())) return ''; //check if 'date' is actually a valid date	//since 'Invalid Date' would return 'NaN'

		const day = date.getDate();

		// Add the ordinal suffix to the day
		var suffix = '';
		if (addDayOrdinalSuffix) {
			if (day % 10 === 1 && day !== 11) {
				suffix = 'st';
			} else if (day % 10 === 2 && day !== 12) {
				suffix = 'nd';
			} else if (day % 10 === 3 && day !== 13) {
				suffix = 'rd';
			} else {
				suffix = 'th';
			}
		}

		//format hh:mm time string
		var timeStr = '';
		if (outputTime) {
			var hours = date.getHours();
			var minutes = date.getMinutes();
			var ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			timeStr = ', ' + hours + ':' + minutes + ' ' + ampm;
		}
		if (abbreviateMonth) {
			return day + suffix + ' ' + months[date.getMonth()].slice(0, 3) + ' ' + date.getFullYear() + timeStr;
		} else {
			return day + suffix + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + timeStr;
		}
	} catch (err) {
		return '';
	}
}

/**
 * A function that takes either a Date object or a Number (Unix epoch) as input
 * and returns a number formatted string of the date
 * @param {(Date|number)} input - The date to be formatted
 * @param {boolean} [seperator='-'] - Default '-'. Whether to add ordinal suffix to date. For eg' 1st, 2nd, 3rd, 4th etc
 * @param {boolean} [prefixSingleDigitsWith_0=false] - Default FALSE. Whether to prefix single digits with 0.. For eg' 01, 02, 03 etc
 * @returns {string} The formatted date string or ''
 */
export function getNumberFormatedDate(input, seperator, prefixSingleDigitsWith_0) {
	try {
		//defaults
		if (!seperator || typeof seperator !== 'string') seperator = '-';
		if (typeof prefixSingleDigitsWith_0 !== 'boolean') prefixSingleDigitsWith_0 = false;

		var date;
		// Check the type of the input
		if (typeof input === 'number' && Number.isInteger(input)) {
			date = new Date(input * 1000); // If the input is Unix epoch number, create a new date object from it
			//*1000 is to convert epoch seconds to miliseconds for us in JS
		} else if (input instanceof Date) {
			date = input; // If the input is already a date object, use it as it is
		} else {
			return ''; // If the input is neither a number nor a date object
		}
		if (date instanceof Date && isNaN(date.getTime())) return ''; //check if 'date' is actually a valid date	//since 'Invalid Date' would return 'NaN'

		let d = date.getDate();
		let m = date.getMonth() + 1; //since getMonth() returns index from 0
		if (prefixSingleDigitsWith_0) {
			d = d < 10 ? '0' + d : d;
			m = m < 10 ? '0' + m : m;
		}

		return d + seperator + m + seperator + date.getFullYear();
	} catch (err) {
		return '';
	}
}

/**
 * - Given a number 134567235.426 this fn outputs '13,45,67,235.43'
 * - Given a number 134567235 this fn outputs '13,45,67,235.00'
 * @param {134567235.426} number - Number to be formatted
 * @returns {?'13,45,67,235.43} Formatted string
 */
export function formatPriceInIndianStyle(number) {
	if (number == null || number == undefined || isNaN(Number(number))) return null; //'-.--';
	if (Number(number) === 0) return '0.00';
	return new Intl.NumberFormat('en-IN', {
		// maximumSignificantDigits: 3,
		// style: 'decimal',	//'currency'	//use currency to output the rupee symbol too
		// currency: 'INR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);
}

/**
 * Converts all uppercase name to having only the initials in uppercase.
 * For eg formats 'NIMISH SUDHIR RAJWADE' to 'Nimish Sudhir Rajwade'
 * @param {string} nameString For eg, 'NIMISH SUDHIR RAJWADE'
 * @returns {string} Ourputs 'Nimish Sudhir Rajwade'
 */
export function formatName(nameString) {
	return nameString
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
}

/**
 * Calculates the GST @ 18% and payment-gateway fees of 2% on final total
 * @function calcTaxAndFees
 * @param {Number} basePrice Base price
 * @param {Boolean} [levyTax=false] Whether to levy 18% GST
 * @returns {{gst:0,paymentGatewayFee:0,total:0}} GST, payment-gateway fee & total rounded off using Math ceil
 */
export function calcTaxAndFees(basePrice, levyTax) {
	try {
		if (!basePrice) throw new Error();
		if (isNaN(basePrice)) throw new Error();
		if (basePrice === 0) throw new Error();

		//calc GST @ 18%
		const gst = levyTax ? Math.ceil(basePrice * 0.18) : 0;

		//calc payment gateway convenience-fee @ 2%

		// const cnvf = basePrice * 0.02;	 //based off basePrice excluding gst

		//Since of the total customer payment, basePrice + gst (what we should get after PG deducts 2%) is 98% and PG convenience fee is rest 2%
		//If (basePrice + GST) is 98%, we calculate how much X would be for 100%
		// 	  X			basePrice + GST
		//	-----	x	---------------
		//	 100			  98
		// const cnvf = Number(((((basePrice + gst) * 100) / 98) * 0.02).toFixed(2)); //we get 2% out of that total and then round off to 2 decimals
		const cnvf = Math.ceil(Number((((basePrice + gst) * 100) / 98) * 0.02));
		return { gst, paymentGatewayFee: cnvf, total: Math.ceil(basePrice + gst + cnvf) };
	} catch (err) {
		return { gst: 0, paymentGatewayFee: 0, total: 0 };
	}
}

/**
 * Calculates cost components from the final total. This function is the opp of {@link calcTaxAndFees} function
 * @param {Number} totalCost Final total payable price
 * @param {Boolean} [isTaxIncluded=false] Whether to consider tax in the totalCost
 * @returns {{basePrice:Number,gst:Number,paymentGatewayFee:Number}} Base price, GST & payment-gateway fee
 */
export function calcCostSplitComponents(totalCost, isTaxIncluded) {
	try {
		if (!totalCost) throw new Error();
		if (isNaN(totalCost)) throw new Error();
		if (totalCost === 0) throw new Error();

		const cnvf = Math.ceil(totalCost * 0.02); //since it is 2% of the total cost
		if (isTaxIncluded) {
			const taxedAmt = totalCost - cnvf;
			const basePrice = Math.floor((taxedAmt * 100) / 118);
			return { basePrice, gst: taxedAmt - basePrice, paymentGatewayFee: cnvf };
		}
		//else return without considering tax
		const basePrice = totalCost - cnvf;
		return { basePrice, gst: 0, paymentGatewayFee: cnvf };
	} catch (err) {
		return { basePrice: 0, gst: 0, paymentGatewayFee: 0 };
	}
}

/** Copies value to clipboard
 * @param {!string} value Value to copy
 * @returns {Boolean} TRUE if value copied to clipboard successfully or FALSE otherwise
 */
export function copyToClipboard(value) {
	if (value == null || value == undefined || typeof value !== 'string' || value?.trim() === '') return false;
	if (!navigator) return false;
	navigator?.clipboard?.writeText(value);
	return true;
}
