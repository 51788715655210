import React from 'react';

//This SVG component will be imported in the root and wud mostly / preferably render right after opening body tag
//other SVGs down the line can reference the reusable symbols and defs from this svg

export default function SvgReusableElements(props) {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 0 0"
			width="0"
			height="0"
			style={{ visibility: 'hidden', position: 'absolute', pointerEvents: 'none' }}
			{...props}
		>
			<defs>
				<path
					id="svgHeroMainBlobPath"
					d="M672,38c-185,61-490-141-642,48c-104,130,95,184,116,291c28,141-132,76-100,229s607,265,833,137C1144,594,1324-179,672,38z"
				/>
				<path id="svgLeafLhPath" d="M80,0l4,400C191,331,177,183,80,0z" />
				<path id="svgLeafRhPath" d="M80,0l-4,400C-31,331-17,183,80,0z" />
				<path id="svgFeatureBlobPath" d="M169,9c86,47,193-2,212,109c14,82-57,214-187,244c-80,18-166-12-187-92C-26,143,72-44,169,9z" />
			</defs>
			<symbol id="svgHeroMainBlob" width="1120" height="800" viewBox="0 0 1120 800">
				<linearGradient id="svgHeroMainBlobG" gradientUnits="userSpaceOnUse" x1="151" y1="-53" x2="947" y2="743">
					<stop offset="0" stopColor="#FAC833" />
					<stop offset="1" stopColor="#F4771A" />
				</linearGradient>
				<use href="#svgHeroMainBlobPath" fill="url(#svgHeroMainBlobG)" />
			</symbol>
			<symbol id="svgFeatureBlob" width="384" height="368" viewBox="0 0 384 368">
				<linearGradient id="svgFeatureBlobG" gradientUnits="userSpaceOnUse" x1="5" y1="89" x2="287" y2="247">
					<stop offset="0" stopColor="#A7A1C5" />
					<stop offset="1" stopColor="#D3D0E1" />
				</linearGradient>
				<use href="#svgFeatureBlobPath" fill="url(#svgFeatureBlobG)" />
			</symbol>
			<symbol id="svgLeaf" width="160" height="400" viewBox="0 0 160 400">
				<linearGradient id="svgLeafG1" gradientUnits="userSpaceOnUse" x1="120" y1="400" x2="120" y2="0">
					<stop offset="0" stopColor="rgb(248, 173, 43)" />
					<stop offset="1" stopColor="rgb(252, 227, 59)" />
				</linearGradient>
				<use href="#svgLeafLhPath" fill="url(#svgLeafG1)" />
				<linearGradient id="svgLeafG2" gradientUnits="userSpaceOnUse" x1="40" y1="400" x2="40" y2="0">
					<stop offset="0" stopColor="rgb(246, 146, 35)" />
					<stop offset="1" stopColor="rgb(250, 200, 51)" />
				</linearGradient>
				<use href="#svgLeafRhPath" fill="url(#svgLeafG2)" />
			</symbol>
			<symbol id="svgTarget" width="320" height="400" viewBox="0 0 320 400">
				<linearGradient id="svgTargetG" gradientUnits="userSpaceOnUse" x1="64" y1="336" x2="256" y2="144">
					<stop offset="0" stopColor="#f4771a" />
					<stop offset="1" stopColor="#F8AD2B" />
				</linearGradient>
				<line fill="none" stroke="#FFFFFF" strokeWidth="3" strokeMiterlimit="10" x1="160" y1="110" x2="160" y2="0" />
				<circle fill="#FFFFFF" cx="160" cy="240" r="160" />
				<circle fill="none" stroke="url(#svgTargetG)" strokeWidth="36" strokeMiterlimit="10" cx="160" cy="240" r="112" />
				<circle fill="none" stroke="url(#svgTargetG)" strokeWidth="36" strokeMiterlimit="10" cx="160" cy="240" r="40" />
			</symbol>
			<symbol id="svgPaperUnrolled" width="216" height="248" viewBox="0 0 216 248">
				{/* <!--bottomRollBackSide--> */}
				<g>
					<linearGradient id="svgBottomRollBackSideG" gradientUnits="userSpaceOnUse" x1="116" y1="248" x2="116" y2="216">
						<stop offset="0" stopColor="#A7A1C5" />
						<stop offset="0.5" stopColor="#D3D0E1" />
					</linearGradient>
					<rect x="28" y="216" fill="url(#svgBottomRollBackSideG)" width="176" height="32" />
					<ellipse fill="url(#svgBottomRollBackSideG)" cx="204" cy="232" rx="12" ry="16" />
				</g>
				{/* <!--frontSide--> */}
				<g>
					<rect x="20" y="24" fill="#FFFFFF" width="184" height="192" />
					<ellipse fill="#FFFFFF" cx="190" cy="24" rx="14" ry="24" />
					<rect x="10" fill="#FFFFFF" width="180" height="32" />
				</g>
				{/* <!--bottomRollFrontSide--> */}
				<g>
					<linearGradient id="svgBottomRollFrontSideG" gradientUnits="userSpaceOnUse" x1="28" y1="248" x2="28" y2="216">
						<stop offset="0" stopColor="#D3D0E1" />
						<stop offset="1" stopColor="#fff" />
					</linearGradient>
					<ellipse fill="url(#svgBottomRollFrontSideG)" cx="28" cy="232" rx="8" ry="16" />
					<rect x="20" y="216" fill="url(#svgBottomRollFrontSideG)" width="8" height="16" />
				</g>
				{/* <!--topRollFrontSide--> */}
				<g>
					<linearGradient id="svgTopRollFrontSideG" gradientUnits="userSpaceOnUse" x1="15" y1="48" x2="15" y2="40">
						<stop offset="0" stopColor="#A7A1C5" />
						<stop offset="1" stopColor="#52458B" />
					</linearGradient>
					<rect x="10" y="40" fill="url(#svgTopRollFrontSideG)" width="10" height="8" />
				</g>
				{/* <!--topRollBackSide--> */}
				<g>
					<linearGradient id="svgTopRollBackSideG" gradientUnits="userSpaceOnUse" x1="10" y1="48" x2="10" y2="0">
						<stop offset="0.25" stopColor="#D3D0E1" />
						<stop offset="1" stopColor="#7D74A8" />
					</linearGradient>
					<ellipse fill="url(#svgTopRollBackSideG)" cx="10" cy="24" rx="10" ry="24" />
					<rect x="12" y="24" fill="url(#svgTopRollBackSideG)" width="8" height="16" />
				</g>
			</symbol>
			<symbol id="svgCoin" width="200" height="200" viewBox="0 0 200 200">
				<linearGradient id="svgCoinG1" gradientUnits="userSpaceOnUse" x1="36" y1="164" x2="172" y2="28">
					<stop offset="0" stopColor="#F0410B" />
					<stop offset="1" stopColor="#F8AD2B" />
				</linearGradient>
				<circle fill="url(#svgCoinG1)" cx="104" cy="96" r="96" />
				<linearGradient id="svgCoinG2" gradientUnits="userSpaceOnUse" x1="28" y1="172" x2="164" y2="36">
					<stop offset="0" stopColor="#F4771A" />
					<stop offset="1" stopColor="#FAC833" />
				</linearGradient>
				<circle fill="url(#svgCoinG2)" cx="96" cy="104" r="96" />
				<g>
					<circle fill="none" stroke="#F4771A" strokeWidth="12" strokeMiterlimit="10" strokeDasharray="7.8992,7.8992" cx="96" cy="104" r="88" />
				</g>
				<linearGradient id="svgCoinG3" gradientUnits="userSpaceOnUse" x1="44" y1="156" x2="148" y2="52">
					<stop offset="0" stopColor="#D3D0E1" />
					<stop offset="1" stopColor="#fff" />
				</linearGradient>
				<circle fill="url(#svgCoinG3)" cx="96" cy="104" r="74" />
				<linearGradient id="svgCoinRupeeG" gradientUnits="userSpaceOnUse" x1="80" y1="123" x2="126" y2="77">
					<stop offset="0" stopColor="#FF6700" />
					<stop offset="1" stopColor="#F4AD21" />
				</linearGradient>
				<path
					fill="url(#svgCoinRupeeG)"
					d="M70,72h51v9h-15c1,1,2,4,3,6h12v9h-12c-1,7-8,13-15,15c8,4,14,17,24,33h-18c-6-12-13-30-24-30c-1,0-6,0-6,0v-9c0,0,6,0,9,0c7,0,13-4,15-9h-24v-9h24c-0-2-4-6-12-6c-3,0-12,0-12,0V72z"
				/>
			</symbol>
			<symbol id="svgTea" width="208" height="288" viewBox="0 0 208 288">
				<polygon opacity="0.625" fill="#fff" points="163,264 45,264 0,24 208,24" />
				<ellipse opacity="0.75" fill="#fff" cx="104" cy="268" rx="58.9" ry="20" />
				<linearGradient id="svgTeaG1" gradientUnits="userSpaceOnUse" x1="24" y1="224" x2="184" y2="64">
					<stop offset="0" stopColor="#FF6700" />
					<stop offset="1" stopColor="#FAC833" />
				</linearGradient>
				<polygon fill="url(#svgTeaG1)" points="160,248 48,248 16,72 192,72" />
				<linearGradient id="svgTeaG2" gradientUnits="userSpaceOnUse" x1="59" y1="117" x2="149" y2="27">
					<stop offset="0" stopColor="#FF6700" />
					<stop offset="1" stopColor="#FAC833" />
				</linearGradient>
				<ellipse fill="url(#svgTeaG2)" cx="104" cy="72" rx="88" ry="16" />
				<linearGradient id="svgTeaG3" gradientUnits="userSpaceOnUse" x1="74" y1="274" x2="134" y2="214">
					<stop offset="0" stopColor="#FF6700" />
					<stop offset="1" stopColor="#FAC833" />
				</linearGradient>
				<ellipse fill="url(#svgTeaG3)" cx="104" cy="244" rx="56" ry="20" />
				<ellipse fill="#fff" cx="104" cy="24" rx="104" ry="24" />
				<ellipse opacity="0.5" fill="#fff" cx="104" cy="212" rx="8" ry="60" />
				<ellipse transform="matrix(-0.1 -1 1 -0.1 -123 274)" opacity="0.5" fill="#fff" cx="60" cy="191" rx="81" ry="8" />
				<ellipse
					transform="matrix(9.757128e-002 -0.9952 0.9952 9.757128e-002 -66.7334 326.4045)"
					opacity="0.3"
					fill="#FFFFFF"
					cx="146.6"
					cy="200"
					rx="72"
					ry="8"
				/>
			</symbol>
		</svg>
	);
}
