import React from 'react';

// import ToastNotifier from '@components/ToastNotifier';
import ToastNotification_ContextProvider from '@providers/ToastNotification';
import NetworkCheck_ContextProvider from '@providers/NetworkAvailabilityCheck';
import PersistentOfflineCache_ContextProvider from '@providers/OfflineCache';
import Storage_ContextProvider from '@providers/Storage';
import AwsCredentials_ContextProvider from '@providers/aws/AwsCredentials';
import DynamoDb_ContextProvider from '@providers/aws/DynamoDB';
import Lambda_ContextProvider from '@providers/aws/Lambda';
// import User from '@components/User';
import User_ContextProvider from '@providers/User';
// import PaymentGateway_ContextProvider from '@providers/PaymentGateway';
import PaymentGateway_payU_ContextProvider from '@providers/PaymentGateway/payu';
import { UserDropDown_ContextProvider } from '@components/userControlPanel/UserDropdown';
import { UserForm_ContextProvider } from '@components/userControlPanel/UserForm';
import { GoogleLogin_ContextProvider } from '@components/federatedLogin/Google';

import SvgReusableElements from '@components/svgJsx/CommonElements.js'; //imported in 'gatsby-browser.js'
import ToastNotifier from '@components/ToastNotifier';
import InterimLoginModalForm from '@components/userControlPanel/InterimLoginModalForm';
import PWA_installPrompt from '@components/PWA_installPrompt';

/**
 * This component is imported in 'gatsby-browser.js' & 'gatsby-ssr.js'.
 * It is used as a wrapper element using the 'wrapRootElement' gatsby API.
 * In this we can provide all Context Providers (Non UI components)
 */
export default function AppRoot({ children }) {
	return (
		<ToastNotification_ContextProvider>
			<NetworkCheck_ContextProvider>
				<PersistentOfflineCache_ContextProvider>
					<Storage_ContextProvider>
						<AwsCredentials_ContextProvider>
							<DynamoDb_ContextProvider>
								<Lambda_ContextProvider>
									<User_ContextProvider>
										<UserDropDown_ContextProvider>
											<UserForm_ContextProvider>
												<PaymentGateway_payU_ContextProvider>
													{/* <GoogleLogin_ContextProvider> */}
													{children}
													{/* <InterimLoginModalForm /> */}
													{/* </GoogleLogin_ContextProvider> */}
												</PaymentGateway_payU_ContextProvider>
											</UserForm_ContextProvider>
										</UserDropDown_ContextProvider>
									</User_ContextProvider>
								</Lambda_ContextProvider>
							</DynamoDb_ContextProvider>
						</AwsCredentials_ContextProvider>
					</Storage_ContextProvider>
				</PersistentOfflineCache_ContextProvider>
			</NetworkCheck_ContextProvider>
		</ToastNotification_ContextProvider>
	);
}

/**
 * This component is imported in 'gatsby-browser.js' & 'gatsby-ssr.js'.
 * It is used as a wrapper element using the 'wrapPageElement' gatsby API
 * This component thus becomes the child of the 'AppRoot' component
 */
export function PageUiRoot({ children }) {
	return (
		<>
			<SvgReusableElements />
			<GoogleLogin_ContextProvider>
				{children}
				<InterimLoginModalForm />
			</GoogleLogin_ContextProvider>
			<ToastNotifier />
			<PWA_installPrompt />
		</>
	);
}
