import React, { useContext } from 'react';

// import { UserContext } from '@components/User';
import { UserContext } from '@providers/User';

import SubscriptionStatusCard from './SubscriptionStatusCard/index';
import NavListItem from './NavListItem/index';

import { adminNavMenu } from './adminMenu.module.css';

export default function AdminMenu({ goToForm, hideDropDown }) {
	// const { isLoggedIn, name, email, pic, signOut } = useContext(UserContext);
	const { signOut } = useContext(UserContext);

	return (
		<>
			<SubscriptionStatusCard goToForm={goToForm} />
			<ul className={adminNavMenu}>
				<li>
					<NavListItem
						label="My Transactions"
						onClick={() => {
							hideDropDown();
						}}
						icon="credit-card"
						linkTo="/console/payment-transactions"
					/>
				</li>
				<li>
					<NavListItem
						label="My Profile"
						onClick={() => {
							goToForm('myProfile');
						}}
						icon="user"
					/>
				</li>
				{/* <li>
					<NavListItem
						label="Settings"
						onClick={() => {
							goToForm('settings');
						}}
						icon="settings"
					/>
				</li> */}
				<li>
					<NavListItem
						label="Sign Out"
						onClick={() => {
							signOut();
							hideDropDown();
						}}
						icon="log-out"
					/>
				</li>
			</ul>
		</>
	);
}
