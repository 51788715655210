/*global process*/

//LINK TO RAISE A TICKET WITH PAYU -- https://help.payu.in/raise-ticket

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import Loading from '@components/ui/Loading';

import { ToastNotificationContext } from '@providers/ToastNotification';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { StorageContext } from '@providers/Storage';
// import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';
import { DynamoDbContext } from '@providers/aws/DynamoDB';
import { LambdaContext } from '@providers/aws/Lambda';
import { UserContext } from '@providers/User';
import { UserFormContext } from '@components/userControlPanel/UserForm';

/** React context for PayU Payment Gateway */
export const PaymentGateway_payU_Context = createContext({
	/** Creates a new payment order in our backend. We also generate the hash using sensitive salt in our backend
	 * @param {!1|2|3} plan Payment Plan
	 * @param {?Number} [interimPhone] Phone number (If phone from user profile data saved in backend is not available)
	 * @returns {Promise<?CreateOrderSuccessResponse>} Object containing the Transaction ID and hash
	 * @see {@link CreateOrderSuccessResponse}
	 */
	initPayment: async (plan, interimPhone) => {}, //eslint-disable-line
});

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
/**
 * User object constructed after reading data from backend DB
 * @typedef {import('@providers/User').ProfileData} ProfileData
 */

/**
 * Success response object received from 'kaagzi-lambda-payuPg-createOrder' lambda function
 * @typedef {Object} CreateOrderSuccessResponse
 * @property {!1} s - Response status
 * @property {!string} txId - Transaction ID
 * @property {!string} h - Hash
 * @property {!string} t - Order creation timestamp
 */

/**
 * Error response object received from 'kaagzi-lambda-payuPg-createOrder' lambda function
 * @typedef {Object} CreateOrderErrorResponse
 * @property {!0} s - Response status
 * @property {!string} m - Error Message. Present in case above property s === 0
 */

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for PayU Payment Gateway.
 * This component is imported in 'src/containers/Root'
 */
export default function PaymentGateway_payU_ContextProvider({ children }) {
	// const [scriptLoaded, setScriptLoaded] = useState(false); //since we only use this to know if script has loaded or not, and not for any UI logic, we can use it as a ref
	const scriptLoaded = useRef(false);
	const [isWip, setIsWip] = useState(false); //this shall be utilized to show a loading / processing curtain over the page
	const [isPaymentPopupOpened, setIsPaymentPopupOpened] = useState(false);

	const { notify } = useContext(ToastNotificationContext);
	const isOnline = useContext(NetworkCheckContext);
	const { storageSet, storageGet, storageClear } = useContext(StorageContext);
	const { dbGetItemData } = useContext(DynamoDbContext);
	const invokeFn = useContext(LambdaContext);
	const { id, isLoggedIn, name, email, phone, readUserProfileFromDB } = useContext(UserContext);
	const { setActiveForm, setWip: setFormWip } = useContext(UserFormContext);

	/** Loads js script on demand
	 * @returns {Promise<boolean>} TRUE on successfull loading of script, FALSE otherwise
	 */
	const _loadScript = () => {
		return new Promise((resolve, reject) => {
			if (scriptLoaded.current) resolve(true); //if script is already loaded, then no need to re download
			if (!isOnline) reject(false);

			try {
				//load script here
				const scriptTag = document.createElement('script');
				// scriptTag.id = 'googleGsiClientScript';
				scriptTag.src = process.env.GATSBY_PAYU_JS_URL; //'https://jssdk-uat.payu.in/bolt/bolt.min.js'; //replace with production url
				scriptTag.async = true;
				scriptTag.defer = true;

				// scriptTag.nonce = nonce;
				scriptTag.onload = () => {
					scriptLoaded.current = true; //setScriptLoaded(true);
					resolve(true);
				};
				scriptTag.onerror = () => {
					scriptLoaded.current = false; //setScriptLoaded(false);
					reject(false);
				};
				document.body.appendChild(scriptTag);
				// return true;
			} catch (err) {
				reject(false);
			}
		});
	};

	/**
	 * Creates a new payment order in our backend. We also generate the hash using sensitive salt in our backend
	 * @param {!1|2|3} plan - Payment Plan
	 * @param {?Number} [interimPhone] - Phone number (If phone from user profile data saved in backend is not available)
	 * @returns {Promise<?CreateOrderSuccessResponse>} Object containing the Transaction ID and hash
	 * @see {@link CreateOrderSuccessResponse}
	 */
	const _createOrder = async (plan, interimPhone) => {
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!plan) throw new Error('No plan specified');
			if (typeof plan !== 'number' || ![1, 2, 3].includes(plan)) throw new Error('Invalid plan specified');

			//here check if user has provided phone number
			if (!phone && !interimPhone) {
				notify('Kindly provide your phone number');
				//show phone entry form, or we may ask for phone number in the same form where user clicks the proceed to payment button
				return null;
			}

			/**
			 * @type {CreateOrderSuccessResponse|CreateOrderErrorResponse}
			 * @see {@link CreateOrderSuccessResponse} and {@link CreateOrderErrorResponse}
			 */
			const createOrderResp = await invokeFn('kaagzi-lambda-payuPg-createOrder', {
				pl: plan,
				n: name.split(' ')[0],
				e: email,
				//p: phone ? phone.toString() : interimPhone.toString(),
			});
			if (!createOrderResp) throw new Error('Error creating Payment Order');

			if (!Object.prototype.hasOwnProperty.call(createOrderResp, 's')) throw new Error('Error creating Payment Order'); //here check response status
			if (createOrderResp.s === 0) throw new Error(createOrderResp.m || 'Error creating Payment Order'); //Show error returned by backend
			if (createOrderResp.s !== 1) throw new Error('Error creating Payment Order'); //If still the status isn't okay

			if (!Object.prototype.hasOwnProperty.call(createOrderResp, 'txId')) throw new Error('Error reading transaction data');
			if (!Object.prototype.hasOwnProperty.call(createOrderResp, 'h')) throw new Error('Error reading transaction token data');

			// //here store response in localstorage
			// //NOTE that we do not write to state since anyhow we shall be redirected to payment page. We shall load state from storage once we are redirected back after payment
			// //also try and save the 'plan expiry' epoch before the payment is initiated
			// _writeWipOrderDataToStorage_onlyIfDataHasChanged(createOrderResp.oid, createOrderResp.psid, createOrderResp.t, createOrderResp.x);

			// //here we navigate to payment page
			// const cashfree = await cashFreeLoad({
			// 	mode: 'sandbox', //or production
			// });
			// cashfree.checkout({
			// 	paymentSessionId: createOrderResp.psid,
			// 	// redirectTarget: '_self', //optional (_self or _blank)
			// });

			return createOrderResp;
		} catch (err) {
			// console.log('createOrder():', err.message || err.code || err.name || 'Error creating payment order');
			notify(err.message || 'Error creating payment order', 'err');
			return null;
		}
	};

	const _handleResponse = (pgResp) => {
		// console.log('pgResp:', pgResp);
		// pgResp.response : {
		// 	mihpayid: '403993715532046401',
		// 	mode: 'DC',
		// 	status: 'success',
		// 	key: 'rQIxIc',
		// 	txnid: '1722508162_nimish3dgmailcom',
		// 	amount: '91.00',
		// 	addedon: '2024-08-01 15:59:24',
		// 	productinfo: '1 Month Plan',
		// 	firstname: 'Nimish',
		// 	lastname: 'Rajwade',
		// 	address1: '',
		// 	address2: '',
		// 	city: '',
		// 	state: '',
		// 	country: '',
		// 	zipcode: '',
		// 	email: 'nimish3d@gmail.com',
		// 	phone: '1234567890',
		// 	udf1: 'ap-south-1:93cb68f7-3e40-c1af-a667-ffd40aae0fa1',
		// 	udf2: '',
		// 	udf3: '',
		// 	udf4: '',
		// 	udf5: '',
		// 	udf6: '',
		// 	udf7: '',
		// 	udf8: '',
		// 	udf9: '',
		// 	udf10: '',
		// 	card_token: '',
		// 	card_no: 'XXXXXXXXXXXX0003',
		// 	field0: '',
		// 	field1: '188799893530478400',
		// 	field2: '143984',
		// 	field3: '91.00',
		// 	field4: '',
		// 	field5: '00',
		// 	field6: '02',
		// 	field7: 'AUTHPOSITIVE',
		// 	field8: 'AUTHORIZED',
		// 	field9: 'Transaction is Successful',
		// 	payment_source: 'payu',
		// 	PG_TYPE: 'DC-PG',
		// 	error: 'E000',
		// 	error_Message: 'No Error',
		// 	cardToken: null,
		// 	net_amount_debit: 91,
		// 	discount: '0.00',
		// 	offer_key: null,
		// 	offer_availed: null,
		// 	unmappedstatus: 'captured',
		// 	hash: '4e057cbde9276c2e5da3e4b80bdd5b659aa9ca8f5c5e67eb2765de3a30d17ccdb66c86338d16a8c330b69e75c46d2a9e902141b72efe7d9d598ef9d30b6fbcf6',
		// 	bank_ref_no: '188799893530478400',
		// 	bank_ref_num: '188799893530478400',
		// 	bankcode: 'MAST',
		// 	surl: 'http://kaagzi.in',
		// 	curl: 'http://kaagzi.in/payment-transactions',
		// 	furl: 'http://kaagzi.in/payment-transactions',
		// 	meCode: '{"MID":"PAYUPAYMENTCYBS"}',
		// 	card_hash: '25d25456fd0216e927141c1cc5df282d166e1b71e99d8958196654cd7399aaf3',
		// 	txnStatus: 'SUCCESS',
		// 	txnMessage: 'Transaction Successful',
		// };
		if (pgResp.response.txnStatus == 'SUCCESS') {
			// console.log('Your payment has been successful');
			//here verify payment and check if it has been captured in our backend
			(async () => {
				await new Promise((resolve) => setTimeout(resolve, 1000)); //wait for 1 second for pg webhook to update our DB
				const profileRefresh = await readUserProfileFromDB(id, true);
				profileRefresh && console.log('Plan expiry refreshed');
			})();
			notify('Payment successful', 'success');

			// GOOGLE ANALYTICS 'purchase' EVENT
			typeof window !== 'undefined' &&
				window.gtag('event', 'purchase', {
					send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
					currency: 'INR',
					value: Number(pgResp.response.amount),
					transaction_id: pgResp.response.txnid,
					items: [
						{
							item_name: pgResp.response.productinfo,
							price: Number(pgResp.response.amount),
							//quantity: 1,	//this is the default
						},
					],
				});
		}
		if (pgResp.response.txnStatus == 'FAILED') {
			// console.log('Payment failed. Please try again.');
			notify('Payment failed', 'err');
		}
		if (pgResp.response.txnStatus == 'CANCEL') {
			// console.log('Payment failed. Please try again.');
			notify('Payment cancelled', 'err');
		}
		setActiveForm('auto');
		setIsPaymentPopupOpened(false);
		setIsWip(false);
		setFormWip(false);
	};

	/**
	 * Shows the payment modal popup
	 * @param {!1|2|3} plan - Payment Plan
	 * @param {!String|Number} transactionId - Order ID
	 * @param {!String} hash - Hash
	 * @returns {Promise<?boolean>}
	 */
	const _payOrder = async (plan, transactionId, hash) => {
		try {
			setIsWip(true);
			setFormWip(true);
			const isScriptLoaded = await _loadScript();
			if (!isScriptLoaded) throw new Error('Error loading Payment Gateway');
			if (!Object.prototype.hasOwnProperty.call(window, 'bolt')) throw new Error('Error initialising Payment Gateway');

			let priceInInr = 91.0, //default to 75 + 14(18% GST) + 2(2% payment gateway fee)
				productInfo = '1 Month Plan';
			switch (plan) {
				// case 1: //1 month plan
				// 	// priceInInr = 91.0; // 75 + 14(18% GST) + 2(2% payment gateway fee)
				// 	priceInInr = 77.0; //75 + 2(2% payment gateway fee)	//GST EXEMPTED
				// 	break;
				case 2: //6 months plan
					// priceInInr = 453.0; // 375 + 68(18% GST) + 10(2% payment gateway fee)
					priceInInr = 383.0; //375 + 8(2% payment gateway fee)	//GST EXEMPTED
					productInfo = '6 Months Plan';
					break;
				case 3: //12 months plan
					// priceInInr = 814.0; // 675 + 122(18% GST) + 17(2% payment gateway fee)
					priceInInr = 689.0; //675 + 14(2% payment gateway fee)	//GST EXEMPTED
					productInfo = '12 Months Plan';
					break;
				default:
					// priceInInr = 91.0;
					priceInInr = 77.0; //75 + 2(2% payment gateway fee)	//GST EXEMPTED
					productInfo = '1 Month Plan';
			}

			setIsPaymentPopupOpened(true);
			const name_parts = name.split(' ');
			const pgData = {
				key: process.env.GATSBY_PAYU_MERCHANT_KEY,
				hash: hash,
				txnid: transactionId,
				amount: parseInt(priceInInr),
				firstname: name_parts[0], //name.split(' ')[0],
				email: email,
				phone: phone,
				productinfo: productInfo,
				surl: 'http://kaagzi.in',
				furl: 'http://kaagzi.in/payment-transactions',
				// lastname: 'surname',
				udf1: id,
				// enforce_paymethod: 'creditcard|debitcard|HDFB|AXIB',
				// display_lang: 'Hindi',
				// drop_category: 'creditcard|debitcard',
				pg: 'UPI', //default
				// custom_note: 'You will be charged an extra amount of Rs 100 on this transaction',
			};
			if (name_parts.length > 1) pgData.lastname = name_parts[1];

			window?.bolt?.launch(pgData, {
				responseHandler: _handleResponse,
				catchException: () => {
					setActiveForm('auto');
					notify('Payment failed! Kindly retry', 'err');
				},
			});

			// GOOGLE ANALYTICS 'begin_checkout' EVENT
			typeof window !== 'undefined' &&
				window.gtag('event', 'begin_checkout', {
					send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
					currency: 'INR',
					value: priceInInr,
					items: [
						{
							item_name: productInfo,
							price: priceInInr,
							//quantity: 1,	//this is the default
						},
					],
				});
			return true;
		} catch (err) {
			setIsWip(false);
			setFormWip(false);
			return null;
		}
	};

	/**
	 * Creates a new payment order in our backend. We also generate the hash using sensitive salt in our backend
	 * @param {!1|2|3} plan - Payment Plan
	 * @param {?Number} [interimPhone] - Phone number (If phone from user profile data saved in backend is not available)
	 * @returns {Promise<?CreateOrderSuccessResponse>} Object containing the Transaction ID and hash
	 * @see {@link CreateOrderSuccessResponse}
	 */
	const initPayment = async (plan, interimPhone) => {
		try {
			if (!isOnline) {
				notify('Kindly check your network connection!', 'err');
				return null;
			}
			if (isWip) {
				notify('Last payment is still in progress!');
				return null;
			}
			const createOrderResp = await _createOrder(plan, interimPhone);
			if (!createOrderResp) return null;

			// setIsWip(true);

			await _payOrder(plan, createOrderResp.txId, createOrderResp.h).finally(() => {
				setIsWip(false);
				setFormWip(false);
			});
			// setIsWip(false);
			return createOrderResp;
		} catch (err) {
			setIsWip(false);
			setFormWip(false);
			return null;
		}
	};

	// // loads script (if it isn't already loaded) when we are back online
	// useEffect(() => {
	// 	if (scriptLoaded.current) return; //if (scriptLoaded) return; //if script is already loaded, then no need to proceed

	// 	//if we are back online
	// 	if (isOnline) {
	// 		//load script here
	// 		const scriptTag = document.createElement('script');
	// 		// scriptTag.id = 'googleGsiClientScript';
	// 		scriptTag.src = process.env.GATSBY_PAYU_JS_URL; //'https://jssdk-uat.payu.in/bolt/bolt.min.js'; //replace with production url
	// 		scriptTag.async = true;
	// 		scriptTag.defer = true;
	// 		// scriptTag.nonce = nonce;
	// 		scriptTag.onload = () => {
	// 			scriptLoaded.current = true; //setScriptLoaded(true);
	// 			//initialize		// NOTE : WE INITIALIZE BELOW BY REGISTERING onGoogleLibraryLoad() fn
	// 			// window?.google?.accounts?.id?.initialize({
	// 			// 	client_id: googleClientId,
	// 			// 	callback: handleSignInCredentialResponse,
	// 			// });
	// 		};
	// 		scriptTag.onerror = () => {
	// 			scriptLoaded.current = false; //setScriptLoaded(false);
	// 		};
	// 		document.body.appendChild(scriptTag);

	// 		//we also return a cleanup fn
	// 		return () => {
	// 			// const scriptTag = document?.getElementById('googleGsiClientScript');
	// 			scriptTag && document.body.removeChild(scriptTag);
	// 		};
	// 	}
	// }, [isOnline]); //runs when network availability status changes

	return (
		<PaymentGateway_payU_Context.Provider value={{ initPayment }}>
			{children}
			{isWip && <Loading note="In progress" isDark={true} coverFullPage={true} />}
		</PaymentGateway_payU_Context.Provider>
	);
}
