import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '@providers/User';

import Button, { ButtonListContainer } from '@components/ui/Button';
import { getLetterFormatedDate } from '@utils';

import * as styles from './subscriptionStatusCard.module.css';

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
// /**
//  * Plan expiry details
//  * @typedef {import('@providers/User').PlanExpiryDetails} PlanExpiryDetails
//  */

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
// /**
//  * @function SubscriptionStatusCard
//  * @param {Object} prop - Prop
//  * @param {Function} prop.goToForm - Navigates to the specified form
//  */
export default function SubscriptionStatusCard({ goToForm }) {
	// const { isLoggedIn, name, email, pic, signOut } = useContext(UserContext);
	const { id, name, email, exp, getPlanExpiry, isPlanActive } = useContext(UserContext);
	const [isPayPlanActive, setIsPayPlanActive] = useState(false); //useState(getPlanExpiry().isActive)
	const [planExpiryDateStr, setPlanExpiryDateStr] = useState(''); //'30 Mar 2024' //Can be used to show 'Plan active till _____' or 'Plan expired on _____'

	useEffect(() => {
		(async () => {
			// console.log('exp', exp);
			const { expiry, isActive } = await getPlanExpiry();
			// console.log('expiry', expiry);
			if (isActive) {
				if (!isPayPlanActive) setIsPayPlanActive(true);
			} else {
				isPayPlanActive && setIsPayPlanActive(false);
			}

			if (expiry === null) {
				planExpiryDateStr !== '' && setPlanExpiryDateStr('');
			} else {
				const formattedDateStr = getLetterFormatedDate(expiry, false, true, false);
				planExpiryDateStr !== formattedDateStr && setPlanExpiryDateStr(formattedDateStr);
			}
		})();
	}, [exp]); //runs on first mount and then when 'plan expiry' changes

	return (
		<ul className={styles.planStsCard}>
			<li className={styles.usr}>
				<span className={styles.nm}>{name}</span>
				<span className={styles.eml}>{email}</span>
			</li>
			{isPayPlanActive ? (
				<li className={styles.sts + ' ' + styles.stsY}>Plan Active till {planExpiryDateStr}</li>
			) : (
				<>
					<li className={styles.sts + ' ' + styles.stsN}>
						{planExpiryDateStr !== '' ? 'Last plan expired on ' + planExpiryDateStr : 'No Active Plan!'}
					</li>
					<li className={styles.ctaC}>
						<ButtonListContainer>
							<Button
								variant="primary"
								isDark={true}
								size="big"
								icon="arrow-right"
								isIconOnRight={true}
								onClick={() => {
									goToForm('planSelection');
								}}
							>
								Activate Plan
							</Button>
						</ButtonListContainer>
					</li>
				</>
			)}
		</ul>
	);
}
