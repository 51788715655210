import React from 'react';

import { calcTaxAndFees, calcCostSplitComponents } from '@utils';
import { splitList as splitList_cssClass } from './costSplitDetails.module.css';

/**
 * React component that displays the cost split into base-price, gst and convenience fee
 * @param {Object} prop - React prop
 * @param {string} [prop.productName] - Name of product
 * @param {Number} prop.amt - Price. Either the base-price excluding tax & fees or the final-price including tax & fees
 * @param {Boolean} [prop.amtIncludesTaxNFees=false] - Whether amt provided above is to be treated as the final-price and includes tax and fees
 */
export default function CostSplitDetails({ productName, amt, amtIncludesTaxNFees }) {
	// const { gst, paymentGatewayFee, total } = calcTaxAndFees(amt);
	let basePrice = 0,
		gst = 0,
		fee = 0,
		total = 0;
	//if amt includes tax n fees, then
	if (amtIncludesTaxNFees) {
		const taxNFeesSplitFromFinalPrice = calcCostSplitComponents(amt);
		basePrice = taxNFeesSplitFromFinalPrice.basePrice;
		gst = taxNFeesSplitFromFinalPrice.gst;
		fee = taxNFeesSplitFromFinalPrice.paymentGatewayFee;
		total = amt;
	} else {
		const taxNFeesOnBasePrice = calcTaxAndFees(amt);
		basePrice = amt;
		gst = taxNFeesOnBasePrice.gst;
		fee = taxNFeesOnBasePrice.paymentGatewayFee;
		total = taxNFeesOnBasePrice.total;
	}

	return (
		<ul className={splitList_cssClass}>
			<li>
				<span>{productName || 'Base Price'}</span>
				<span>{basePrice}</span>
			</li>
			<li>
				<span>GST @ 18%</span>
				<span>{gst}</span>
			</li>
			<li>
				<span>Convenience fee @ 2%</span>
				<span>{fee}</span>
			</li>
			<li>
				<span>Total</span>
				<span>{total}</span>
			</li>
		</ul>
	);
}
