/*global require*/
/*global exports*/

/*
// import './src/css/variables.css';
// import './src/css/commonStyles.css';
require('./src/css/variables.css');
require('./src/css/commonStyles.css');

const React = require('react');

const AppRoot = require('./src/Root').default;

//https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
//same is also implemented in 'gatsby-ssr.js'

exports.wrapRootElement = ({ element }) => {
	return <AppRoot>{element}</AppRoot>;
};
//*/

import './src/css/variables.css';
import './src/css/commonStyles.css';

import React from 'react';

import AppRoot from './src/containers/Root';
// import SvgReusableElements from './src/components/svgJsx/CommonElements.js';
import { PageUiRoot } from './src/containers/Root';

export function wrapRootElement({ element }) {
	return <AppRoot>{element}</AppRoot>;
}

// export function wrapPageElement({ element }) {
// 	return (
// 		<>
// 			<SvgReusableElements />
// 			{element}
// 		</>
// 	);
// }

export function wrapPageElement({ element }) {
	return <PageUiRoot>{element}</PageUiRoot>;
}
