import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import * as styles from './modal.module.css';

/**
 * React modal container component
 * @param {Object} prop - prop passed to this component
 * @param {Boolean} [prop.useCloseBtn=false] - Show close button or allow closing modal when clicked on backdrop
 * @param {()=>{}} [prop.doOnOpen] - Callback fn to execute on modal opening
 * @param {()=>{}} [prop.doOnClose] - Callback fn to execute on modal close
 * @param {Boolean} [prop.disallowManualClosing=false] - Set to TRUE if we do not want the modal to be clossable by the user. For eg, in interim login scenario
 */
export default function Modal({ children, useCloseBtn, doOnOpen, doOnClose, disallowManualClosing, ...restProps }) {
	const [isViz, setIsViz] = useState(false);

	const _open = () => {
		document && document.body && (document.body.style.overflow = 'hidden');
		setTimeout(() => {
			setIsViz(true); // this.setState({ isVisible: true });
			doOnOpen &&
				typeof doOnOpen == 'function' &&
				setTimeout(() => {
					doOnOpen();
				}, 200);
		}, 10); //a slight delay of 10ms jst for CSS transition state-change to take effect
	};
	const close = () => {
		setIsViz(false); //this.setState({ isVisible: false });
		setTimeout(() => {
			document && document.body && (document.body.style.overflow = 'visible');
			doOnClose && typeof doOnClose == 'function' && doOnClose();
		}, 200);
	};

	useEffect(() => {
		_open();

		return () => {
			document && document.body && (document.body.style.overflow = 'visible');
		};
	}, []);

	// return (
	// 	<div className={clsx(styles.wrap, isViz && styles.isViz)} {...restProps}>
	// 		{useCloseBtn || disallowManualClosing ? <div className={styles.bg}></div> : <div className={styles.bg} onClick={close}></div>}
	// 		<div className={styles.modal}>
	// 			<main>{typeof children == 'function' ? children(close) : children}</main>
	// 			{useCloseBtn && <span className={styles.xBtn} onClick={close} />}
	// 		</div>
	// 	</div>
	// );
	return createPortal(
		<div className={clsx(styles.wrap, isViz && styles.isViz)} {...restProps}>
			{useCloseBtn || disallowManualClosing ? <div className={styles.bg}></div> : <div className={styles.bg} onClick={close}></div>}
			<div className={styles.modal}>
				<main>{typeof children == 'function' ? children(close) : children}</main>
				{useCloseBtn && <span className={styles.xBtn} onClick={close} />}
			</div>
		</div>,
		document.body,
	);
}

/* export default function Modal({ children,useCloseBtn,doOnClose}) {
	const [isViz, setIsViz] = useState(false);

	const _open = () => {
		return new Promise(resolve => {
			//https://docusaurus.io/docs/docusaurus-core#useIsBrowser
			document && document.body && (document.body.style.overflow = 'hidden');
			setTimeout(() => {
				setIsViz(true);	// this.setState({ isVisible: true });
				setTimeout(() => {
					resolve();
				}, 200);
			}, 10);	//a slight delay of 10ms jst for CSS transition state-change to take effect
		});
	};
	const close = () => {
		return new Promise(resolve => {
			setIsViz(false); //this.setState({ isVisible: false });
			setTimeout(() => {
				document && document.body && (document.body.style.overflow = 'visible');
				resolve();
			}, 200);
		});
	};
	const closeAndDo = () => {	//handleCloseClick = ()=>{	//(e)=>{
		//e.stopPropagation();e.preventDefault();
		close().then(() => {
			doOnClose && typeof doOnClose == 'function' && doOnClose();
		});
	};
	useEffect(() => {
		_open();
	}, []);

	return (
		<div className={clsx(
			styles.wrap,
			isViz && styles.isViz
		)}>
			{useCloseBtn ? (
				<div className={styles.bg}></div>
			) : (
				<div className={styles.bg} onClick={closeAndDo}></div>
			)}
			<div className={styles.modal}>
				<main>
					{typeof children == 'function' ? (
						children(close)
					) : (
						children
					)}
				</main>
				{useCloseBtn && <span className={styles.xBtn} onClick={closeAndDo} />}
			</div>
		</div>
	);
} */
