import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Icon from '@components/ui/Icon';
import * as styles from './navListItem.module.css';

export default function NavListItem({ icon, label, children, onClick, linkTo }) {
	return (
		//onClick && typeof onClick == 'function' ? (
		<div
			className={clsx(
				//https://docusaurus.io/docs/advanced/routing#generating-and-accessing-routes
				//use 'linkTo' string to check route path as shown in above link, and mark this as 'active'
				styles.itm,
				onClick && typeof onClick == 'function' && styles.btn,
			)}
			onClick={onClick}
		>
			{icon && icon !== '' && <Icon icon={icon} />}
			{linkTo ? (
				<>
					<Link activeClassName={styles.active} to={linkTo}>
						{label}
					</Link>
					{children && <div>{children}</div>}
				</>
			) : (
				<>
					<span>{label}</span>
					{children && <div>{children}</div>}
					{onClick && typeof onClick == 'function' && <Icon icon={'chevron-right'} />}
				</>
			)}
		</div>
		//) : ()
	);
}
