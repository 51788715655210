import React, { useContext } from 'react';

// import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';
import { UserContext } from '@providers/User';
// import GoogleLoginButton from '@components/federatedLogin/Google';
// import Button, { ButtonListContainer } from '@components/ui/Button';
import Modal from '@containers/Modal';
import SignInForm from '@components/userControlPanel/UserForm/SignInForm';

// import { interimLoginC as interimLoginC_cssClass, sep as sep_cssClass } from './interimLogin.module.css';

// /**
//  * React component to display the interim login form inside a modal.
//  * This component is shown when a user is logged-in but the creds (those issued by aws STS to perfomr backend AWS ops) have expired.
//  * This component in imported in 'src/containers/Root/index.js'
//  */
// export default function InterimLoginModalForm() {
// 	const isOnline = useContext(NetworkCheckContext);
// 	const { isCredsValid } = useContext(AwsCredentialsContext);
// 	const { isLoggedIn, name, signOut } = useContext(UserContext);

// 	if (!isCredsValid && isLoggedIn) {
// 		return (
// 			<Modal disallowManualClosing={true}>
// 				<div className={interimLoginC_cssClass}>
// 					<p>
// 						Dear <b>{name}</b>, kindly re-login to continue session!
// 					</p>
// 					{isOnline ? <GoogleLoginButton /> : <p>You are offline. Please check your network connection</p>}
// 					<span className={sep_cssClass}>OR</span>
// 					<p>You may choose to sign-out to end current session</p>
// 					<ButtonListContainer>
// 						<Button variant="secondary" isDark={true} icon="log-out" onClick={signOut} size="small">
// 							Sign Out
// 						</Button>
// 					</ButtonListContainer>
// 				</div>
// 			</Modal>
// 		);
// 	} else {
// 		return null;
// 	}
// }

/**
 * React component to display the interim login form inside a modal.
 * This component is shown when a user is logged-in but the creds (those issued by aws STS to perfomr backend AWS ops) have expired.
 * This component in imported in 'src/containers/Root/index.js'
 */
export default function InterimLoginModalForm() {
	// const isOnline = useContext(NetworkCheckContext);
	const { isCredsValid } = useContext(AwsCredentialsContext);
	const { isLoggedIn } = useContext(UserContext);

	if (!isCredsValid && isLoggedIn) {
		return (
			<Modal disallowManualClosing={true}>
				<SignInForm />
			</Modal>
		);
	} else {
		return null;
	}
}
