import React, { useState, useContext } from 'react';

import TextInput, { FauxTextInput } from '@components/ui/TextInput';
import Button, { ButtonListContainer } from '@components/ui/Button';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { ToastNotificationContext } from '@providers/ToastNotification';
import { UserContext } from '@providers/User';

import { vuPane as vuPaneCssClass, editPane as editPaneCssClass } from './myProfileForm.module.css';

/**
 * MyProfileForm component displays the My Profile data form.
 * @component
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {boolean} props.isWip - Whether the form context is in WIP state.
 * @param {()=>{}} props.setWip - Set or unset WIP form state.
 * @return {JSX.Element} A React component that displays the user's profile data.
 */
export default function MyProfileForm({ isWip, setWip }) {
	const [showEditForm, setShowEditForm] = useState(false);
	return showEditForm ? (
		<MyProfileEditForm
			goBackToViewPane={() => {
				setShowEditForm(false);
			}}
			isWip={isWip}
			setWip={setWip}
		/>
	) : (
		<MyProfileViewPane
			goToEditForm={() => {
				setShowEditForm(true);
			}}
		/>
	);
}

function MyProfileViewPane({ goToEditForm }) {
	const { id, name, email, phone, isRegistered } = useContext(UserContext);
	return (
		<ul className={vuPaneCssClass}>
			<li>
				<span>Kaagzi ID</span>
				<span>{id}</span>
			</li>
			<li>
				<span>Name</span>
				<span>{name}</span>
			</li>
			<li>
				<span>Email</span>
				<span>{email}</span>
			</li>
			<li>
				<span>Phone</span>
				<span>{phone || '--'}</span>
			</li>
			{isRegistered && (
				//only allow editing and saving data to backend if user is already registered in our backend (Note: we only reg user data in backend when he/she makes the first payment)
				<ButtonListContainer>
					<Button icon="edit" onClick={goToEditForm} variant="secondary" isDark={true}>
						Edit
					</Button>
				</ButtonListContainer>
			)}
		</ul>
	);
}

/**
 * MyProfileEditForm component displays the My Profile data editing form.
 * @component
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {boolean} props.goBackToViewPane - Fn to take user back to profile view pane.
 * @param {boolean} props.isWip - Whether the form context is in WIP state.
 * @param {()=>{}} props.setWip - Set or unset WIP form state.
 * @return {JSX.Element} A React component that displays the user's profile data.
 */
function MyProfileEditForm({ goBackToViewPane, isWip, setWip }) {
	const { name, email, phone, isRegistered, updateUserProfileInDB } = useContext(UserContext);

	if (!isRegistered) {
		//since we only allow editing of data if it is already registered /saved in our backend
		goBackToViewPane();
		return null;
	}

	const [tempName, setTempName] = useState(name);
	const [tempNameErr, setTempNameErr] = useState('');
	const [tempPhone, setTempPhone] = useState(/** @type {number} */ (phone));
	const [tempPhoneErr, setTempPhoneErr] = useState('');

	const isOnline = useContext(NetworkCheckContext);
	const { notify } = useContext(ToastNotificationContext);

	const reset = () => {
		setTempName(name || '');
		setTempNameErr('');
		setTempPhone(phone || null);
		setTempPhoneErr('');
		setWip(false);
	};
	const _getNameErrors = (name) => {
		if (!name || name.trim() == '') return 'Name is required';
		if (name.trim().length < 3) return 'Must be at least 3 characters long';
		if (name.trim().length > 30) return 'Name is too long';
		// matches the format 'firstname lastname'
		// if(!/^[^\d\W\s_]+(?:(?:[^\d\W_]{2,})(?:[\s]{1})[^\d\W\s_]{2,}){0,1}$/.test(name))return 'Enter valid Name';
		// /^([^\d\W\s_]{3,20})(?:[\s]{1}([^\d\W\s_]{2,20})){0,1}$/			<-- this pattern captures the 'firstname' & 'lastname' in the match-group array
		if (!/^(?:[^\d\W\s_]{3,20})(?:[\s]{1}(?:[^\d\W\s_]{2,20})){0,1}$/.test(name)) return 'Req Format: Firstname Lastname';
		return null;
	};
	const updateTempName = (e) => {
		setTempNameErr(_getNameErrors(e.target.value) || '');
		setTempName(e.target.value);
	};
	const _getPhnErrors = (phn) => {
		if (!phn || phn.toString().trim() == '') return 'Phone is required';
		if (!/^[0-9]{10}$/.test(phn)) return 'Enter valid 10 digit number';
		if (phn.trim().length !== 10) return 'Should be 10 digits long';
		return null;
	};
	const updateTempPhn = (e) => {
		setTempPhoneErr(_getPhnErrors(e.target.value) || '');
		setTempPhone(e.target.value);
	};

	const doSave = async () => {
		//validate
		var nErr = _getNameErrors(tempName),
			phErr = _getPhnErrors(tempPhone);
		if (nErr || phErr) {
			nErr && setTempNameErr(nErr);
			phErr && setTempPhoneErr(phErr);
			notify('Kindly rectify input errors', 'warn');
			return;
		}
		//here we check if data entered has really changed. If not, then we do not need resaving it
		if (tempName === name && tempPhone == phone) return;
		try {
			if (!isOnline) throw new Error('Please check your network connection');
			setWip(true);
			//here we write to DB
			const writtenData = await updateUserProfileInDB(tempName === name ? null : tempName, tempPhone == phone ? null : tempPhone);
			writtenData && notify('Profile data updated!', 'success') && goBackToViewPane();
			// setWip(false);	//we set it in finally stmt block below
		} catch (err) {
			setWip(false);
			notify(err.message || 'Error saving user profile data', 'err');
			return null;
		} finally {
			setWip(false);
		}
	};

	return (
		<ul className={editPaneCssClass}>
			<li>
				<TextInput placeholder="Name" title="Name" icon="user" initFocused={true} value={tempName} onChange={updateTempName} error={tempNameErr} />
			</li>
			<li>
				<FauxTextInput title="Email">{email}</FauxTextInput>
			</li>
			<li>
				<TextInput
					type="number"
					placeholder="10 digit phone number"
					title="Phone"
					icon="phone"
					value={tempPhone || ''}
					onChange={updateTempPhn}
					error={tempPhoneErr}
				/>
			</li>

			<li>
				<ButtonListContainer>
					{tempName !== name || tempPhone != phone ? (
						<>
							<Button onClick={doSave} isWip={isWip} wipText="Saving" variant="primary" isDisabled={tempNameErr != '' || tempPhoneErr != ''}>
								Save
							</Button>
							<Button onClick={reset} variant="tertiary">
								Reset
							</Button>
						</>
					) : (
						<Button onClick={goBackToViewPane} variant="secondary" isDark={true}>
							Back
						</Button>
					)}
				</ButtonListContainer>
			</li>
		</ul>
	);
}
