import React, { useContext } from 'react';

import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';
import { UserContext } from '@providers/User';
import GoogleLoginButton from '@components/federatedLogin/Google';
import Button, { LinkButton } from '@components/ui/Button';

import * as styles from './signInForm.module.css';
// import { loginForm } from './signInForm.module.css';
import { loginFormC } from './signInForm.module.css';

/* export default function SignInForm() {
	const { isLoggedIn, name, email, signOut } = useContext(UserContext);
	return (
		<ul>
			{isLoggedIn ? (
				<>
					<li>
						<p>Signed in as</p>
						<p>
							<b>{name}</b>
						</p>
						<p>{email}</p>
					</li>
					<li>
						<button onClick={signOut}>Sign Out</button>
					</li>
				</>
			) : (
				<>
					<li>
						<p>Google SignIn</p>
						<GoogleLoginButton />
					</li>
					<li>Facebook Sign-In</li>
				</>
			)}
		</ul>
	);
} */

// export default function SignInForm() {
// 	const isOnline = useContext(NetworkCheckContext);
// 	const { isLoggedIn, id, name, email, signOut } = useContext(UserContext);

// 	if (isLoggedIn) return null;
// 	return (
// 		<ul className={loginForm}>
// 			<li>{isOnline ? <GoogleLoginButton /> : <span>You are offline. Please check your network connection</span>}</li>
// 			<li>
// 				<ButtonListContainer>
// 					<LinkButton size="smaller" variant="tertiary" isDark={true} to="/terms-of-use">
// 						Terms Of Use
// 					</LinkButton>
// 				</ButtonListContainer>
// 			</li>
// 		</ul>
// 	);
// }

export default function SignInForm() {
	const isOnline = useContext(NetworkCheckContext);
	const { isCredsValid } = useContext(AwsCredentialsContext);
	const { isLoggedIn, name, email, signOut } = useContext(UserContext);

	return (
		<div className={loginFormC}>
			{isLoggedIn ? (
				isCredsValid ? (
					<>
						<p>
							Logged in as <b>{name}</b>
						</p>
						<Button variant="secondary" onClick={signOut}>
							Sign Out
						</Button>
					</>
				) : (
					//in case of interim login
					<>
						<p>
							Dear <b>{name}</b>, kindly re-login to continue session!
						</p>
						{/* {isOnline && <GoogleLoginButton />} */}
						<div className={styles.gglBtnC}>
							{isOnline ? <GoogleLoginButton /> : <span className={styles.ntwrkErr}>Kindly check your network connection</span>}
						</div>
						<span className="sep">OR</span>
						<p>You may choose to sign-out to end session</p>
						<Button variant="secondary" isDark={true} icon="log-out" onClick={signOut}>
							Sign Out
						</Button>
						{/* <button className="btn ico secondary small dark">
							<i className="icon-log-out"></i>
							<span>Sign Out</span>
						</button> */}
					</>
				)
			) : (
				<>
					<p>Sign into Kaagzi!</p>
					<div className={styles.gglBtnC}>
						{isOnline ? <GoogleLoginButton /> : <span className={styles.ntwrkErr}>Kindly check your network connection</span>}
					</div>
				</>
			)}
		</div>
	);
}
