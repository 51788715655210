import React from 'react';
import clsx from 'clsx';

import Icon from '@components/ui/Icon';

import * as styles from './input.module.css';

/**
 * TextInput component displays the Input field.
 * @component
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {string} [props.id] - The ID of the element.
 * @param {string} [props.className] - The class for the element.
 * @param {string} [props.title] - The label.
 * @param {string} [props.name] - The name for the input field.
 * @param {('text'|'number'|'password')} [props.type=text] - The type of input.
 * @param {function} [props.onChange] - The function to call on change of input value.
 * @param {function} [props.onBlur] - The function to call on loosing focus.
 * @param {string} [props.placeholder] - The placeholder text.
 * @param {(string|number)} [props.value] - The value of the input.
 * @param {string} [props.error] - The error to be shown.
 * @param {boolean} [props.isDisabled] - Whether the input is disabled.
 * @param {boolean} [props.initFocused] - Whether the input should be rendered with cursor focused.
 * @param {string} [props.icon] - The icon to be shown.
 * @param {function} [props.actionPane] - The function that renders JSX in the action pane.
 * @return {JSX.Element} A React component that displays the text input field.
 */
export default function TextInput({
	id,
	className,
	title,
	name,
	type,
	onChange,
	onBlur,
	placeholder,
	value,
	error,
	isDisabled,
	initFocused,
	icon,
	actionPane,
}) {
	const inputRef = React.useRef();
	React.useEffect(() => {
		initFocused && inputRef.current.focus();
	}, []);
	return (
		/* <div className={error && ''!==error.trim() ? ('inpC err '+className).trim(): ('inpC '+className).trim()}> */
		<div
			// className={(className?'inpC '+className:'inpC')+(error && ''!==error.trim() && ' err')}
			className={clsx(styles.inpC, error && '' !== error.trim() && styles.err, icon && icon !== '' && styles.inpIco, className)}
			data-title={title}
			data-err={error}
		>
			{icon && icon !== '' && <Icon icon={icon} />}
			<input
				ref={inputRef}
				tabIndex="0"
				id={id}
				className={styles.inp}
				name={name || id || ''}
				//onChange={(e)=>{onChange(e.target.value)}}
				onChange={onChange}
				onBlur={onBlur}
				type={type || 'text'}
				placeholder={placeholder}
				value={typeof value !== 'string' && typeof value !== 'number' ? '' : value}
				disabled={isDisabled ? 'disabled' : ''}
			/>
			{/* error && ''!==error.trim() && (<span className="textInputErr">{error}</span>) */}
			{actionPane && typeof actionPane == 'function' && <div>{actionPane()}</div>}
		</div>
	);
}

//'FauxTextInput' can be used wherever we need to show preset / prefilled input field which actually can't be edited
//It's different from a 'disabled' input in terms of styling, thus we do not use actual input elm

/**
 * FauxTextInput component displays a label that resembles a disabled text input (faux text input).
 * @component
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {string} [props.id] - The ID of the element.
 * @param {string} [props.className] - The class for the element.
 * @param {string} [props.title] - The label.
 * @param {JSX.Element} [props.children] - The React element to render inside.
 * @return {JSX.Element} A React component that displays the text input field.
 */
export function FauxTextInput({ id, className, title, children }) {
	return (
		<div
			id={id}
			//className={className?'nimFauxInput '+className:'nimFauxInput'}
			className={clsx(styles.inpC, styles.faux, className)}
			data-title={title}
		>
			{children || 'Text'}
		</div>
	);
}
