module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LLTPC3Y6PG"],"gtagConfig":{"cookie_expires":0,"anonymize_ip":false},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Kaagzi","name":"Kaagzi","description":"Kaagzi is a demo trading app for practicing trading in Indian stock market using live market data without requiring any real capital","id":"/","start_url":"/app","background_color":"#292145","display":"standalone","scope":"/","theme_color":"#292145","shortcuts":[{"name":"Angel One","url":"/app/angelOne","description":"Use Angel One broker"},{"name":"Upstox","url":"/app/upstox","description":"Use Upstox broker"}],"icons":[{"src":"/img/icons/icons-vector.svg","type":"image/svg+xml","sizes":"512x512","purpose":"maskable"},{"src":"/img/icons/icons-192.png","type":"image/png","sizes":"192x192","purpose":"maskable"},{"src":"/img/icons/icons-512.png","type":"image/png","sizes":"512x512","purpose":"maskable"},{"src":"/img/icons/icons-vector.svg","type":"image/svg+xml","sizes":"512x512","purpose":"any"},{"src":"/img/icons/icons-192.png","type":"image/png","sizes":"192x192","purpose":"any"},{"src":"/img/icons/icons-512.png","type":"image/png","sizes":"512x512","purpose":"any"}],"cache_busting_mode":"none","screenshots":[{"src":"/img/kaagzi.webp","type":"image/webp","sizes":"1280x720","label":"About Kaagzi"}],"categories":["finance","stock market","trading","tools"],"orientation":"portrait-primary","launch_handler":{"client_mode":"auto"},"lang":"en","dir":"ltr","display_override":["window-controls-overlay"],"edge_side_panel":{"preferred_width":376},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/img/icons/icons-*","**/img/kaagzi.webp"]},"precachePages":["/app/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
