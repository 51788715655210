exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-app-angel-one-index-js": () => import("./../../../src/pages/app/angelOne/index.js" /* webpackChunkName: "component---src-pages-app-angel-one-index-js" */),
  "component---src-pages-app-angel-one-welcome-index-js": () => import("./../../../src/pages/app/angelOne/welcome/index.js" /* webpackChunkName: "component---src-pages-app-angel-one-welcome-index-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-upstox-index-js": () => import("./../../../src/pages/app/upstox/index.js" /* webpackChunkName: "component---src-pages-app-upstox-index-js" */),
  "component---src-pages-app-upstox-welcome-index-js": () => import("./../../../src/pages/app/upstox/welcome/index.js" /* webpackChunkName: "component---src-pages-app-upstox-welcome-index-js" */),
  "component---src-pages-console-payment-transactions-index-js": () => import("./../../../src/pages/console/payment-transactions/index.js" /* webpackChunkName: "component---src-pages-console-payment-transactions-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("./../../../src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-frequently-asked-questions-index-js": () => import("./../../../src/pages/frequently-asked-questions/index.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-index-js" */),
  "component---src-pages-guide-index-js": () => import("./../../../src/pages/guide/index.js" /* webpackChunkName: "component---src-pages-guide-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-refund-policy-index-js": () => import("./../../../src/pages/refund-policy/index.js" /* webpackChunkName: "component---src-pages-refund-policy-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */)
}

