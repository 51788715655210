import React, { createContext, useState, useContext, useEffect } from 'react';

// import { ToastNotifierContext } from '@components/ToastNotifier';
import { ToastNotificationContext } from '@providers/ToastNotification';

export const NetworkCheckContext = createContext(false);
/**
 * Context provider for network check context.
 * Imported in Root
 */
export default function NetworkCheck_ContextProvider(props) {
	//https://react.dev/learn/reusing-logic-with-custom-hooks
	const [isOnline, setIsOnline] = useState(true);
	const { notify } = useContext(ToastNotificationContext); //Note that ToastNotifierContext.Provider will be available up the component hierarchy

	useEffect(() => {
		setIsOnline(window.navigator.onLine); //on first run
		function handleOnline() {
			setIsOnline(true);
			notify('You are back online!', 'ok');
		}
		function handleOffline() {
			setIsOnline(false);
			notify('You are offline! Please check network connection', 'err');
		}
		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);
		return () => {
			//cleanup on unmount
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []); //runs only on first mount render

	return <NetworkCheckContext.Provider value={isOnline}>{props.children}</NetworkCheckContext.Provider>;
}
