import React from 'react';

import Button from '@components/ui/Button';
import { subPage } from './subPage.module.css';

export default function SubPage({ children, title, onGoBackClick }) {
	return (
		<div className={subPage}>
			<div>
				<Button icon="arrow-left" onClick={onGoBackClick} size="bigger" variant="tertiary" isDark={true} />
				{title}
			</div>
			{children}
		</div>
	);
}
