import React, { createContext, useContext, useState } from 'react';
import clsx from 'clsx';
import Icon from '@components/ui/Icon';
import UserForm from '@components/userControlPanel/UserForm';
// import { UserContext } from '@components/User';
import { UserContext } from '@providers/User';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
// import { ToastNotifierContext } from '@components/ToastNotifier';
import { ToastNotificationContext } from '@providers/ToastNotification';
import * as styles from './userDropdown.module.css';

/**
 * React context for 'UserDropDown' component
 */
export const UserDropDownContext = createContext({
	isDropDownViz: false,

	/** Shows the drop-down */
	show: () => {},

	/** Hides the drop-down */
	hide: () => {},
});

/**
 * Provider for the 'UserDropDownContext' context. Imported in 'src/containers/Root'
 * @example
 * const {show,hide} = useContext(UserDropDownContext);	//consume the context in children components
 */
export function UserDropDown_ContextProvider({ children }) {
	const [isDropDownViz, setIsDropDownViz] = useState(false);
	const { id } = useContext(UserContext);
	const isOnline = useContext(NetworkCheckContext);
	const { notify } = useContext(ToastNotificationContext);

	const show = () => {
		if (!id && !isOnline) {
			notify('Please check your internet connection');
			return;
		}
		setIsDropDownViz(true);
	};
	const hide = () => {
		setIsDropDownViz(false);
	};
	return <UserDropDownContext.Provider value={{ isDropDownViz, show, hide }}>{children}</UserDropDownContext.Provider>;
}

//imported in Header component
/**
 * React component that renders the custom sign-in button or profile badge when signed-in. Imported in 'src/components/Header' component
 */
export default function UserDropDown() {
	// const [isDropDownViz, setIsDropDownViz] = useState(false);
	const { isDropDownViz, show, hide } = useContext(UserDropDownContext);
	const { isLoggedIn, id, name, pic } = useContext(UserContext);
	return (
		<div className={styles.adminBtnC}>
			<button
				onClick={() => {
					// setIsDropDownViz(!isDropDownViz);
					isDropDownViz ? hide() : show(); //toggle
				}}
			>
				{id ? (
					pic ? (
						<>
							<span className={styles.avtr}>
								<img src={pic} alt={name} />
							</span>
							<span>{name.split(' ')[0]}</span>
						</>
					) : (
						<>
							<Icon icon="user" />
							<span>{name.split(' ')[0]}</span>
						</>
					)
				) : (
					<>
						<Icon icon="log-in" />
						<span>Sign In</span>
					</>
				)}
			</button>
			<div className={clsx(styles.dd, isDropDownViz && styles.visible)}>
				<UserForm hideDropDown={hide} />
			</div>
			<button className={styles.ddBck} onClick={hide}></button>
		</div>
	);
}
