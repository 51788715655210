import React, { createContext } from 'react';

export const StorageContext = createContext({
	/**
	 * Reads data from localstorage after decrypting it
	 * @param {string} key - Localstorage key
	 * @param {string} [hmacGenKey='kaagzi'] - Key that was used to sign data with while encrypting
	 * @returns {Promise<?string>} Data decrypted as string
	 */
	storageGet: async (key, hmacGenKey) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Writes data to localstorage after encrypting it
	 * @param {string} key - Localstorage key
	 * @param {{}|[]|string} value - Data to store
	 * @param {string} [hmacGenKey='kaagzi'] - Key to sign data with while encrypting
	 * @returns {Promise<?String>} Encrypted value or NULL if storage action failed
	 */
	storageSet: async (key, value, hmacGenKey) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Removes data from localstorage
	 * @param {string} key - Localstorage key to remove
	 * @returns {Boolean}
	 */
	storageRemove: (key) => {}, // eslint-disable-line no-unused-vars

	/**
	 * Clears entire localstorage
	 * @returns {Boolean}
	 */
	storageClear: () => {},
});

/**
 * React provider component for encrypting / decrypting data before writing to localstorage.
 * This component is imported in 'src/containers/Root/index.js'.
 * NOTE: This functionality could well be served as a global variable utility instead of as a React based provider
 */
export default function Storage_ContextProvider(props) {
	/**
	 * Returns HMAC signature for given data and key
	 * @param {string} data - Data to generate digest for
	 * @param {string} [key='kaagzi'] - Key to sign with
	 * @returns {Promise<?string>} HMAC signature
	 */
	const _generateHmac = async (data, key) => {
		if (!data) return null;
		if (typeof data !== 'string') return null;
		if (!key) key = 'kaagzi';
		try {
			const encoder = new TextEncoder();
			const keyData = encoder.encode(key);
			const dataToSign = encoder.encode(data);
			const cryptoKey = await window?.crypto?.subtle.importKey('raw', keyData, { name: 'HMAC', hash: 'SHA-256' }, false, ['sign']);
			const signature = await window?.crypto?.subtle.sign('HMAC', cryptoKey, dataToSign);
			const digest = Array.from(new Uint8Array(signature))
				.map((b) => b.toString(16).padStart(2, '0'))
				.join('');
			return digest;
		} catch (err) {
			return null;
		}
	};

	/**
	 * Verifies HMAC signature for given data and key
	 * @param {string} data - Data
	 * @param {string} [key='kaagzi'] - Key used for signing
	 * @param {string} signatureToVerify - HMAC signature
	 * @returns {Promise<boolean>} TRUE if signature is valid. FALSE otherwise
	 */
	const _verifyHmac = async (data, key, signatureToVerify) => {
		if (!data || !signatureToVerify) return null;
		if (typeof data !== 'string') return null;
		if (!key) key = 'kaagzi';
		try {
			const encoder = new TextEncoder();
			const keyData = encoder.encode(key);
			const dataToVerify = encoder.encode(data);
			const signatureBytes = new Uint8Array(signatureToVerify.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
			const cryptoKey = await window?.crypto?.subtle.importKey('raw', keyData, { name: 'HMAC', hash: 'SHA-256' }, false, ['verify']);
			const isValid = await window?.crypto?.subtle.verify('HMAC', cryptoKey, signatureBytes, dataToVerify);
			return isValid;
		} catch (err) {
			return false;
		}
	};
	const _caesarCipherEncrypt = (str, shift) => {
		if (!shift) shift = 4; //default shift 4
		if (!Number.isInteger(shift)) shift = 4;
		let output = '';
		for (let char of str) {
			let ascii = char.charCodeAt();
			if (ascii >= 65 && ascii <= 90) {
				output += String.fromCharCode(((ascii - 65 + shift) % 26) + 65);
			} else if (ascii >= 97 && ascii <= 122) {
				output += String.fromCharCode(((ascii - 97 + shift) % 26) + 97);
			} else {
				output += char;
			}
		}
		return output;
	};
	const _caesarCipherDecrypt = (str, shift) => {
		if (!shift) shift = 4; //default shift 4
		if (!Number.isInteger(shift)) shift = 4;
		let output = '';
		for (let char of str) {
			let ascii = char.charCodeAt();
			if (ascii >= 65 && ascii <= 90) {
				output += String.fromCharCode(((ascii - 65 + 26 - shift) % 26) + 65);
			} else if (ascii >= 97 && ascii <= 122) {
				output += String.fromCharCode(((ascii - 97 + 26 - shift) % 26) + 97);
			} else {
				output += char;
			}
		}
		return output;
	};

	/**
	 * Writes data to localstorage after encrypting it
	 * @param {string} key - Localstorage key
	 * @param {{}|[]|string} value - Data to store
	 * @param {string} [hmacGenKey='kaagzi'] - Key to sign data with while encrypting
	 * @returns {Promise<?String>} Encrypted value or NULL if storage action failed
	 */
	const set = async (key, value, hmacGenKey) => {
		if (!key || !value) return false;
		if (typeof value !== 'object' && typeof value !== 'string') return false;
		try {
			// const strTest = JSON.parse(JSON.stringify(value));	//test if it is parsable
			// if (!strTest) throw new Error();
			const base64Str = window?.btoa?.(typeof value !== 'string' ? JSON.stringify(value) : value);

			//we'll verify this signature to identify if data has been tampered with
			//here use Caesar-Cipher to encrypt the data base64
			const base64Str_encrypted = _caesarCipherEncrypt(base64Str, 3); //with this, any malicious user won't be able to decode the base64

			//here generate a hmac signature for the encrypted base64 str. We shall append it to the encrypted base64 after a '.' character
			const hmac = await _generateHmac(base64Str_encrypted, hmacGenKey);

			localStorage.setItem(key, base64Str_encrypted + '.' + hmac);
			// return true; //resolve Promise
			return base64Str_encrypted + '.' + hmac;
		} catch (error) {
			// return false;
			return null;
		}
	};

	/**
	 * Reads data from localstorage after decrypting it
	 * @param {string} key - Localstorage key
	 * @param {string} [hmacGenKey='kaagzi'] - Key that was used to sign data with while encrypting
	 * @returns {Promise<?string>} Data decrypted as string
	 */
	const get = async (key, hmacGenKey) => {
		if (!key) return null;
		if (typeof key !== 'string') return null;
		try {
			const dataStr = localStorage.getItem(key);
			if (!dataStr) throw new Error();

			//here split string at '.' char into data part and hmac sig part
			const dataAry = dataStr.split('.');
			if (dataAry.length !== 2) throw new Error();
			//dataAry[0] -- The encrypted base64 string
			//dataAry[1] -- HMAC signature of the encrypted base64 string

			//here verify the hmac signature of the base64 string
			const signatureCheck = await _verifyHmac(dataAry[0], hmacGenKey, dataAry[1]);
			if (!signatureCheck) throw new Error();

			//here decrypt data part using Caesar-Cipher to get actual base64 str
			const base64Str_decrypted = _caesarCipherDecrypt(dataAry[0], 3);

			return window?.atob?.(base64Str_decrypted); //decoded string
		} catch (err) {
			return null;
		}
	};

	/**
	 * Removes data from localstorage
	 * @param {string} key - Localstorage key to remove
	 * @returns {Boolean}
	 */
	const remove = (key) => {
		if (!key) return false;
		if (typeof key !== 'string') return false;
		try {
			if (!localStorage?.getItem(key)) return false;
			localStorage?.removeItem(key);
			return true;
		} catch (err) {
			return false;
		}
	};

	/**
	 * Clears entire localstorage
	 * @returns {Boolean}
	 */
	const clear = () => {
		try {
			localStorage?.clear();
			return true;
		} catch (err) {
			return false;
		}
	};

	return (
		<StorageContext.Provider value={{ storageGet: get, storageSet: set, storageRemove: remove, storageClear: clear }}>
			{props.children}
		</StorageContext.Provider>
	);
}
