import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb'; // ES6 import
import {
	DynamoDBDocumentClient,
	QueryCommand,
	GetCommand,
	// PutCommand,
	UpdateCommand,
	// DeleteCommand,
	// TransactWriteCommand
} from '@aws-sdk/lib-dynamodb'; // ES6 import

// import { ToastNotifierContext } from '@components/ToastNotifier';
import { ToastNotificationContext } from '@providers/ToastNotification';
import { NetworkCheckContext } from '@providers/NetworkAvailabilityCheck';
import { AwsCredentialsContext } from '@providers/aws/AwsCredentials';

/*======================+
|						|
|		TYPE-DEFS		|
|						|
+======================*/
/**
 * Short-lived Temporary Credentials issued by AWS STS service
 * @typedef {import('@providers/aws/AwsCredentials').Creds} Creds
 */

/*==================+
|					|
|		CONTEXT		|
|					|
+==================*/
/**
 * React context for AWS DynamoDB
 */
export const DynamoDbContext = createContext({
	/**
	 * Query items from database
	 * @async
	 * @function dbQueryData
	 * @param {!object} QueryCommandInput - Input for the DynamoDB query cmd
	 * @returns {Promise<?Object>} An object containing the queried data from DB or null if read failed.
	 */
	dbQueryData: async () => {},
	/**
	 * Get Item from database
	 * @async
	 * @function dbGetItemData
	 * @param {!Object} GetCommandInput - Input for the DynamoDB getItem cmd
	 * @returns {Promise<?Object>} An object containing the item-data from DB or null if read failed.
	 * @example
	 * //responseObj:
	 * {
	 *		"$metadata": {
	 *			"httpStatusCode": 200,
	 *			"requestId": "KNFJNSMUMQ9TIGD7PG2EFDLAH7VV4KQNSO5AEMVJF66Q9ASUAAJG",
	 *			"attempts": 1,
	 *			"totalRetryDelay": 0
	 *		},
	 *		"Item": {
	 *			"some-attribute":"attribute-value",
	 *			"some-other-attribute":"other-attribute-value"
	 *		}
	 *	}
	 */
	dbGetItemData: async (GetCommandInput) => {}, // eslint-disable-line no-unused-vars
	// dbPutData: async () => {},
	/**
	 * Update data in database.
	 * @async
	 * @function dbUpdateData
	 * @param {!Object} UpdateCommandInput - Input for the DynamoDB update cmd
	 * @returns {Promise<?Object>} An object containing the data updated in DB or null if update failed.
	 */
	dbUpdateData: async (UpdateCommandInput) => {}, // eslint-disable-line no-unused-vars
});

/*======================+
|						|
|		COMPONENT		|
|						|
+======================*/
/**
 * Provider component for AWS DynamoDB.
 * This component is imported in 'src/containers/Root'
 */
export default function DynamoDb_ContextProvider({ children }) {
	let ddbClientRef = useRef(null);
	let ddbDocClientRef = useRef(null);
	let lastUsedCredsStamp = useRef(null); //we store a signature (ie' any prop of the creds obj that we can compare) of creds to check if the creds have changed

	const { notify } = useContext(ToastNotificationContext);
	const isOnline = useContext(NetworkCheckContext);
	const { getCreds } = useContext(AwsCredentialsContext);

	const configData = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					awsRegion
				}
			}
		}
	`);
	const AWS_REGION = configData.site.siteMetadata.awsRegion;

	/**
	 * Closes any open DynamomDB client and DynamoDB Document client. Also clears out any saved static variables
	 * @function _close
	 */
	const _close = () => {
		//destroy DDB instances here
		//we can also check if ddbDocClientRef.current.constructor.name == 'DynamoDBDocumentClient'
		if (ddbDocClientRef.current) {
			ddbDocClientRef.current.destroy(); // no-op
			ddbDocClientRef.current = null;
		}
		//we can also check if ddbDocClientRef.current.constructor.name == 'DynamoDBClient'
		if (ddbClientRef.current) {
			ddbClientRef.current.destroy(); // destroys DynamoDBClient
			ddbClientRef.current = null;
		}
		if (lastUsedCredsStamp.current) lastUsedCredsStamp.current = null;
	};

	/**
	 * Initializes the DynamoDB client and saves it as static for reuse later
	 * @async
	 * @function _initIfNotAlready
	 * @param {!Creds} creds - Credentials object issued by Amazon STS
	 * @returns {Promise<?object>} DynamoDB document client handle or null.
	 */
	const _initIfNotAlready = async (creds) => {
		if (!creds) return null;
		if (typeof creds !== 'object') return null;
		try {
			if (
				JSON.stringify(creds) === lastUsedCredsStamp?.current && //here we check if creds have changed
				ddbClientRef.current &&
				ddbClientRef.current.constructor.name === 'DynamoDBClient' &&
				ddbDocClientRef.current
			) {
				return ddbDocClientRef.current; //return the already created client stored for reuse
			} else {
				// initialize new DDB client
				_close(); //close any previous open clients! actually there won't be any coz that is exactly what we are checking in the if stmt above
				ddbClientRef.current = new DynamoDBClient({
					// Bare-bones DynamoDB Client
					region: AWS_REGION,
					credentials: creds, //AwsCogIdCreds.creds
				});
				lastUsedCredsStamp.current = JSON.stringify(creds);
				// console.log('ddbClient:',ddbClientRef.current);
				// Once DynamoDB client is created, you can create the bare-bones document client
				ddbDocClientRef.current = DynamoDBDocumentClient.from(ddbClientRef.current); // Bare-bones document client
				return ddbDocClientRef.current;
			}
		} catch (err) {
			console.log('_initIfNotAlready():', err.message || err.code || err.name || 'Error initiating DB client');
			return null;
		}
	};

	/**
	 * Query items from database
	 * @async
	 * @function queryData
	 * @param {!object} QueryCommandInput - Input for the DynamoDB query cmd
	 * @returns {Promise<?object>} An object containing the queried data from DB or null if read failed.
	 */
	const queryData = async (QueryCommandInput) => {
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!QueryCommandInput) throw new Error('No query specified');
			const creds = await getCreds();
			if (!creds) throw new Error('Error getting credentials');
			if (typeof creds !== 'object') throw new Error('No proper creds specified');

			//here init / setup 'ddbDocClient' if not already setup
			// await _initIfNotAlready(creds);
			// const data = await ddbDocClientRef.current.send(new QueryCommand(QueryCommandInput));

			const ddbDocClient = await _initIfNotAlready(creds);
			//note that this sets up 'ddbDocClientRef.current' var so we may actually use 'ddbDocClientRef.current' instead of 'ddbDocClient' further

			if (!ddbDocClient) throw new Error('Error getting DB handle');

			const data = await ddbDocClient.send(new QueryCommand(QueryCommandInput));
			return data;
		} catch (err) {
			console.log('queryData():', err.message || err.code || err.name || 'Error querying data from database');
			return null;
		}
	};

	/**
	 * Get Item from database
	 * @async
	 * @function getItemData
	 * @param {!object} GetCommandInput - Input for the DynamoDB getItem cmd
	 * @returns {Promise<object>|Promise<null>} An object containing the item-data from DB or null if read failed.
	 */
	const getItemData = async (GetCommandInput) => {
		console.log('Getting item from DB');
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!GetCommandInput) throw new Error('No query specified');
			const creds = await getCreds();
			if (!creds) throw new Error('Error getting credentials');
			if (typeof creds !== 'object') throw new Error('No proper creds specified');

			const ddbDocClient = await _initIfNotAlready(creds);
			//note that this sets up 'ddbDocClientRef.current' var so we may actually use 'ddbDocClientRef.current' instead of 'ddbDocClient' further

			if (!ddbDocClient) throw new Error('Error getting DB handle');

			const data = await ddbDocClient.send(new GetCommand(GetCommandInput));
			return data;
		} catch (err) {
			console.log('getItemData():', err.message || err.code || err.name || 'Error fetching data from database');
			console.error(err);
			return null;
		}
	};

	/* const putData = async (PutCommandInput) => {
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!PutCommandInput) throw new Error('No query specified');
			const creds = await getCreds();
			if (!creds) throw new Error('Error getting credentials');
			if (typeof creds !== 'object') throw new Error('No proper creds specified');

			const ddbDocClient = await _initIfNotAlready(creds);
			//note that this sets up 'ddbDocClientRef.current' var so we may actually use 'ddbDocClientRef.current' instead of 'ddbDocClient' further

			if (!ddbDocClient) throw new Error('Error getting DB handle');

			const data = await ddbDocClient.send(new PutCommand(PutCommandInput));
			return data;
		} catch (err) {
			console.log('putData():', err.message || err.code || err.name || 'Error writing to database');
			return null;
		}
	}; */

	/**
	 * Update data in database.
	 * @async
	 * @function updateData
	 * @param {!object} UpdateCommandInput - Input for the DynamoDB update cmd
	 * @returns {Promise<object>|Promise<null>} An object containing the data updated in DB or null if update failed.
	 */
	const updateData = async (UpdateCommandInput) => {
		try {
			if (!isOnline) {
				notify('Network unavailable', 'warn');
				throw new Error('Network Error');
			}
			if (!UpdateCommandInput) throw new Error('No update query specified');
			const creds = await getCreds();
			if (!creds) throw new Error('Error getting credentials');
			if (typeof creds !== 'object') throw new Error('No proper creds specified');

			const ddbDocClient = await _initIfNotAlready(creds);
			//note that this sets up 'ddbDocClientRef.current' var so we may actually use 'ddbDocClientRef.current' instead of 'ddbDocClient' further

			if (!ddbDocClient) throw new Error('Error getting DB handle');

			const data = await ddbDocClient.send(new UpdateCommand(UpdateCommandInput));
			return data;
		} catch (err) {
			console.log('updateData():', err.message || err.code || err.name || 'Error updating database');
			return null;
		}
	};

	useEffect(() => {
		//cleanup
		return () => {
			_close();
		};
	}, []);
	return (
		<DynamoDbContext.Provider
			value={{
				dbQueryData: queryData,
				dbGetItemData: getItemData,
				// dbPutData: putData,
				dbUpdateData: updateData,
			}}
		>
			{children}
		</DynamoDbContext.Provider>
	);
}
