import React, { createContext, useRef, useState } from 'react';

export const ToastNotificationContext = createContext({
	/** Notification to show */
	notification: '',
	/** Type of notification */
	type: '',
	/**
	 * Notify to the user in a toast
	 * @param {!string} msg - The message to display
	 * @param {?('err'|'ok'|'success'|'alert'|'warn')} [type] - Tyoe of message. Either of 'err', 'ok', 'success', 'alert' or 'warn'
	 * @returns {void}
	 */
	notify: (msg, type) => {}, // eslint-disable-line no-unused-vars
	closeToastNotification: () => {},
});

export default function ToastNotification_ContextProvider({ children }) {
	const [isViz, setIsViz] = useState(false);
	const [notification, setNotification] = useState('');
	const [type, setType] = useState('');
	const notificationTimeoutRef = useRef(null);

	/**
	 * Notify to the user in a toast
	 * @function notify
	 * @param {!string} msg - The message to display
	 * @param {'err'|'ok'|'success'|'alert'|'warn'} [type] - Tyoe of message. Either of 'err', 'ok', 'success', 'alert' or 'warn'
	 * @returns {void}
	 */
	const notify = (msg, type) => {
		if (!msg || typeof msg != 'string') return;
		if (msg.trim() == '') return;

		if (!type || typeof type != 'string') type = '';
		if (!['err', 'ok', 'success', 'alert', 'warn'].includes(type)) type = ''; //default to blue color ie -- 'info', but no need to explicitely pass 'info' as type

		notificationTimeoutRef && notificationTimeoutRef.current && clearTimeout(notificationTimeoutRef.current);
		notificationTimeoutRef.current = setTimeout(close, 7000);

		setIsViz(true);
		setNotification(msg);
		setType(type);
	};
	const close = () => {
		setIsViz(false);
		notificationTimeoutRef && notificationTimeoutRef.current && clearTimeout(notificationTimeoutRef.current);
		notificationTimeoutRef.current = setTimeout(_clear, 7000);
	};
	const _clear = () => {
		notificationTimeoutRef && notificationTimeoutRef.current && clearTimeout(notificationTimeoutRef.current);
		setNotification('');
		setType('');
	};
	return (
		<ToastNotificationContext.Provider value={{ isViz, notification, type, notify, closeToastNotification: close }}>
			{children}
		</ToastNotificationContext.Provider>
	);
}
